<template>
  <div>
    <v-dialog
      v-model="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      persistent
      max-width="700"
      style="height: 30px"
      eager
    >
      <v-card tile color="white" class="pb-3">
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold"
            >Računi s predujmom</span
          >
        </v-card-title>
        <v-card-title
          style="
            height: 70px;
            padding-top: 0px;
            position: sticky;
            border-bottom: 1px solid lightgrey;
          "
        >
          <v-col cols="12" class="pb-2 pt-5">
            <v-row style="">
              <v-combobox
                style="max-width: 35% !important; padding-right: 10px"
                label="Kupac"
                :items="associates"
                v-model="associateByName"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="text"
                return-object
                :rules="[rules.req]"
                @change="setAssociateByName()"
              ></v-combobox>
              <v-combobox
                style="max-width: 25% !important; padding-right: 10px"
                label="OIB"
                :items="associates"
                v-model="associateByOib"
                hide-details
                outlined
                dense
                item-text="oibs[0]"
                item-value="text"
                return-object
                :rules="[rules.req]"
                @change="setAssociateByOib()"
              ></v-combobox>
              <!-- </v-row>
            <v-row style="margin-right: -30px !important; padding-top: 10px"> -->
              <v-text-field
                style="max-width: 30% !important; padding-right: 10px"
                outlined
                dense
                clearable
                hide-details
                data-layout="numericAduro"
                v-model="searchNumber"
                :label="$t('$vuetify.cashRegister.searchByReceiptNumber')"
                @click:prepend="doSearch"
                @click:clear="doSearch"
                @click:append="doSearch"
                @focus="showKbd"
                @click="showKbd"
                @keydown.enter="doSearch"
                @keypress.enter.prevent="doSearch"
              ></v-text-field>
              <v-btn
                dense
                height="40"
                style="max-width: 20% !important"
                class="btn infoButton"
                @click="doSearch"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text
          style="padding: 0; margin: 0; overflow-x: hidden; height: 400px"
        >
          <v-row>
            <v-col cols="12" style="min-width: 100%">
              <v-expansion-panels focusable>
                <v-expansion-panel
                  v-for="(receipt, i) in receipts"
                  :key="i"
                  hover
                  tabindex="0"
                  style="border-radius: 0"
                >
                  <v-expansion-panel-header
                    style="max-height: 70px !important"
                    :class="[
                      receipt.avansCurrentTotal > 0
                        ? 'status-ok-exp'
                        : 'status-warn-after-exp',
                    ]"
                    hide-actions
                  >
                    <v-row>
                      <v-col cols="3">
                        <div class="caption grey--text text-center">
                          {{
                            reciptOffer === "receipts"
                              ? $t("$vuetify.receipts.receiptNumber")
                              : $t("$vuetify.receipts.offerNumber")
                          }}
                        </div>
                        <div
                          class="text-center"
                          v-html="receipt.receiptNumber"
                        ></div>
                      </v-col>
                      <v-col cols="2">
                        <div class="caption grey--text text-right">
                          Početni predujam
                        </div>
                        <div class="text-right">
                          {{
                            receipt.avansInitialTotal
                              | money(100, receipt.currency)
                          }}
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="caption grey--text text-right">Stanje</div>
                        <div class="text-right">
                          {{
                            receipt.avansCurrentTotal
                              | money(100, receipt.currency)
                          }}
                        </div>
                      </v-col>

                      <v-col cols="4">
                        <div class="caption grey--text text-center">
                          {{ $t("$vuetify.receipts.receiptIssueDate") }}
                        </div>
                        <div class="text-center">
                          {{ receipt.issue_date_time | local }}
                        </div>
                      </v-col>
                      <v-col
                        cols="1"
                        class="d-flex justify-end"
                        style="margin-top: 2px"
                      >
                        <v-checkbox
                          :disabled="
                            receipt.avansCurrentTotal <= 0 ||
                            isNaN(receipt.avansCurrentTotal)
                          "
                          @click.native.stop
                          v-model="receipt.selected"
                        ></v-checkbox>
                        <!-- <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @keypress.enter.stop=""
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item
                              v-for="(item, index) in receipt.receiptOptions"
                              :key="index"
                              style="cursor: pointer"
                              @click="`${item.action(receipt)}`"
                              @keypress.enter.prevent="
                                `${item.action(receipt)}`
                              "
                              :disabled="
                                receipt.payment &&
                                receipt.payment.current_status === 'PROCESSING'
                              "
                            >
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu> -->
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6" sm="5">
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          Operater: {{ receipt.seller_id }}
                        </div>
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          Kupac: {{ receipt.type.payer_name }}
                        </div>
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          Adresa: {{ receipt.type.payer_address }}
                        </div>
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          Poštanski broj: {{ receipt.type.payer_zip_code }}
                        </div>
                      </v-col>
                      <v-col cols="6" sm="5">
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          Način plaćanja: {{ receipt.payment_method }}
                        </div>
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          OIB: {{ receipt.type.payer_oib }}
                        </div>
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          Grad: {{ receipt.type.payer_city }}
                        </div>
                        <div
                          class="d-flex justify-space-between text-center text-subtitle-2"
                        >
                          Država: {{ receipt.type.payer_country }}
                        </div>
                      </v-col>
                    </v-row>

                    <v-list>
                      <v-row>
                        <v-col>
                          <div
                            class="text-center text-subtitle-2 one-liner-text font-weight-bold"
                          >
                            R. br.
                          </div>
                        </v-col>
                        <v-col>
                          <div
                            class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                          >
                            Naziv
                          </div>
                        </v-col>
                        <v-col>
                          <div
                            class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                          >
                            Količina
                          </div>
                        </v-col>
                        <v-col>
                          <div
                            class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                          >
                            Jedinična cijena
                          </div>
                        </v-col>
                        <v-col>
                          <div
                            class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                          >
                            Cijena
                          </div>
                        </v-col>
                      </v-row>
                      <v-list-item
                        v-for="(item, index) in receipt.items"
                        :key="index"
                        class="pa-0"
                      >
                        <v-row class="pa-0">
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ index + 1 }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ item.name }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ item.amt / multiplier }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{
                                item.single_item_price
                                  | money(100, receipt.currency)
                              }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ item.price | money(100, receipt.currency) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <infinite-loading
            ref="InfiniteLoading"
            @infinite="infiniteHandler"
            spinner="waveDots"
          >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </v-card-text>
        <v-card-actions class="pt-7 pb-5">
          <v-row class="justify-end pr-3">
            <v-btn class="btn dangerButton mr-5" @click="dialogOpen = false"
              >Odustani</v-btn
            >
            <v-btn
              id="success"
              class="btn successButton"
              @click="connectReceipts"
              >Poveži</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>

      <v-overlay
        v-if="loading"
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        style="z-index: 100"
      >
        <div
          v-if="loading"
          class="text-center"
          style="
            position: absolute;
            z-index: 101;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          "
        >
          <v-progress-circular indeterminate size="86" color="primary">
          </v-progress-circular>
        </div>
      </v-overlay>
    </v-dialog>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </div>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import { clone, toISOLocal } from '@/plugins/utils'
import applicationSettings from '@/mixins/applicationSettings'
import state from '@/state'
import InfiniteLoading from 'vue-infinite-loading'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import rules from '@/plugins/rules'

export default {
  components: {
    InfiniteLoading
  },
  mixins: [applicationSettings, onScreenKeyboard],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data () {
    return {
      dialogOpen: false,
      loading: false,
      receipts: [],
      unfiskReceipts: [],
      location: {},
      statusFilter: {},
      receiptNumberFilter: '',
      receiptNumberParsed: null,
      lastVisible: 0,
      listeners: [],
      reciptOffer: {},
      associates: [],
      associate: {
        name: '',
        address: '',
        oib: '',
        zipcode: '',
        city: '',
        country: ''
      },
      rules: {
        req: rules.req(),
        money: rules.money(),
        arrReq: rules.arrReq()
      },
      associateByOib: undefined,
      associateByName: undefined,
      multiplier: 0,
      filtersExpanded: false,
      absolute: true,
      opacity: 0.46,
      overlay: true,
      searchNumber: '',
      register: null,
      document: null,
      last_balance_timestamp: new Date(new Date().setHours(0, 0, 0, 0)).valueOf() / 1000
    }
  },
  watch: {
    dialogOpen (val) {
      !val && this.$emit('closeReceiptsDialog')
    }
  },
  computed: {
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    }
  },
  methods: {
    setAssociateByName () {
      if (this.associateByName && this.associateByName.name && this.associateByName.name !== '') {
        this.associate = this.associateByName
        this.associateByOib = this.associateByName
      } else {
        this.associate = {
          name: '',
          address: '',
          oib: '',
          zipcode: '',
          city: '',
          country: ''
        }
      }
    },
    setAssociateByOib () {
      if (this.associateByOib && this.associateByOib.name) {
        this.associate = this.associateByOib
        this.associateByName = this.associateByOib
      } else {
        this.associate = {
          name: '',
          address: '',
          oib: '',
          zipcode: '',
          city: '',
          country: ''
        }
      }
    },
    connectReceipts () {
      var connectedReceipts = []

      if (this.receipts && this.receipts.length > 0) {
        this.receipts.forEach(receipt => {
          if (receipt.selected && receipt.selected === true) {
            connectedReceipts.push(receipt)
          }
        })
      }

      this.$emit('connectedReceipts', connectedReceipts)
      this.dialogOpen = false
    },
    async open (registerChoosen = undefined, location = undefined) {
      var reg
      if (state.getCashRegister().id) {
        reg = state.getCashRegister()
      }

      if (registerChoosen) {
        reg = registerChoosen
      }

      const register = await df.doc(`cash_registers/${reg.id}`).get()
      this.register = register.data()
      this.last_balance_timestamp = register.data().last_balance_timestamp

      this.dialogOpen = true
      this.doSearch()
    },
    close () {
      this.$emit('closeReceiptsDialog')
    },
    applyFilter () {
      this.lastVisible = {}
      this.receipts = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },
    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    infiniteHandler ($state) {
      const that = this

      let receiptNumberParsed
      if (this.receiptNumberFilter !== '') {
        receiptNumberParsed = this.parseReceiptNumber(this.receiptNumberFilter)
      }
      let query = df
        .collection(
          `${this.reciptOffer}/${state.getCurrentCompany().id}/${
            this.reciptOffer
          }`
        )
        .orderBy('issue_date_time', 'desc')
        .limit(10)
        .startAfter(that.lastVisible)

      var receiptType = {
        receipt_type: 'AVANS',
        tag: 'RECEIPT_TYPE'
      }

      if (this.associate && this.associate.name !== '') {
        query = query.where('type.payer_name', '==', this.associate.name)
        query = query.where('type.payer_oib', '==', this.associate.oibs[0])
      }

      query = query.where('tags', 'array-contains', receiptType)
      query = query.where('type.flag', 'in', ['BO', 'R'])

      if (this.searchNumber && this.searchNumber.length > 0) {
        query = query.where('designation.number', '==', Number(this.searchNumber))
      }

      if (receiptNumberParsed) {
        query = query
          .where('designation.number', '==', receiptNumberParsed.number)
          .where('designation.location_id', '==', receiptNumberParsed.location_id)
          .where('designation.register_number', '==', receiptNumberParsed.register_number)
      }

      const listener = query.onSnapshot(
        doc => {
          doc.docChanges().forEach(change => {
            that.modifyItems(change)
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              that.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        }
      )

      this.listeners.push(listener)
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (change.doc.data()) {
          const _document = clone(change.doc.data())

          switch (_document.type.flag) {
            case 'R':
            case 'R1':
            case 'R2':
            case 'BO':
              _document.receiptOptions = [
                { title: 'Poveži račun', action: this.connectReceipt }
              ]
          }

          let rcptSeparator = '-'
          if (_document.company_details.rcpt_separator !== undefined && _document.company_details.rcpt_separator !== '') {
            rcptSeparator = _document.company_details.rcpt_separator
          }

          if (_document.designation) {
            _document.receiptNumber = `${_document.designation.number}${rcptSeparator}${_document.designation.location_id}${rcptSeparator}${_document.designation.register_number}`
          }

          if (_document.company_details.rcpt_num_year === true) {
            var d = new Date(_document.issue_date_time * 1000)

            _document.receiptNumber += `  <b>${d.getFullYear()}</b> `
          }

          if (_document.issue_date_time) {
            _document.time = _document.issue_date_time.seconds
          }
          if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
            _document.customer = _document.type.payer_name
          } else {
            _document.customer = this.$t('$vuetify.receipts.retailCustomer')
          }

          if (_document.tags && _document.tags.length > 0) {
            _document.tags.forEach(tag => {
              if (tag.current_value) {
                _document.avansCurrentTotal = tag.current_value.total
                _document.avansCurrentTaxBase = tag.current_value.tax_base
                _document.avansCurrentTaxTotal = tag.current_value.tax_total
                _document.avansCurrentTaxRate = tag.current_value.tax_rate
              }
              if (tag.total) {
                _document.avansInitialTotal = tag.total.total
              }

              if (tag.avans_status) {
                _document.avansStatus = tag.avans_status
              }
            })
          }

          _document.show = false

          if (_document.avans_status !== 'CLOSED' && _document.avansCurrentTotal > 0) {
            this.receipts.push({
              ..._document,
              ...{ [`hover-${change.doc.data().id}`]: false }
            })
          }

          // }

          this.receipts.sort((a, b) => b.issue_date_time - a.issue_date_time) // b - a for reverse sort
          this.$forceUpdate()
        }
      }
      if (change.type === 'modified') {
        this.receipts = this.receipts.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            const _document = clone(change.doc.data())
            switch (_document.type.flag) {
              case 'R':
              case 'R1':
              case 'R2':
              case 'BO':
                _document.receiptOptions = [
                  { title: 'Poveži račun', action: this.connectReceipt }
                ]
            }
            if (_document.designation) {
              _document.receiptNumber = `${_document.designation.number}-${_document.designation.location_id}-${_document.designation.register_number}`
            }
            if (_document.issue_date_time) {
              _document.time = _document.issue_date_time.seconds
            }
            if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
              _document.customer = _document.type.payer_name
            } else {
              _document.customer = this.$t('$vuetify.receipts.retailCustomer')
            }
            ret = _document
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }

      this.receipts = this.receipts.filter((document, index, self) =>
        index === self.findIndex((t) => (
          t.id === document.id
        ))
      )
    },
    getReceiptsOrOffers () {
      this.lastVisible = ''
      this.receipts = []
      this.unfiskReceipts = []
      this.detachListeners()

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },

    onExpandFilters () {
      this.filtersExpanded = !this.filtersExpanded
      this.expandIcon = this.filtersExpanded ? 'mdi-minus' : 'mdi-plus'
    },

    parseReceiptNumber (receiptNumber) {
      receiptNumber = receiptNumber.replaceAll('/', '-')
      const parts = receiptNumber.split('-')
      if (parts.length !== 3) {
        return {
          number: 0,
          location_id: '',
          register_number: 0
        }
      }
      return { number: parseInt(parts[0]), location_id: parts[1], register_number: parseInt(parts[2]) }
    },

    getAssociates () {
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((resp) => {
          if (resp && resp.data()) {
            this.defaultAssociates = Object.keys(resp.data().associates)
              .map((key) => {
                return resp.data().associates[key]
              })
              .filter((associate) => associate.status === 'OK' && associate.associate_type.BUYER)
            this.associates = clone(this.defaultAssociates)
          }
        })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    issueBefore (issueDateTime) {
      const tmp = new Date(
        toISOLocal(
          new Date(issueDateTime * 1000)
        )
      )

      tmp.setDate(tmp.getDate() + 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )

      return before
    },
    resetField (field) {
      field === 'oib' ? this.oibFilterManual = '' : this.oibFilter = undefined
      this.applyFilter()
    },

    doSearch () {
      if (!/^(\s*|\d+)$/.test(this.searchNumber)) {
        this.searchNumber = ''
        return
      }
      this.lastVisible = {}
      this.receipts = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    }
  },
  async created () {
    if (state.getCashRegister().id) {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      this.register = register.data()
      this.last_balance_timestamp = register.data().last_balance_timestamp
    }
  },
  mounted () {
    this.setEnterAction(this.doSearch)
    this.setPosition(this.$refs.keyboard, '117px', '502px')
    this.getAssociates()
    this.getMultiplier()
    this.locations = [
      { name: this.$t('$vuetify.all'), id: '', location_id: '' }
    ]
    this.location = this.locations[0]

    this.reciptOffer = 'receipts'

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locs = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)

    let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
      .orderBy('issue_date_time', 'desc')

    const now = parseInt(
      (new Date().getTime() / 1000).toFixed(0)
    )

    const tmp = new Date()
    tmp.setDate(tmp.getDate() - 2)

    const before = parseInt(
      (tmp.getTime() / 1000).toFixed(0)
    )
    query = query.where('fiscalize', '==', true).where('jir', '==', '').where('issue_date_time', '>=', before).where('issue_date_time', '<=', now)

    const listenerForReceipts = query
      .onSnapshot((doc) => {
        doc.docs.forEach((rec) => {
          this.unfiskReceipts.push(rec.data())
        })
        if (doc.docs.length === 0) {
          this.unfiskReceipts = []
        }
      }
      )

    this.listeners.push(listenerForReceipts)

    this.oibFilter = undefined
    this.oibFilterManual = ''
    this.receiptNumberFilter = ''
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px;
}
.panel-container {
  margin-top: 1rem;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.panel-cell {
  padding: 0 1px 1px 0;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.label {
  font-size: 0.6rem;
  color: white;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
