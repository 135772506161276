<template>
  <div>
    <v-navigation-drawer
      class="background nav"
      style="z-index: 10; padding-bottom: 60px; top: 64px"
      :app="
        ($vuetify.breakpoint.lgAndUp && drawerOne) ||
        ($vuetify.breakpoint.mdAndDown && drawerOne)
      "
      :fixed="true"
      v-model="drawerOne"
      :mini-variant.sync="mini"
      permanent
      :disable-resize-watcher="true"
      :expand-on-hover="expand"
      @transitionend="
        setMenuStatus();
        setDrawerExpandedState();
      "
    >
      <v-list dense class="pb-0">
        <div v-for="(item, idx) in items" :key="item.title">
          <v-divider v-if="item.divider" class="mb-4 mt-4"></v-divider>
          <div v-else>
            <v-list-item
              v-if="item.dialog && !item.hidden"
              @click="item.action"
            >
              <v-list-item-icon
                v-if="item.icon"
                class="font-weight-bold primary--text"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              >

              <v-list-item-content>
                <div class="font-weight-bold primary--text">
                  {{ item.title }}
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-if="item.menu && !item.hidden"
              :value="item.active"
              :class="[
                { 'background-color: groupList': item.active },
                'side-group',
                'justify-end',
              ]"
              @click="setActiveMenu(idx)"
              class="ma-0 pa-0 remove_padd"
              :group="item.group"
            >
              <template v-slot:activator>
                <v-list-item
                  active-class="active-link"
                  @input="setActiveMenu(idx)"
                >
                  <v-list-item-icon
                    class="font-weight-bold primary--text"
                    v-if="item.icon"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawerExpanded">
                    <div class="font-weight-bold primary--text">
                      {{ item.title }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-flex v-for="(child, i) in item.children" :key="i">
                <v-list-item
                  v-if="child.hidden !== true"
                  link
                  class="pl-10 ma-0 pa-0 remove_padd"
                  :to="child.to"
                  exact
                  @click="child.changeModule ? setWarehouseModule() : ''"
                >
                  <v-list-item-icon>
                    <v-icon v-text="child.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div>
                      {{ child.title }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-list-group>

            <v-list-item
              v-if="!item.menu && !item.dialog && !item.hidden"
              @click="setActiveMenu(idx)"
              link
              :to="item.to !== '' ? item.to : ''"
              exact
              style="height: 48px !important"
              class="ma-0 group_padding"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon class="mt-2">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="drawerExpanded">
                <div class="font-weight-bold primary--text">
                  {{ item.title }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
      <!-- <div style="position: relative"></div> -->

      <v-list-item
        class="ma-0 group_padding"
        style="
          position: relative;
          bottom: 1;
          cursor: pointer;
          height: 48px !important;
        "
        @click="logOut"
      >
        <v-list-item-icon class="mt-3">
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold primary--text">{{
            $t("$vuetify.formDetails.logOut")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>
<script>
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import applicationSettings from '@/mixins/applicationSettings'
import logOutUser from '@/mixins/logOutUser'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'

export default {
  mixins: [applicationSettings, logOutUser],
  data: () => ({
    drawerOne: false,
    drawerExpanded: false,
    expand: false,
    items: [],
    drawerKey: 1,
    mini: true,
    companySelected: false,
    warehouseModuleAction: 'Aktiviraj modul skladište'
  }),
  computed: {
    drawerModel: {
      set () {},
      get () {
        return (
          this.$vuetify.breakpoint.lgAndUp ||
          (this.drawerOne && this.$vuetify.breakpoint.mdAndDown)
        )
      }
    },
    sideSize () {
      let ret = 240
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          ret = '80%'
          break
        case 'sm':
          ret = '80%'
          break
        case 'md':
          ret = 250
          break
        case 'lg': {
          ret = 250
        }
      }
      return ret
    }
  },
  watch: {
    $route (to) {
      this.setRouteData(to.meta.sideNav)
    },
    drawerOne (nv) {
      if (nv === true && this.mini === true) {
        this.drawerOne = false
      }

      state.setDrawerOne(this.drawerOne)

      this.expand = !nv
    },
    mini (nv) {
      if (nv === true && this.drawerOne === true) {
        this.drawerOne = false
      }
    }
  },
  methods: {
    setWarehouseModule () {
      const that = this

      let newWarehouse = 'basic'
      switch (state.getCurrentCompany().warehouse) {
        case 'basic':
          newWarehouse = 'module'
          break
        case 'module':
          newWarehouse = 'basic'
          break
      }

      try {
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'set',
            entity: 'warehouse_module',
            resource_id: state.getCurrentCompany().id,
            params: {
              warehouse: newWarehouse
            }
          }
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'warehouse',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function (doc) {
          })
          .catch(function (error) {
            // console.error('Error writing document: ', error)
            that.showMsgBox({
              text: error || 'Internal error',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
          .finally(() => {})
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    },
    setMenuStatus () {
      if (this.mini) {
        this.items.forEach(item => {
          if (item.active) {
            item.active = false
            item._active = true
          }
        })
      } else {
        this.items.forEach(item => {
          if (item._active) {
            item.active = true
            item._active = false
          }
        })
      }
      this.$forceUpdate()
    },
    setDrawerExpandedState () {
      if (!this.mini) {
        this.drawerExpanded = true
      } else {
        this.drawerExpanded = false
      }
      this.$forceUpdate()
    },
    setActiveMenu (itemIndex) {
      this.items = this.items.map((item, id) => {
        if (id === itemIndex) {
          item.active = true
        } else {
          item.active = false
        }
        return item
      })
    },

    setRouteData (sideNav) {
      const usersTabVisible =
        state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN

      const comp = state.getCurrentCompany()
      let hideOldWarehouse = false

      if (comp.active_products !== undefined) {
        Object.keys(comp.active_products).forEach((id) => {
          if (id === '232a3f0b-999a-4742-ab1a-5094264e6c24' && comp.active_products[id].active === true) {
            hideOldWarehouse = true
          }
        })
      }

      const adminKnjigovođa = state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.KNJIGOVOĐA || state.getUser()?.roles?.ADMIN

      let targetModifier = ''

      switch (sideNav) {
        case 'Companies':
          break
        case 'Company':
          targetModifier = 'company'
          break
        case 'PointOfSale':
          targetModifier = 'pointOfSale'
          break
        case 'CashRegister':
          targetModifier = 'pointOfSale'
          break
      }

      var userNotSupport = true
      if (auth.currentUser.uid === 'Zg4qIpxX2uNGKvjznSVnICZAVtJ2') {
        userNotSupport = false
      }

      const sideNavItems = [
        {
          // ADMIN
          appearsOn: [
            // 'Companies',
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.cashRegisterTabs.admin'),
          icon: 'mdi-account-group-outline',
          group: 'items',
          to: { name: `${targetModifier}.admin` },
          hidden: userNotSupport,
          menu: true,
          children: [
            {
              appearsOn: [
                // 'Companies',
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.companySettings'),
              icon: 'mdi-note-text',
              to: { name: `${targetModifier}.admin.companySettings` }
            },
            {
              appearsOn: [
                // 'Companies',
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.companyAdd'),
              icon: 'mdi-food-variant',
              to: { name: `${targetModifier}.admin.companyAdd` }
            }
          ]
        },
        {
          // MOJE FIRME
          appearsOn: [
            'Companies',
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.cashRegisterTabs.myCompanis'),
          icon: 'mdi-briefcase-outline',
          to: { name: 'companies' }
        },
        {
          // PRODAJNA MJESTA
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.cashRegisterTabs.pointsOfSale'),
          icon: 'mdi-crosshairs-gps',
          to: { name: 'company.pointsOfSale' }
        },
        {
          // BLAGAJNE
          appearsOn: [
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.cashRegisterTabs.registers'),
          icon: 'mdi-cash-register',
          to: { name: `${targetModifier}.cashRegisters` }
        },
        {
          // POSTAVKE
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.userDataTabs.userData'),
          icon: 'mdi-cog',
          menu: true,
          children: [
            {
              // Platne kartice
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: 'Platne kartice',
              icon: 'mdi-credit-card-settings-outline',
              hidden: !usersTabVisible,
              to: { name: targetModifier ? `${targetModifier}.cardConfiguration` : 'cardConfiguration' }
            },
            {
              // POS UREĐAJI
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: 'POS uređaji',
              icon: 'mdi-account-credit-card-outline',
              hidden: !usersTabVisible,
              to: { name: targetModifier ? `${targetModifier}.posConfiguration` : 'posConfiguration' }
            },
            {
              // PRINTER
              appearsOn: [
                'PointOfSale',
                'CashRegister'
              ],
              title: 'Printeri',
              icon: 'mdi-printer-settings',
              hidden: !usersTabVisible,
              to: { name: targetModifier ? `${targetModifier}.printerConfiguration` : 'printerConfiguration' }
            },
            {
              // USTROJSTVENA JEDINICA
              appearsOn: [
                'PointOfSale',
                'CashRegister'
              ],
              title: 'Ustrojstvena jedinica',
              icon: 'mdi-animation',
              hidden: !usersTabVisible,
              to: { name: targetModifier ? `${targetModifier}.organizationalUnitConfiguration` : 'organizationalUnitConfiguration' }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.userDataTabs.bill'),
              icon: 'mdi-note-text',
              to: { name: targetModifier === 'company' ? 'userData.bill' : 'pointOfSale.userData.bill' }
            },
            {
              // KORISNICI
              appearsOn: [
                'Companies',
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.companyTabs.users'),
              icon: 'mdi-account-multiple-outline',
              hidden: !usersTabVisible,
              to: { name: targetModifier ? `${targetModifier}.users` : 'users' }
            },
            {
              // Komunikacija
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: 'Komunikacija',
              icon: 'mdi-email-box',
              hidden: !usersTabVisible,
              to: { name: targetModifier ? `${targetModifier}.communicationConfiguration` : 'communicationConfiguration' }
            }
            // {
            //   appearsOn: [
            //     'Company',
            //     'PointOfSale',
            //     'CashRegister'
            //   ],
            //   title: this.warehouseModuleAction,
            //   icon: 'mdi-logout',
            //   changeModule: true
            // }
          ]
        },
        {
          // DIVIDER
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          divider: true
        },

        {
          // ARTIKLI
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.companyTabs.items'),
          icon: 'mdi-basket',
          group: 'items',
          to: { name: `${targetModifier}.items` },
          menu: true,
          children: [
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.lager'),
              icon: 'mdi-note-text',
              group: 'lager',
              to: { name: `${targetModifier}.items.lager` }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.categories'),
              icon: 'mdi-food-variant',
              group: 'categories',
              to: { name: `${targetModifier}.items.categories` }
            },
            // {
            //   appearsOn: [
            //     'Company',
            //     'PointOfSale',
            //     'CashRegister'
            //   ],
            //   title: this.$t('$vuetify.pointsOfSaleTabs.uploadItems'),
            //   icon: 'mdi-file-upload',
            //   group: 'upload',
            //   to: { name: `${targetModifier}.items.upload` }
            // },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.discounts'),
              icon: 'mdi-percent',
              to: { name: `${targetModifier}.items.discount` }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.companyTabs.norm'),
              icon: 'mdi-clipboard-list',
              hidden: !hideOldWarehouse,
              group: 'norm',
              to: { name: `${targetModifier}.items.norm` }
            }, {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.restaurant.menu'),
              hidden: !hideOldWarehouse,
              icon: 'mdi-food-fork-drink',
              group: 'restarant-menu',
              to: { name: `${targetModifier}.restaurant.menuItems` }
            }
          ]
        },
        {
          // SKLADISTE
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          hidden: !hideOldWarehouse,
          title: this.$t('$vuetify.warehouse.titleText'),
          icon: 'mdi-warehouse',
          to: { name: `${targetModifier}.warehouseModule` },
          menu: { title: 'meni' },
          children: [
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.warehouseItems'),
              icon: 'mdi-form-select',
              to: {
                name: `${targetModifier}.warehouseModule.items`
              }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.warehouseDocuments'),
              icon: 'mdi-book-open-variant',
              to: {
                name: `${targetModifier}.warehouseModule.documents`
              }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.primka'),
              icon: 'mdi-book-open-variant',
              to: {
                name: `${targetModifier}.warehouseModule.documentsv2`
              }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.warehouseDocumentsOverview'),
              icon: 'mdi-book-open-variant',
              to: {
                name: `${targetModifier}.warehouseModule.warehouseDocumentsOverview`
              }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.inventory'),
              icon: 'mdi-clipboard-list-outline',
              to: {
                name: `${targetModifier}.warehouseModule.inventories`
              }
            }
          ]
        },
        {
          // CJENIK
          appearsOn: [
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.cashRegisterTabs.priceList'),
          icon: 'mdi-list-status',
          to: { name: `${targetModifier}.priceList` }
        },
        {
          // PRODAJNO MJESTO
          appearsOn: [
            'PointOfSale',
            'CashRegister'
          ],
          hidden: hideOldWarehouse,
          title: this.$t('$vuetify.warehouse.titleText2'),
          icon: 'mdi-warehouse',
          to: { name: `${targetModifier}.warehouse` }
        },
        {
          // NARUDŽBE
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          hidden: !hideOldWarehouse,
          title: this.$t('$vuetify.orders.titleText'),
          icon: 'mdi-order-bool-descending-variant',
          menu: true,
          children: [
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.orders.createOrder'),
              icon: 'mdi-border-color',
              to: {
                name: `${targetModifier}.orders.createOrder`
              }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.orders.showOrders'),
              icon: 'mdi-book-open-variant',
              to: {
                name: `${targetModifier}.orders.showOrders`
              }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.company.suppliers'),
              icon: 'mdi-human-dolly',
              to: {
                name: `${targetModifier}.suppliers`
              }
            }
          ]
        },
        {
          // RAČUNI I PONUDE
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.cashRegisterTabs.receipts'),
          icon: 'mdi-printer',
          to: { name: `${targetModifier}.receipts` }
        },
        {
          // KLIJENTI
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.pointsOfSaleTabs.associates'),
          icon: 'mdi-account-multiple-outline',
          to: { name: `${targetModifier}.associates` }
        },
        {
          // MASTER
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.pointsOfSaleTabs.master'),
          icon: 'mdi-shield-crown-outline',
          to: { name: `${targetModifier}.all-reports.reportMenu` },
          hidden: !adminKnjigovođa
        },
        {
          // IZVJEŠTAJI
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.cashRegisterTabs.reports'),
          icon: 'mdi-window-maximize',
          to: { name: 'pointOfSale.reports' },
          menu: { title: 'meni' },
          children: [
            {
              // ZAKLJUČCI BLAGAJNE
              appearsOn: [
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.cashRegisterTabs.closeBalanceTitles'),
              icon: 'mdi-book-open-variant',
              to: { name: `${targetModifier}.closeBalance.displayCloseBalance` }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.cashRegisterTabs.posdReport'),
              icon: 'mdi-form-select',
              to: { name: `${targetModifier}.reports.reportPosd` }
            },
            {
              appearsOn: [
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.cashRegisterTabs.kprReport'),
              icon: 'mdi-book-open-variant',
              to: { name: 'pointOfSale.reports.reportKpr' }
            },
            {
              appearsOn: [
                'Company',
                'PointOfSale',
                'CashRegister'
              ],
              title: this.$t('$vuetify.pointsOfSaleTabs.groupageColection'),
              icon: 'mdi-book-open-variant',
              to: { name: 'pointOfSale.reports.groupageColection' }
            }
          ]
        },
        {
          // OBAVJESTI
          appearsOn: [
            'Company',
            'PointOfSale',
            'CashRegister'
          ],
          title: this.$t('$vuetify.notifications.title'),
          icon: 'mdi-shield-crown-outline',
          to: { name: `${targetModifier}.notifications` }
        }
      ]

      this.items = sideNavItems.filter(i => {
        return i.appearsOn.includes(sideNav)
      })
      this.items.forEach(element => {
        if (element.menu) {
          const filteredMenu = element.children.filter(c => {
            return c.appearsOn.includes(sideNav)
          })
          element.children = [...filteredMenu]
        }
      })
    }
  },
  created () {
  },

  mounted () {
    if (this.$route.name !== 'companies') {
      this.companySelected = true
    }

    if (state.getCurrentCompany().warehouse !== undefined && state.getCurrentCompany().warehouse !== '' && state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModuleAction = 'Deaktiviraj modul skladište'
    }
    this.drawerOne = false
    EventBus.$on('set-mini', () => {
      this.mini = !this.mini
      this.drawerOne = !this.drawerOne
    })

    this.setRouteData(this.$route.meta.sideNav)
  }
}
</script>

<style scoped>
.remove_padd >>> .v-list-group__header {
  padding: 0 !important;
}

.group_padding >>> .v-list-group__header {
  padding: 5 !important;
}

.side-group >>> .v-list-group__header {
  padding: 0 !important;
  height: 48px;
}
</style>
