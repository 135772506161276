<template>
  <div>
    <v-container
      v-if="!companies || companies.length === 0"
      class="d-flex fill-height justify-center"
      style="height: 800px"
    >
      <div>
        Još nemate niti jednu dodanu firmu.
        <a href="" @click.prevent="addCompany">Dodaj firmu</a>
      </div>
    </v-container>

    <v-container
      v-else
      fluid
      class="d-flex justify-center align-center flex-wrap"
      style="position: relative"
    >
      <v-row>
        <v-spacer></v-spacer>
        <Search-box
          :emitDestination="searchEmitDestination"
          searchPlaceholder="Pretraži firme"
        ></Search-box>
      </v-row>

      <v-row
        class="justify-center fill-height ma-0 pa-0"
        style="position: absolute"
      >
        <v-card
          autofocus
          class="align-center ma-5"
          v-for="(company, i) in sortedCompanies"
          :key="i"
          hover
          height="300"
          width="250"
          @click="openCompany(company)"
          @keypress.enter.stop="openCompany(company)"
          :ref="`company${i}`"
        >
          <v-img
            :lazy-src="require('@/assets/unknown_profile.jpg')"
            :src="
              company.logo
                ? company.logo
                : require('@/assets/unknown_profile.jpg')
            "
            class="white--text align-end"
            contain
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="245px"
          >
            <v-card-title class="one-liner-text">
              {{ company.name }}
            </v-card-title>
            <v-card-subtitle class="white--text one-liner-text">
              <v-tooltip bottom>
                <template #activator="{ on: on }">
                  <div v-on="on">
                    Status: {{ translateStatus(company.status) }}
                  </div>
                </template>
                {{ getStatusTooltip(company.status) }}
              </v-tooltip>
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="addCertificate(company)"
                  @keypress.enter.stop="addCertificate(company)"
                  icon
                >
                  <v-icon :color="company.certificate ? 'success' : 'error'"
                    >mdi-certificate</v-icon
                  >
                </v-btn>
              </template>
              <span>{{
                company.certificate
                  ? "Uspješno dodan certifikat za fiskalizaciju"
                  : "Certifikat za fiskalizaciju nije dodan"
              }}</span>
            </v-tooltip>
            <v-menu offset-y nudge-left="85" v-if="!hideMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @keypress.enter.stop="">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in companyOptions"
                  :key="index"
                  style="cursor: pointer"
                  @click="`${item.action(company)}`"
                  @keypress.enter.prevent="`${item.action(company)}`"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
    <edit-company
      ref="editCompany"
      @editSuccess="refreshEditCompany"
    ></edit-company>
    <edit-receipt-settings
      ref="editReceiptSettings"
      @editSuccess="refreshEditCompany"
    ></edit-receipt-settings>
    <certificate-upload ref="certificateUpload"></certificate-upload>
  </div>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import EditCompany from '@/modules/companies/components/EditCompany'
import EditReceiptSettings from '@/modules/companies/components/EditReceiptSettings'
import CertificateUpload from '@/modules/companies/components/CertificateUploadForm'
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
import EventBus from '@/plugins/event-bus'

export default {
  components: {
    EditCompany,
    EditReceiptSettings,
    CertificateUpload
  },
  inject: ['showLoader', 'hideLoader'],
  mixins: [applicationSettings],
  data: (vm) => ({
    presentCompanies: [],
    companies: [],
    sortedCompanies: [],
    loading: false,
    menu: false,
    hideMenu: false,
    companyOptions: [
      { title: 'Dodaj certifikat', action: vm.addCertificate },
      { title: 'Postavke firme', action: vm.editCompany },
      { title: 'Postavke računa', action: vm.editReceiptSettings }
    ],
    unsubscribe: undefined,
    searchEmitDestination: 'companiesSearch',
    isMounted: false

  }),
  computed: {
    companiesTabs () {
      return [
        {
          title: this.$t('$vuetify.companiesTabs'),
          icon: 'mdi-briefcase-outline'
        }
      ]
    }
  },
  watch: {
    companies: {
      handler (nv) {
        if (this.search !== '') {
          this.presentCompanies = nv.filter(
            (company) =>
              company.name &&
              company.name.toLowerCase().includes(this.search.toLowerCase())
          )
        } else {
          this.presentCompanies = nv
        }
        this.sortedCompanies = this.sortCompanies()
      },
      deep: true,
      immediate: true
    }
    // search (val) {
    //   console.log('searchVal', val)
    //   if (val && val !== '') {
    //     this.presentCompanies = this.companies.filter(
    //       (company) =>
    //         company.name &&
    //         company.name.toLowerCase().includes(val.toLowerCase())
    //     )
    //   } else {
    //     this.presentCompanies = this.companies
    //   }
    //   this.sortedCompanies = this.sortCompanies()
    // },
  },
  mounted () {
    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchAll(searchObject.searchString)
    })

    const user = state.getUser()

    if (!user.roles.ADMIN && !user.roles.SUPERADMIN) {
      this.hideMenu = true
    }
    // document.onkeydown = this.handleKeyEvents
    try {
      this.$refs.company0[0].$el.focus()
    } catch {
      setTimeout(() => {
        if (this.$refs.company0 && this.$refs.company0[0]) {
          this.$refs.company0[0].$el.focus()
        }
      }, 500)
    }

    this.isMounted = true

    // state.removeCurrentCompany()
  },
  created () {
    const docRef = df.doc(`user_companies/${auth.currentUser.uid}`)
    this.unsubscribe = docRef.onSnapshot((doc) => {
      const data = doc.data()
      if (data?.companies) {
        this.companies = Object.keys(data.companies)
          .map((key) => {
            return data.companies[key]
          })
          .filter((company) => company?.resource_status === 'ACTIVE')
          .sort((company1, company2) => {
            if (company1.id < company2.id) return -1
            if (company1.id > company2.id) return 1
          })
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  methods: {
    searchAll (val) {
      if (val && val !== '') {
        this.presentCompanies = this.companies.filter(
          (company) =>
            company.name &&
            company.name.toLowerCase().includes(val.toLowerCase())
        )
      } else {
        this.presentCompanies = this.companies
      }
      this.sortedCompanies = this.sortCompanies()
    },
    translateStatus (status) {
      if (!status) return ''
      if (status.toUpperCase() === 'OK' || status.toUpperCase() === 'PROCESSING') {
        return status
      } else {
        return this.$t('$vuetify.errors.errorTitle')
      }
    },
    getStatusTooltip (status) {
      if (!status) return ''
      if (status.toUpperCase() === 'OK' || status.toUpperCase() === 'PROCESSING') {
        return status
      } else if (status.toUpperCase() === 'DUPLICATE') {
        return this.$t('$vuetify.errors.duplicateCompany')
      } else {
        return status
      }
    },
    sortCompanies () {
      this.sortedCompanies = clone(this.presentCompanies)
      return this.sortedCompanies.sort((a, b) => a.name.localeCompare(b.name))
    },
    openCompany (company) {
      if (company.status !== 'OK') {
        return
      }
      state.setCurrentCompany(company)
      // COMMENT - DODANO ZATO DA ODSETIRA LOKACIJU AKO JE ODABRANA NOVA FIRMA
      // SKLADISTE DOKUMENTI MENI OVISE O OVOME
      state.removePointOfSale()

      state.setAppTitle(company.name)

      this.$router.push({
        name: 'company',
        params: { companyId: company.id }
      })
    },
    addCompany () {
      this.$refs.editCompany.open()
    },
    editCompany (copmany) {
      this.$refs.editCompany.open(copmany)
    },
    editReceiptSettings (copmany) {
      this.$refs.editReceiptSettings.open(copmany)
    },
    refreshCompanies (company) {
      if (company.name.toLowerCase().includes(this.search.toLowerCase())) {
        this.presentCompanies.push({
          name: company.name,
          status: 'PROCESSING'
        })
      }
    },
    refreshEditCompany (company) {
      this.presentCompanies = this.presentCompanies.map((com) => {
        if (com.id === company.id) {
          com.status = 'PROCESSING'
        }
        return com
      })
    },
    addCertificate (company) {
      this.$refs.certificateUpload.open(company)
    },
    handleKeyEvents (e) {
      e = e || window.event

      switch (e.keyCode) {
        case 70: {
          // F -> search
          if (!this.$refs.companies_search.isFocused) {
            this.$refs.companies_search.focus()
            e.preventDefault()
          }
          break
        }
      }
    }
  }
}
</script>
<style scoped>
.card-title {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
}

.img {
  height: 100vh;
}

.one-liner-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
