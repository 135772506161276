<template>
  <div style="width: 100%">
    <cash-register-top-bar
      v-if="registerType === 'cash'"
    ></cash-register-top-bar>
    <panel-register-top-bar
      v-if="
        registerType === 'panel' ||
        registerType === 'ai-caffe-wide' ||
        registerType === 'bottom-up' ||
        registerType === 'ai-crud'
      "
    ></panel-register-top-bar>
  </div>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import CashRegisterTopBar from '@/components/top-bar/components/CashRegisterTopBar'
import PanelRegisterTopBar from '@/components/top-bar/components/PanelRegisterTopBar'

export default {
  components: {
    CashRegisterTopBar,
    PanelRegisterTopBar
  },
  data () {
    return {
      registerType: undefined
    }
  },
  methods: {
    async setRegisterTemplate () {
      state.resetRegisterType()
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (register && register.data()) {
        const doc = register.data()
        if (doc && doc.register_template) {
          state.setRegisterType(doc.register_template)
        } else {
          state.setRegisterType('cash')
        }
      } else {
        state.setRegisterType('cash')
      }
      this.registerType = state.getRegisterType()
    }
  },
  async created () {
    this.setRegisterTemplate()

    EventBus.$on('change-register-template', () => {
      this.registerType = state.getRegisterType()
    })
  }
}
</script>
