<template>
  <v-text-field v-bind="$attrs"
    v-on="_events"
    v-money="money"> </v-text-field>
</template>
<script>

import { VMoney } from 'v-money'

export default {
  name: 'MoneyInput',
  directives: { money: VMoney },
  props: ['currency'],
  data: function () {
    return {
      currencies: [
        {
          id: 'HRK',
          options: {
            decimal: ',',
            thousands: '.',
            precision: 2,
            suffix: ' HRK'
          }
        },
        {
          id: 'EUR',
          options: {
            decimal: ',',
            thousands: '.',
            precision: 2,
            suffix: ' €'
          }
        },
        {
          id: 'USD',
          options: {
            decimal: '.',
            thousands: ',',
            precision: 2,
            prefix: '$ '
          }
        },
        {
          id: 'GBP',
          options: {
            decimal: ',',
            thousands: '.',
            precision: 2,
            prefix: '£ '
          }
        },
        {
          id: '%',
          options: {
            decimal: ',',
            thousands: '.',
            precision: 2,
            suffix: ' %'
          }
        }
      ],
      money: {}
    }
  },
  watch: {
    currency (val) {
      if (this.currencies.some(currency => currency.id === val)) {
        this.money = this.currencies.find(currency => currency.id === val).options
      } else {
        this.setDefaultCurrency()
      }
    }
  },
  created () {
    this.setDefaultCurrency()
  },
  methods: {
    setDefaultCurrency () {
      this.currencyDefaults = this.currencies.find(c => c.id === this.currency)
      this.money = this.currencyDefaults ? this.currencyDefaults.options : this.currencies.find(c => c.id === 'HRK').options
    },
    currencyToFloat (currency) {
      const curRe = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/
      const parts = curRe.exec(currency)
      const number = parseFloat(parts[1].replace(/\D/g, '') + '.' + (parts[2] ? parts[2] : '00'))
      return parseFloat(number.toFixed(2))
    }
  }
}
</script>
