<template>
  <input
    ref="inputRef"
    style="
      border-radius: 4px;
      border: 1px solid #757575;
      padding: 5px;
      width: 99%;
      font-size: initial;
    "
  />
</template>

<script>
import { watch } from '@vue/composition-api'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    value: Number, // Vue 2: value
    options: Object
  },
  setup (props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)

    watch(
      () => props.value, // Vue 2: props.value
      (value) => {
        if (typeof (value) === 'number') { setValue(value) }
      }
    )

    watch(
      () => props.options.currency,
      (options) => {
        setOptions(props.options)
      }
    )

    return { inputRef }
  }
}
</script>
