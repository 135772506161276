import { compactAduro, normalAduro, numericAduro } from '@/assets/keyboardLayouts/aduroHr'
import '@/assets/styles/oskStyles.css'
import { dragElement } from '@/libs/keyboard/draggable'
import state from '@/state'

export default {
  data: () => ({
    oskVisible: false,
    layout: '',
    oskOptions: {
      useKbEvents: false,
      preventClickEvent: false
    },
    input: null,
    width: '500px',
    enterAction: () => { }
  }),
  methods: {
    setEnterAction (action) {
      this.enterAction = action
    },
    onOskChange () {
      state.setOsk(this.switchOnScreenKeyboard)
    },
    accept () {
      this.enterAction()
      this.hideKbd()
    },
    resolveLayout (layout) {
      switch (layout) {
        case 'numericAduro':
          return numericAduro
        case 'normalAduro':
          return normalAduro
        case 'compactAduro':
          return compactAduro
        default:
          return layout
      }
    },
    setPosition (ref, top, left) {
      ref.style.top = top
      ref.style.left = left
    },
    showKbd (e, prop) {
      if (state.getOsk()) {
        this.input = e.target
        this.layout = this.resolveLayout(e.target.dataset.layout)
        this.setWidth(e.target.dataset.layout)
        if (!this.oskVisible) { this.oskVisible = true }
      }
    },
    showKbdOnMount (ref, layout) {
      if (state.getOsk()) {
        this.input = ref
        this.layout = this.resolveLayout(layout)
        this.setWidth(layout)
        if (!this.oskVisible) { this.oskVisible = true }
      }
    },
    hideKbd () {
      this.oskVisible = false
    },
    setWidth (layout) {
      switch (layout) {
        case 'normal':
        case 'normalAduro':
          this.width = '890px'
          break
        case 'numeric':
        case 'numericAduro':
          this.width = '481px'
          break
        case 'compact':
        case 'compactAduro':
          this.width = '650px'
          break
      }
    }
  },
  mounted () {
    state.resetOsk()
    dragElement(this.$refs.keyboard)
  }
}
