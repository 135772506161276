export default class Company {
  // eslint-disable-next-line space-before-function-paren
  constructor() {
    this.active_products = []
    this.address = ''
    this.associated_oibs = []
    this.bank_accounts = []
    this.certificate = ''
    this.city = ''
    this.country = 'Hrvatska'
    this.domain = ''
    this.id = ''
    this.is_taxed = false
    this.kelner = false
    this.legal_info = {
      bank: {},
      base_capital: 0,
      base_capital_currency: 'EUR',
      court: '',
      court_register_id: '',
      members: []
    }
    this.logo = ''
    this.name = ''
    this.oib = ''
    this.print_eur = false
    this.print_template = 'racun02'
    this.receipt_type = ''
    this.status = 'OK'
    this.tax_type = 'income'
    this.type = 'pausalni_obrt'
    this.warehouse = 'basic'
    this.zip_code = ''
  }
}
