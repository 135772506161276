import * as JSPM from 'jsprintmanager'
import state from '@/state'

export const initPrintManager = (successHook, failureHook) => {
  JSPM.JSPrintManager.auto_reconnect = false
  JSPM.JSPrintManager.license_url = state.getJsPrintManagerLicenceUrl()

  JSPM.JSPrintManager.start()
  JSPM.JSPrintManager.WS.onStatusChanged = function () {
    switch (JSPM.JSPrintManager.websocket_status) {
      case JSPM.WSStatus.Open:
        console.info('JSPM is running')
        if (successHook) { successHook() }
        break
      case JSPM.WSStatus.Closed:
        console.error('JSPM is not installed or not running!')
        alert('Pokrenite JsPrintManager klijentsku aplikaciju i osvježite preglednik, ili odaberite drugi printer.')
        if (failureHook) { failureHook() }
        break
      case JSPM.WSStatus.Blocked:
        if (failureHook) { failureHook() }
        console.error('JSPM has blocked this website!')
        break
    }
  }
}

export const jspmWSStatus = async () => {
  if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
    return true
  } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
    console.log('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm')
    return false
  } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
    console.log('JSPM has blocked this website!')
    return false
  }
}

export const getPrinterSettings = (font, size) => {
  const lineLength = getLineLength(font, size)
  return { font: font, size: size, lineLength: lineLength }
}

export const getLineLength = (font, size) => {
  switch (font.toLowerCase()) {
    case 'a':
      switch (size) {
        case 0:
          return 48
        case 1:
          return 24
        case 2:
          return 16
        case 3:
          return 12
      }
      break
    case 'b':
    case 'c':
      switch (size) {
        case 0:
          return 64
        case 1:
          return 32
        case 2:
          return 21
        case 3:
          return 16
      }
      break
  }
}

export const checkJspmStatus = async () => {
  let status = null
  await JSPM.JSPrintManager.start()
    .then(_ => {
      if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        console.log('JSPM is running.')
        status = true
      } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        console.log('JSPM is not installed or not running!')
        status = false
      } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.BlackListed) {
        console.log('JSPM has blacklisted this website!')
        status = false
      }
    })
    .catch((e) => {
      console.log('JSPM is not installed or not running!')
      status = false
    })
  return status
}

export const printerErrors = (err) => {
  switch (err) {
    case 'JSPM_not_running':
      alert('Pokrenite JsPrintManager klijentsku aplikaciju i osvježite preglednik, ili odaberite drugi printer.')
      break
    default:
      break
  }
}
