export default class LogData {
  // eslint-disable-next-line space-before-function-paren
  constructor() {
    this.company_id = ''
    this.company_name = ''
    this.location_id = ''
    this.location_name = ''
    this.cash_register_id = 0
    this.cash_register_name = ''
    this.action_id = ''
    this.action_name = ''
    this.description = ''
    this.user_id = ''
  }
}
