<template>
  <!-- <div style="width: 100%"> -->
  <v-row class="text-h4 primary--text align-center" style="overflow: hidden">
    <v-col
      md="4"
      lg="5"
      class="hidden-md-and-down one-liner-text mb-0 pb-3 flex-grow-1"
      style="min-width: 100px; max-width: 100%"
    >
      {{ title }}
      <v-btn
        class="white--text rounded-card"
        color="fiscButton"
        @click="routeToReceipts"
        id="routeReceiptsBtn"
        v-if="unfiskReceipts.length !== 0 && !$route.name.includes('receipts')"
        >Broj nefiskaliziranih računa: {{ unfiskReceipts.length }}
      </v-btn>
    </v-col>

    <v-col
      cols="10"
      sm="11"
      md="7"
      lg="6"
      xl="7"
      class="d-flex align-center justify-end"
    >
      <v-text-field
        @click="showKbd"
        data-layout="normal"
        append-icon="mdi-magnify"
        clearable
        v-if="
          $route.meta.removeSearch ||
          registerMode === 'panel' ||
          registerMode === 'ai-crud'
            ? false
            : true
        "
        class="pr-5 pt-5"
        :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '50%' : '100%' }"
        v-model="topSearch"
        @input="filterItems()"
        @click:clear="
          filterItems({ reset: true });
          searchItems({ reset: true });
        "
        :placeholder="
          $route.meta.filterSearch
            ? $t('$vuetify.filterPlaceholderLabel')
            : $t('$vuetify.searchLabel')
        "
        @keydown.enter="searchItems()"
      >
      </v-text-field>
      <v-btn
        :style="{
          'max-width': $vuetify.breakpoint.mdAndUp ? '50%' : '100%',
          'font-size': '10px',
        }"
        plain
        class="font-weight-bold"
        outlined
        color="primary"
        @click="logoutCashRegister"
        v-if="showCashRegLogout"
      >
        {{ "Odjavi se s blagajne" }}
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text plain v-bind="attrs" v-on="on" @keypress.enter.stop="">
            {{ $t("$vuetify.cashRegister.optionsText") }}
            <v-icon v-if="menu">mdi-menu-up</v-icon>
            <v-icon v-else>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in cashRegisterOptions"
            :key="index"
            style="cursor: pointer"
            @click="`${item.action()}`"
            @keypress.enter.prevent="`${item.action()}`"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-tooltip bottom>
        <template
          v-if="$route.meta.removeGrid ? false : true"
          v-slot:activator="{ on, attrs }"
        >
          <v-btn v-on="on" v-bind="attrs" plain text @click="changeView">
            <v-icon v-if="listView">mdi-view-grid</v-icon>
            <v-icon v-else>mdi-view-list</v-icon>
          </v-btn>
        </template>
        <span>{{ listView ? "Prikazi kao grid" : "Prikazi kao listu" }}</span>
      </v-tooltip> -->
    </v-col>
    <change-template-dialog
      v-if="templatesDialogOpen"
      @selectTempate="changeCashRegisterTemplate"
      @closeTemplatesDialog="onCloseTemplatesDialog"
    ></change-template-dialog>
  </v-row>
  <!-- <v-container class="kbd"
v-if="!$vuetify.breakpoint.smAndDown" ref="keyboard" v-show="oskVisible" :style="{ 'max-width': width }">
      <div class="kbd-header"></div>
      <vue-touch-keyboard :options="oskOptions" :layout="layout" :cancel="hideKbd" :accept="accept" :input="input" />
    </v-container> -->
  <!-- </div> -->
</template>
<script>
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
import { auth, df } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'
import ChangeTemplateDialog from '@/components/top-bar/components/ChangeTemplateDialog'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import LogData from '@/model/LogData'
import log from '@/mixins/log'

export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [applicationSettings, onScreenKeyboard, log],
  components: {
    ChangeTemplateDialog
  },
  data: function (vm) {
    return {
      templatesDialogOpen: false,
      switchOnScreenKeyboard: true,
      registerMode: '',
      menu: false,
      topSearch: '',
      cashRegisterOptions: [
        { title: vm.$t('$vuetify.cashRegister.closeBalanceTitle'), action: vm.closeBalance },
        { title: vm.$t('$vuetify.cashRegister.selectTemplate'), action: vm.showTemplateDialog }
        // { title: vm.$t('$vuetify.cashRegister.createRegisterReport'), action: vm.createReport }
      ],
      unfiskReceipts: [],
      listeners: [],
      showCashRegLogout: false,
      switchOnOrdersTable: false,
      onLine: true,
      selectedPrinter: {}
    }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  async created () {
    this.registerMode = await this.setRegisterTemplate()
    this.selectedPrinter = state.getSelectedPrinter()
  },
  mounted () {
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
      // console.log('this.selectedPrinter', this.selectedPrinter)
    })

    if (state.getCurrentCompany().id !== '') {
      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .orderBy('issue_date_time', 'desc')

      const now = parseInt(
        (new Date().getTime() / 1000).toFixed(0)
      )

      const tmp = new Date()
      tmp.setDate(tmp.getDate() - 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )
      query = query.where('fiscalize', '==', true).where('jir', '==', '').where('issue_date_time', '>=', before).where('issue_date_time', '<=', now)

      const listener = query
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            this.unfiskReceipts.push(rec.data())
          })
          if (doc.docs.length === 0) {
            this.unfiskReceipts = []
          }
        }
        )

      this.listeners.push(listener)
    }

    const cashReg = state.getCashRegister()
    if (cashReg.login_type !== undefined && cashReg.login_type !== '' && cashReg.login_type !== 'DEFAULT') {
      this.showCashRegLogout = true
    }
  },
  computed: {
    title () {
      return state.getCashRegister().name
    },
    listView () {
      return state.isListView()
    }
  },
  watch: {
    filter (val) {
      if (val !== this.topSearch) {
        this.topSearch = val
      }
    },
    search (val) {
      if (val !== this.topSearch) {
        this.topSearch = val
      }
    },
    registerMode (val) {
      // console.log('VAL', val)
    }
  },
  methods: {
    onCloseTemplatesDialog () {
      this.templatesDialogOpen = false
    },
    showTemplateDialog () {
      this.templatesDialogOpen = true
    },
    changeCashRegisterTemplate () {
      this.hideKbd()
      if (state.getRegisterType() === 'cash') {
        state.setRegisterType('cash')
        this.registerMode = 'cash'
      } else if (state.getRegisterType() === 'panel') {
        state.setRegisterType('panel')
        this.registerMode = 'panel'
      } else if (state.getRegisterType() === 'ai-caffe-wide') {
        state.setRegisterType('ai-caffe-wide')
        this.registerMode = 'ai-caffe-wide'
      } else if (state.getRegisterType() === 'ai-crud') {
        state.setRegisterType('ai-crud')
        this.registerMode = 'ai-crud'
      }
      this.saveRegisterType()
      EventBus.$emit('change-register-template')
    },
    async saveRegisterType () {
      const me = this
      df.doc(`cash_registers/${state.getCashRegister().id}`).update({ register_template: this.registerMode })
        .then(() => {
          // console.log(`Register template changed to ${this.registerMode}`)
        })
        .catch(() => {
          me.hideLoader()
          me.showMsgBox({ text: 'Pogreška prilikom promjene templatea blagajne', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    },
    async setRegisterTemplate () {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (register && register.data()) {
        const doc = register.data()
        if (doc && doc.register_template) {
          state.setRegisterType(doc.register_template)
        } else {
          state.setRegisterType('cash')
        }
      } else {
        state.setRegisterType('cash')
      }
      return state.getRegisterType()
    },
    async logoutCashRegister () {
      this.showLoader()

      if (state.getCurrentCompany().save_log) {
        this.prepareLogCall()
      }
      // const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()

      // const cashRegUser = state.getCashRegisterUser()
      // const cashRegUserName = cashRegUser.name + ' ' + cashRegUser.surname
      // if (register.reserved_by !== '' && register.reserved_by === cashRegUserName) {
      state.setCashRegisterUser({ id: undefined })
      df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
        .then(() => {
          console.log('success')
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
              companyId: state.getCurrentCompany().id,
              locationId: state.getPointOfSale().id
            }
          })
        })
        .catch(() => {
          this.hideLoader()
          this.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
              companyId: state.getCurrentCompany().id,
              locationId: state.getPointOfSale().id
            }
          })
        })
        .finally(() => {
          this.hideLoader()
        })

      // }
    },
    prepareLogCall () {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Odjava'
      req.action_id = '2'
      req.user_id = userId

      this.setLog(req)
    },
    routeToReceipts () {
      this.$router.push({
        name: 'pointOfSale.receipts',
        params: {
        }
      })
    },
    changeView () {
      state.setListView(!state.isListView())
    },
    createReport () {
      if (!state.events) return
      state.events.createReport = true
    },
    closeBalance () {
      if (!state.events) return
      state.events.closeBalance = true
    },
    searchItems (reset = false) {
      if (reset) {
        this.search = ''
      } else {
        if (this.topSearch) {
          this.search = this.topSearch
        } else {
          this.search = ''
        }
      }
    },
    filterItems (reset = false) {
      if (reset) {
        this.filter = ''
      } else {
        if (this.topSearch) {
          this.filter = this.topSearch
        } else {
          this.filter = ''
          this.search = '' // reset search on input event if there is no text
        }
      }
    }
  }
}
</script>
