<template>
  <v-card-text class="ma-0 pa-0">
    <v-list>
      <v-list-item class="pl-0" @click="setStep('main-account-window')">
        <v-icon large>mdi-arrow-left</v-icon>
        <span class="ml-2">{{ $t("$vuetify.appLanguageTxt") }}</span>
      </v-list-item>
      <v-divider></v-divider>
      <div class="pl-14">
        <v-radio-group v-model="language">
          <v-radio
            v-for="language in languages"
            :key="language.value"
            :value="language.value"
            :label="$t(language.name)"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-list>
  </v-card-text>
</template>
<script>
import applicationSettings from '@/mixins/applicationSettings'
import state from '@/state'
export default {
  mixins: [applicationSettings],
  data: function () {
    return {
      language: undefined
    }
  },
  computed: {
    languages () {
      return [
        { name: '$vuetify.languages.hr', value: 'hr' }
        // { name: '$vuetify.languages.en', value: 'en' }
      ]
    }
  },
  watch: {
    language (nv, ov) {
      if (nv !== ov) {
        this.$i18n.locale = nv
        state.setLocale(nv)
      }
    }
  },
  mounted () {
    this.language = this.$i18n.locale
  },
  methods: {
    setStep (step) {
      this.$emit('stepChange', step)
    }
  }
}
</script>
