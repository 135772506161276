import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import { dateTimeToUnixTimestamp } from '@/libs/receiptIssuing/commons'
import { v4 as uuidv4 } from 'uuid'

export const validateParamsForReceipt = (params) => {
  var response = {
    valid: true
  }

  if (params && params.items && params.items.length > 0) {
    var totalByItems = 0

    params.items.forEach(item => {
      response = checkAmount(item)
      if (response.valid === false) {
        logError(params, response)
        return response
      }

      totalByItems += item.price
    })

    if (!params.discount || (params.discount && params.discount.name && params.discount.name !== 'Reprezentacija')) {
      if (params.discount && params.discount.amount && params.discount.amount > 0) {
        totalByItems -= params.discount.amount
      }

      response = checkPrices(totalByItems, params.total)
      if (response.valid === false) {
        logError(params, response)
        return response
      }
    }
  }

  return response
}

export const checkAmount = (item) => {
  var response = {
    valid: true
  }

  if (item.amt === 0 || item.amt < 0) {
    response = { valid: false, message: 'Artikl ' + item.name + ' ima krivu količinu. Molimo ispravite količinu artikla ili uklonite artikl i dodajte ga ponovno.' }
  }

  if (isNaN(item.amt)) {
    response = { valid: false, message: 'Artikl ' + item.name + ' ima krivu količinu. Molimo ispravite količinu artikla ili uklonite artikl i dodajte ga ponovno.' }
  }

  return response
}

export const checkPrices = (totalByItems, total) => {
  var response = {
    valid: true
  }

  if (totalByItems !== total) {
    response = { valid: false, message: 'Zbroj cijene artikala i ukupna cijena artikala se ne podudaraju. ' }
  }

  return response
}

export const logError = (params, response) => {
  var userID = auth.currentUser.uid
  if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
    userID = state.getCashRegisterUser().id
  }

  df.doc(`error_logs/${uuidv4()}`)
    .set({
      user_id: userID,
      error: response.message,
      created: `${dateTimeToUnixTimestamp(new Date())}`,
      payload: params
    })

  return response
}

export const retry = async (fn, maxAttempts) => {
  const execute = async (attempt) => {
    try {
      return await fn()
    } catch (err) {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1
        // const delayInSeconds = Math.max(Math.min(Math.pow(2, nextAttempt) +
        //   randInt(-nextAttempt, nextAttempt), 600), 1)
        const delayInSeconds = 2
        console.log(`Retrying after ${delayInSeconds} seconds due to:`, err)
        return delay(() => execute(nextAttempt), delayInSeconds * 1000)
      } else {
        throw err
      }
    }
  }
  return execute(1)
}

const delay = (fn, ms) => new Promise((resolve) => setTimeout(() => resolve(fn()), ms))

// const randInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
