<template>
  <div style="width: 100%">
    <v-row class="text-h4 primary--text align-center">
      <v-col
        md="5"
        class="hidden-md-and-down one-liner-text mb-0 pb-3 flex-lg-grow-1"
        style="min-width: 100px; max-width: 100%"
        >{{ title }}
        <v-btn
          md="5"
          class="white--text rounded-card"
          color="fiscButton"
          @click="routeToReceipts"
          id="routeReceiptsBtn"
          v-if="
            unfiskReceipts.length !== 0 && !$route.name.includes('receipts')
          "
          >Broj nefiskaliziranih računa: {{ unfiskReceipts.length }}
        </v-btn>
      </v-col>
      <v-col
        md="2"
        style="min-width: 100px; max-width: 100%"
        class="justify-center text-h5 font-italic text--secondary d-none d-md-flex pb-1"
      >
        {{ $t($route.meta.title) }}
      </v-col>
      <v-col
        cols="10"
        sm="11"
        md="8"
        lg="5"
        xl="4"
        style="min-width: 100px; max-width: 100%"
        class="d-flex align-center justify-end flex-sm-grow-1"
      >
        <!-- <v-text-field
          @click="showKbd"
          data-layout="normal"
          append-icon="mdi-magnify"
          clearable
          class="pr-5 pt-5"
          :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '50%' : '100%' }"
          v-model="topSearch"
          v-if="$route.meta.removeSearch ? false : true"
          @input="filterItems()"
          @click:clear="
            filterItems({ reset: true });
            searchItems({ reset: true });
          "
          @click:append="searchItems()"
          :placeholder="
            $route.meta.filterSearch
              ? $t('$vuetify.filterPlaceholderLabel')
              : $t('$vuetify.searchLabel')
          "
          @keydown.enter="searchItems()"
        >
        </v-text-field> -->
        <v-btn
          plain
          outlined
          color="primary"
          @click="fireAddEvent"
          v-if="!$route.meta.noAddButton"
          class="hidden-sm-and-down"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t($route.meta.addItemText) }}
        </v-btn>
        <!-- <v-tooltip bottom>
          <template
            v-if="$route.meta.removeGrid ? false : true"
            v-slot:activator="{ on, attrs }"
          >
            <v-btn v-on="on" v-bind="attrs" plain text @click="changeView">
              <v-icon v-if="listView">mdi-view-grid</v-icon>
              <v-icon v-else>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>{{ listView ? "Prikaži kao grid" : "Prikaži kao listu" }}</span>
        </v-tooltip> -->
      </v-col>
    </v-row>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </div>
</template>
<script>
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
import { df } from '@/plugins/firebase'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'

export default {
  name: 'PointOfSaleTopBar',
  mixins: [applicationSettings, onScreenKeyboard],
  data: function () {
    return {
      topSearch: '',
      unfiskReceipts: [],
      listeners: []
    }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  mounted () {
    if (state.getCurrentCompany().id !== '') {
      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .orderBy('issue_date_time', 'desc')

      const now = parseInt(
        (new Date().getTime() / 1000).toFixed(0)
      )

      const tmp = new Date()
      tmp.setDate(tmp.getDate() - 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )
      query = query.where('fiscalize', '==', true).where('jir', '==', '').where('issue_date_time', '>=', before).where('issue_date_time', '<=', now)

      const listener = query
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            this.unfiskReceipts.push(rec.data())
          })

          if (doc.docs.length === 0) {
            this.unfiskReceipts = []
          }
        }
        )
      this.listeners.push(listener)
    }
  },
  computed: {
    title () {
      return state.getAppTitle()
    },
    listView () {
      return state.isListView()
    }
  },
  watch: {
    filter (val) {
      if (val !== this.topSearch) {
        this.topSearch = val
      }
    },
    search (val) {
      if (val !== this.topSearch) {
        this.topSearch = val
      }
    }
  },
  methods: {
    routeToReceipts () {
      this.$router.push({
        name: 'pointOfSale.receipts',
        params: {
        }
      })
    },
    changeView () {
      state.setListView(!state.isListView())
    },
    fireAddEvent () {
      if (state.events && Object.prototype.hasOwnProperty.call(state.events, this.$route.meta.addItemAction)) {
        state.events[this.$route.meta.addItemAction] = true
      }
    },
    searchItems (reset = false) {
      if (reset) {
        this.search = ''
      } else {
        if (this.topSearch) {
          this.search = this.topSearch
        } else {
          this.search = ''
        }
      }
    },
    filterItems (reset = false) {
      if (reset) {
        this.filter = ''
      } else {
        if (this.topSearch) {
          this.filter = this.topSearch
        } else {
          this.filter = ''
          this.search = '' // reset search on input event if there is no text
        }
      }
    }
  }
}
</script>
