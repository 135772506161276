var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.companies || _vm.companies.length === 0)?_c('v-container',{staticClass:"d-flex fill-height justify-center",staticStyle:{"height":"800px"}},[_c('div',[_vm._v(" Još nemate niti jednu dodanu firmu. "),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.addCompany.apply(null, arguments)}}},[_vm._v("Dodaj firmu")])])]):_c('v-container',{staticClass:"d-flex justify-center align-center flex-wrap",staticStyle:{"position":"relative"},attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('Search-box',{attrs:{"emitDestination":_vm.searchEmitDestination,"searchPlaceholder":"Pretraži firme"}})],1),_c('v-row',{staticClass:"justify-center fill-height ma-0 pa-0",staticStyle:{"position":"absolute"}},_vm._l((_vm.sortedCompanies),function(company,i){return _c('v-card',{key:i,ref:("company" + i),refInFor:true,staticClass:"align-center ma-5",attrs:{"autofocus":"","hover":"","height":"300","width":"250"},on:{"click":function($event){return _vm.openCompany(company)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.openCompany(company)}}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"lazy-src":require('@/assets/unknown_profile.jpg'),"src":company.logo
              ? company.logo
              : require('@/assets/unknown_profile.jpg'),"contain":"","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"245px"}},[_c('v-card-title',{staticClass:"one-liner-text"},[_vm._v(" "+_vm._s(company.name)+" ")]),_c('v-card-subtitle',{staticClass:"white--text one-liner-text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" Status: "+_vm._s(_vm.translateStatus(company.status))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getStatusTooltip(company.status))+" ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.addCertificate(company)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.addCertificate(company)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":company.certificate ? 'success' : 'error'}},[_vm._v("mdi-certificate")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(company.certificate ? "Uspješno dodan certifikat za fiskalizaciju" : "Certifikat za fiskalizaciju nije dodan"))])]),(!_vm.hideMenu)?_c('v-menu',{attrs:{"offset-y":"","nudge-left":"85"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.companyOptions),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){("" + (item.action(company)))},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();("" + (item.action(company)))}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],1)],1)}),1)],1),_c('edit-company',{ref:"editCompany",on:{"editSuccess":_vm.refreshEditCompany}}),_c('edit-receipt-settings',{ref:"editReceiptSettings",on:{"editSuccess":_vm.refreshEditCompany}}),_c('certificate-upload',{ref:"certificateUpload"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }