var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$route.name !== 'cashRegister' &&
      !_vm.$route.params.redirectedThruIssue &&
      !_vm.$route.name.includes('recapitulation') &&
      !_vm.$route.name.includes('orders') &&
      !_vm.$route.name.includes('companies') &&
      !_vm.$route.name.includes('suppliers') &&
      !_vm.$route.name.includes('documentsv2')
    )?_c('v-speed-dial',{attrs:{"top":_vm.top,"bottom":_vm.bottom,"right":_vm.right,"left":_vm.left,"fixed":"","direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"#7BD500","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-menu ")])],1)]},proxy:true}],null,false,2925570079),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{staticStyle:{"z-index":"99","margin-right":"35px","margin-top":"5px !important"},attrs:{"dark":"","medium":"","color":"green"},on:{"click":_vm.openCrudDialog}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_c('b',[_vm._v("Račun")])])],1),_c('v-btn',{staticStyle:{"z-index":"99","margin-right":"63px"},attrs:{"dark":"","medium":"","color":"#2697c5"},on:{"click":_vm.openMaster}},[_c('v-icon',[_vm._v("mdi-menu")]),_c('span',[_c('b',[_vm._v("Izvještaji")])])],1),(_vm.showNewWarehouseDocumentBtn)?_c('v-btn',{staticStyle:{"z-index":"99","margin-right":"40px"},attrs:{"dark":"","medium":"","color":"orange"},on:{"click":_vm.openWarehouseNewDocument}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_c('b',[_vm._v("Primka")])])],1):_vm._e()],1):_vm._e(),_c('crud-template-dialog',{ref:"crudTemplateDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }