<template>
  <v-dialog
    v-model="visible"
    persistent
    width="900"
    style="overflow-y: auto; border-radius: 20px !important"
    :scrollable="true"
    content-class="my-custom-dialog"
  >
    <v-card style="border-radius: 20px !important">
      <v-card-title
        class="d-flex"
        style="
          vertical-align: middle;
          background-color: #1577da;
          height: 58px;
          color: white;
        "
      >
        <span class="text-h5" style="font-weight: bold">Predložak računa</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-radio-group v-model="templateSelected">
            <div class="field is-grouped is-grouped-multiline">
              <div ref="el" v-viewer="options" class="images clearfix">
                <v-row>
                  <v-col
                    cols="4"
                    :key="'c' + image.id"
                    v-for="image in receiptTemplates"
                  >
                    <span style="display: table">
                      <div
                        style="
                          vertical-align: middle;
                          background-color: #1577da;
                          height: 35px;
                          color: white;
                          font-weight: bold;
                          display: grid;
                          flex-direction: grid;
                          align-items: center;
                          text-align: center;
                        "
                      >
                        {{ image.name }}
                      </div>
                      <img
                        style="
                          height: 250px !important;
                          width: 220px !important;
                          border: 3px solid #1577da;
                        "
                        :key="image.id"
                        class="image"
                        :src="image.picture"
                        :data-source="image.picture"
                        :alt="image.name"
                      />
                      <v-radio
                        style="
                          justify-content: center;
                          margin-right: 0px !important;
                        "
                        :key="'r' + image.id"
                        :value="image.id"
                      ></v-radio>
                    </span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-radio-group>
        </v-container>
      </v-card-text>
      <v-card-actions class="pr-10 pl-10">
        <v-row dense style="margin-top: 1rem; margin-bottom: 1rem">
          <v-spacer></v-spacer>

          <v-btn
            class="btn dangerButton"
            style="margin-right: 1rem"
            @click="close"
          >
            Odustani
          </v-btn>
          <v-btn
            id="success"
            class="btn successButton"
            type="submit"
            @click="submit"
          >
            Spremi
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <viewer
      ref="viewer"
      :hide-toolbar="true"
      v-if="showOverlay"
      :images="images"
    >
      <img v-for="src in images" :key="src" :src="src" />
    </viewer>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import rules from '@/plugins/rules'
export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],
  data: () => ({
    visible: false,
    receiptTemplates: [],
    rules: {
      req: rules.req()
    },
    overlayImg: '',
    showOverlay: false,
    listeners: [],
    templateSelected: '',
    images: [],
    options: {
      toolbar: true,
      url: 'data-source',
      navbar: false,
      keyboard: false
    }

  }),
  mounted () {
    this.getReceiptTemplates()
  },
  methods: {
    openOverlay (picture) {
      this.overlayImg = picture
      this.showOverlay = true

      this.images = []
      this.images.push(picture)

      this.$refs.viewer.view()
    },
    open (company) {
      this.templateSelected = company.print_template
      this.visible = true
      this.resetValues()
    },
    close () {
      this.visible = false
    },
    submit () {
      this.$emit('templateSelected', this.templateSelected)
      this.resetValues()

      this.close()
    },
    resetValues () {

    },
    getReceiptTemplates () {
      const listener = df.doc('project_constants/receipt_templates').onSnapshot(doc => {
        this.receiptTemplates = doc.data().templates
      })
      this.listeners.push(listener)
    },

    transformirajNiz (objekti, velicinaPodniza) {
      const duljina = objekti.length
      const brojArraya = Math.ceil(duljina / velicinaPodniza)

      const rezultat = []

      for (let i = 0; i < brojArraya; i++) {
        // Izračunajte indekse objekata koji će biti dodani u trenutni array
        const pocetniIndeks = i * velicinaPodniza
        let krajnjiIndeks = (i + 1) * velicinaPodniza - 1
        if (krajnjiIndeks >= duljina) {
          krajnjiIndeks = duljina - 1
        }

        // Inicijalizacija trenutnog arraya
        const trenutniArray = []

        // Dodavanje objekata u trenutni array
        for (let j = pocetniIndeks; j <= krajnjiIndeks; j++) {
          trenutniArray.push(objekti[j])
        }

        // Dodavanje trenutnog arraya u rezultat
        rezultat.push(trenutniArray)
      }

      return rezultat
    }
  }
}
</script>
<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px !important;
}

.image {
  width: calc(20% - 10px);
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
</style>

<style lang="scss" rel="stylesheet/scss" scoped>
.image {
  width: calc(20% - 10px);
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
</style>
