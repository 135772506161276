class WriteInProductModel {
  constructor () {
    this.active = true
    this.barcode = ''
    this.categories = []
    this.category_ids = []
    this.code = ''
    this.created = undefined
    this.description = ''
    this.id = ''
    this.manual_price_input = false
    this.name = ''
    this.picture = ''
    this.prices = {}
    this.quantity = 0
    this.selling_unit = ''
    this.sold_count = 0
    this.status = 'OK'
    this.stock = 0
    this.storage_unit = ''
    this.suppliers = {}
    this.type = 'SERVICE'
  }
}

export default WriteInProductModel
