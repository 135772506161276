var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"text-h4 primary--text align-center",staticStyle:{"overflow":"hidden"}},[_c('v-col',{staticClass:"hidden-md-and-down one-liner-text mb-0 pb-3 flex-grow-1",staticStyle:{"min-width":"100px","max-width":"100%"},attrs:{"md":"4","lg":"5"}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.unfiskReceipts.length !== 0 && !_vm.$route.name.includes('receipts'))?_c('v-btn',{staticClass:"white--text rounded-card",attrs:{"color":"fiscButton","id":"routeReceiptsBtn"},on:{"click":_vm.routeToReceipts}},[_vm._v("Broj nefiskaliziranih računa: "+_vm._s(_vm.unfiskReceipts.length)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"10","sm":"11","md":"7","lg":"6","xl":"7"}},[(
          _vm.$route.meta.removeSearch ||
          _vm.registerMode === 'panel' ||
          _vm.registerMode === 'ai-crud'
            ? false
            : true
        )?_c('v-text-field',{staticClass:"pr-5 pt-5",style:({ 'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '50%' : '100%' }),attrs:{"data-layout":"normal","append-icon":"mdi-magnify","clearable":"","placeholder":_vm.$route.meta.filterSearch
            ? _vm.$t('$vuetify.filterPlaceholderLabel')
            : _vm.$t('$vuetify.searchLabel')},on:{"click":_vm.showKbd,"input":function($event){return _vm.filterItems()},"click:clear":function($event){_vm.filterItems({ reset: true });
          _vm.searchItems({ reset: true });},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchItems()}},model:{value:(_vm.topSearch),callback:function ($$v) {_vm.topSearch=$$v},expression:"topSearch"}}):_vm._e(),(_vm.showCashRegLogout)?_c('v-btn',{staticClass:"font-weight-bold",style:({
          'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '50%' : '100%',
          'font-size': '10px',
        }),attrs:{"plain":"","outlined":"","color":"primary"},on:{"click":_vm.logoutCashRegister}},[_vm._v(" "+_vm._s("Odjavi se s blagajne")+" ")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","plain":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("$vuetify.cashRegister.optionsText"))+" "),(_vm.menu)?_c('v-icon',[_vm._v("mdi-menu-up")]):_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.cashRegisterOptions),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){("" + (item.action()))},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();("" + (item.action()))}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),(_vm.templatesDialogOpen)?_c('change-template-dialog',{on:{"selectTempate":_vm.changeCashRegisterTemplate,"closeTemplatesDialog":_vm.onCloseTemplatesDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }