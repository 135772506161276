<template>
  <v-dialog
    v-model="visible"
    v-if="visible"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    scrollable
    persistent
    width="450"
  >
    <v-form ref="addIbanForm" @submit.prevent="submit">
      <v-card
        tile
        color="white"
        class="pb-3 overflow-auto"
        style="border-radius: 25px !important"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold">Dodaj iban</span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col class="mt-0 pb-3 mb-0">
              <v-text-field
                class="textField"
                autofocus
                hide-details
                dense
                outlined
                label="IBAN"
                v-model="iban"
                :rules="[rules.req]"
                :color="ibanTextFieldColor"
              >
                <!-- Could not find soulution with custom tokens and v-mask as directive at this time .. problem... expecting v-mask to be an array TODO: use custom token instead of keypress-->
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-3 mt-0 pb-0 mb-0">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <v-combobox
                :disabled="allDisabled"
                class="textField"
                dense
                outlined
                hide-details
                label="Swift kod"
                v-model="swift"
                :items="banks"
                item-text="swift"
                item-value="swift"
                :rules="[rules.req]"
                @change="findBankData()"
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row class="pt-3 mt-0 pb-0 mb-0">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <v-text-field
                dense
                :disabled="allDisabled"
                class="textField"
                hide-details
                outlined
                autofocus
                label="Naziv banke"
                v-model="bank.name"
                :rules="[rules.req]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-3 mt-0 pb-0 mb-0">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <v-text-field
                :disabled="allDisabled"
                dense
                class="textField"
                outlined
                hide-details
                label="Adresa banke"
                v-model="bank.street"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row class="pt-0">
                <v-col class="mt-0 pb-0 mb-0">
                  <v-text-field
                    :disabled="allDisabled"
                    dense
                    hide-details
                    class="textField"
                    outlined
                    label="Grad"
                    v-model="bank.city"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-col class="mt-0 pb-0 mb-0">
                  <v-select
                    :disabled="allDisabled"
                    outlined
                    dense
                    class="textField"
                    hide-details
                    :items="countries"
                    label="Država"
                    v-model="bank.country"
                    item-value="country"
                    item-text="country"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="pt-0">
                <v-col class="mt-0 pb-0 mb-0">
                  <v-text-field
                    dense
                    :disabled="allDisabled"
                    outlined
                    class="textField"
                    hide-details
                    label="Poštanski broj"
                    v-model="bank.zip_code"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-col class="mt-0 pb-0 mb-0">
                  <v-select
                    :disabled="allDisabled"
                    class="textField"
                    outlined
                    dense
                    :items="[
                      { val: true, name: $t('$vuetify.yes') },
                      { val: false, name: $t('$vuetify.no') },
                    ]"
                    v-model="defaultIban"
                    :label="$t('$vuetify.companies.defaultIban')"
                    item-text="name"
                    item-value="val"
                    hide-details
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex pt-3 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
          <v-btn
            id="success"
            class="btn successButton"
            type="submit"
            :disabled="isAddIbanButtonDisabled"
          >
            Dodaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import countries from '@/mixins/countries'

export default {
  props: ['banks'],
  mixins: [countries],
  inject: ['showMsgBox'],
  data: function () {
    return {
      visible: false,
      iban: undefined,
      swift: undefined,
      defaultIban: true,
      rules: {
        req: rules.req(),
        eq: rules.eq,
        oib: rules.oib()
      },
      swiftCodes: [],
      bank: {},
      bankPrefix: '',
      initialIban: undefined,
      ibanTextFieldColor: undefined,
      ibanSubmitButtonColor: '#2597c5',
      submitIbanButtonClass: 'white--text okButton',
      isAddIbanButtonDisabled: undefined,
      allDisabled: true,
      initialData: {}
    }
  },
  watch: {
    iban (nv, ov) {
      if (nv && nv.length > 5) {
        this.allDisabled = false
      } else {
        this.allDisabled = true
      }
      if (nv === ov) return
      this.setIbanValues(nv)
    }
  },

  methods: {
    findBankData () {
      if (typeof (this.swift) === 'object') {
        this.swift = this.swift?.swift ? this.swift?.swift : ''
      }
      if (this.swift && this.swift !== '') {
        var temp = this.banks ? this.banks.find(bank => bank.swift === this.swift) : undefined
        if (temp) {
          this.bank = temp
        }
      }
    },
    setIbanValues (nv) {
      const ibantools = require('ibantools')

      ibantools.isValidIBAN(nv) ? this.ibanTextFieldColor = 'green' : this.ibanTextFieldColor = 'red'
      ibantools.isValidIBAN(nv) ? this.submitIbanButtonClass = 'white--text' : this.submitIbanButtonClass = 'white--text okButton'
      ibantools.isValidIBAN(nv) ? this.isAddIbanButtonDisabled = false : this.isAddIbanButtonDisabled = true

      if (!nv || nv.length < 11) return
      if (!ibantools.isValidIBAN(nv)) return
      const bankPrefix = nv.substr(4, 7)
      if (this.bankPrefix === bankPrefix) return
      var temp = this.banks ? this.banks.find(bank => bank.leading_bank_num === bankPrefix) : undefined
      if (temp) {
        this.bank = temp
      }
      if (this.bank?.swift) this.swift = clone(this.bank.swift)
    },
    open (existing = null) {
      this.resetValues()
      if (existing) {
        this.initialData = clone(existing)
        this.iban = existing.iban
        this.initialIban = clone(existing.iban)
        this.defaultIban = existing.default

        if (this.iban?.length >= 11) {
          this.bankPrefix = existing.iban.substr(4, 7)
        }

        if (existing.bank) {
          this.bank = existing.bank
        }
        if (existing && existing.swift) this.swift = clone(existing.swift)
      }
      this.visible = true
    },
    checkIfObjectsAreSame (initialData, newData) {
      if (initialData.swift !== newData.swift) {
        return false
      }

      if (initialData.bank.name !== newData.bank.name) {
        return false
      }

      if (initialData.bank.address !== newData.bank.address) {
        return false
      }

      if (initialData.bank.zip_code !== newData.bank.zip_code) {
        return false
      }

      if (initialData.bank.country !== newData.bank.country) {
        return false
      }

      if (initialData.default !== newData.default) {
        return false
      }

      if (initialData.iban !== newData.iban) {
        return false
      }

      if (initialData.initialIban !== newData.initialIban) {
        return false
      }

      return true
    },
    close () {
      this.visible = false
    },
    submit () {
      if (!this.$refs.addIbanForm.validate()) return

      if (typeof (this.swift) === 'object') {
        this.swift = this.swift?.swift ? this.swift?.swift : ''
      }

      var newData = { swift: this.swift, bank: this.bank, default: this.defaultIban, iban: this.iban, initialIban: this.initialIban }

      var same = this.checkIfObjectsAreSame(this.initialData, newData)

      if (same) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      this.$emit('add', newData)
      this.visible = false
    },
    resetValues () {
      this.iban = undefined
      this.swift = undefined
      this.initialIban = undefined
      this.bank = {}
      this.swift = undefined
    }
  }
}
</script>
<style scoped>
.textField >>> .v-input__slot {
  border-radius: 12px !important;
}
</style>
