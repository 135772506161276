<template>
  <div>
    <top-bar />
    <side-nav />
    <div style="position: relative">
      <v-main style="padding-top: 64px">
        <router-view />
        <!-- TODO zakomentirano za bolje dane za sloziti -->
        <!-- <vue-draggable-resizable
          :w="100"
          :h="100"
          @dragging="onDrag"
          @resizing="onResize"
          absolute
          :parent="true"
          style="z-index: 100"
        >
          <v-btn
            style="z-index: 100"
            rounded
            fixed
            bottom
            right
            color="primary"
            @click="navigateToFlow"
            v-if="$route.name !== 'cashRegister' && $route.name !== 'companies'"
          >
            <v-icon>mdi-plus</v-icon>
            <span>{{ $t(`$vuetify.pointOfSale.issueReceipt`) }}</span>
          </v-btn>
        </vue-draggable-resizable> -->
        <!-- <v-btn
          style="z-index: 4"
          class="white--text okButton rounded-card"
          rounded
          fixed
          bottom
          right
          color="loginButton"
          @click="navigateToFlow"
          v-if="
            $route.name !== 'cashRegister' &&
            !$route.params.redirectedThruIssue &&
            !$route.name.includes('warehouseModule') &&
            !$route.name.includes('recapitulation') &&
            !$route.name.includes('orders') &&
            !$route.name.includes('suppliers')
          "
        >
          <v-icon>mdi-plus</v-icon>
          <span>{{ $t(`$vuetify.formDetails.issueReceipt`) }}</span>
        </v-btn> -->

        <fab-menu v-if="!$vuetify.breakpoint.smAndDown"></fab-menu>
        <!-- <v-btn
          style="z-index: 4"
          class="white--text okButton rounded-card"
          rounded
          fixed
          bottom
          right
          color="loginButton"
          @click="navigateToFlow"
          v-if="
            $route.name !== 'cashRegister' &&
            !$route.params.redirectedThruIssue &&
            !$route.name.includes('warehouseModule') &&
            !$route.name.includes('recapitulation') &&
            !$route.name.includes('orders') &&
            !$route.name.includes('suppliers')
          "
        >
          <v-icon>mdi-plus</v-icon>
          <span>{{ $t(`$vuetify.formDetails.issueReceipt`) }}</span>
        </v-btn> -->
      </v-main>
    </div>
  </div>
</template>
<script>
import SideNav from '@/components/SideNav'
import TopBar from '@/components/top-bar/TopBar'
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
import FabMenu from '@/modules/company/components/FabMenu'

// import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  components: { SideNav, TopBar, FabMenu },
  mixins: [applicationSettings],
  inject: ['confirm', 'confirmClose', 'showMsgBox', 'showLoader', 'hideLoader'],
  data () {
    return {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      cashRegisters: []
    }
  },
  mounted () {
    auth.currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        state.r_time = idTokenResult?.claims?.r_time
      })
    const docRef = df.doc(`user_sessions/${auth.currentUser.uid}`)
    this.listeners.push(
      docRef.onSnapshot(doc => {
        if (doc?.data()?.active_session) {
          if (state.r_time !== 0) {
            auth.currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                state.r_time = idTokenResult?.claims?.r_time
                if (doc?.data()?.active_session?.start !== idTokenResult?.claims?.r_time && !doc.metadata.fromCache) {
                  this.confirm({
                    message: this.$t('$vuetify.messages.multipleSignIn'),
                    options: {
                      cancelText: 'OK',
                      confirmVisible: false
                    }
                  }).finally(() => {
                    state.isAuthenticated = false
                    state.removeCurrentCompany()
                    state.removePointOfSale()
                    state.removeCashRegister()
                    state.removeAppTitle()
                    state.setListView(true)
                    this.$router.push({ name: 'Login' })
                  })

                  state.r_time = 0
                } else {
                  state.r_time = idTokenResult?.claims?.r_time
                }
              })
          }
        }
      })
    )
  },
  methods: {

    onResize: function (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
    },
    onDrag: function (x, y) {
      this.x = x
      this.y = y
    },
    callReservation (register, user) {
      const that = this

      this.showLoader()
      const reserving = user.name + ' ' + user.surname

      if (register.reserved_by !== undefined) {
        df.doc(`cash_registers/${register.id}`).update({ reserved_by: reserving })
          .then(() => {
          })
          .catch(() => {
            that.hideLoader()
            that.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
            that.$router.push({
              name: 'pointOfSale.cashRegisters',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id
              }
            })
          })
          .finally(() => {
            that.hideLoader()
            state.setCashRegister(register)
            that.$router.push({
              name: 'cashRegister',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id,
                registerId: state.getCashRegister().id,
                redirectedThruIssue: true
              }
            })
              .catch(() => {})
            this.submitting = false
          })
      }
    },
    async getUser () {
      const user = await df.doc(`users/${auth.currentUser.uid}`).get()
      return user
    },
    async getCashRegister () {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      return register
    },
    navigateToFlow () {
      this.getDirectToRegisterData()
        .then((resp) => {
          const that = this
          state.setCurrentCompany(resp.company)
          state.setAppTitle(resp.company.name)
          state.setPointOfSale(resp.pSale)
          state.setCashRegister(resp.register)

          this.getCashRegister()
            .then(cashRegResp => {
              const cashReg = cashRegResp.data()

              this.getUser()
                .then(userResp => {
                  const user = userResp.data()
                  let reservedRegister = false

                  if (cashReg.reserved_by !== undefined && cashReg.reserved_by !== '' && cashReg.reserved_by !== user.name + ' ' + user.surname) {
                    reservedRegister = true
                    this.confirm({
                      title: 'Rezervacija blagajne',
                      message: `Trenutno je na blagajnu prijavljen ${
                   cashReg.reserved_by
                  }.<br>Da li se želite odjaviti tog korisnika i prijaviti sebe?`,
                      options: {
                        toolbar: true,
                        width: 410,
                        confirmText: 'DA',
                        cancelText: this.$t('$vuetify.close')
                      }
                    }).then(async (resp) => {
                      if (resp) {
                        this.confirmClose()
                        this.callReservation(cashReg, user)
                      } else {
                        this.confirmClose()
                        that.$router.push({
                          name: 'pointOfSale.cashRegisters',
                          params: {
                            companyId: resp.company.id,
                            locationId: resp.pSale.id,
                            redirectedThruIssue: true
                          }
                        }).catch(() => {})
                        that.submitting = false
                      }
                    })
                  }

                  if (cashReg.reserved_by !== undefined && cashReg.reserved_by === '') {
                    reservedRegister = true
                    this.callReservation(cashReg, user)
                  }

                  if (!reservedRegister) {
                    this.$router
                      .push({
                        name: 'cashRegister',
                        params: {
                          companyId: resp.company.id,
                          locationId: resp.pSale.id,
                          registerId: resp.register.id,
                          redirectedThruIssue: true
                        }
                      })
                      .catch(() => {})
                    this.submitting = false
                  }
                })
            })
        })
        .catch((resp) => {
          let routeFound = false
          let routeName = ''
          let registerReserved = false

          if (state.getCurrentCompany().id !== undefined && state.getCurrentCompany().id !== '') {
            routeFound = true
            routeName = 'company'
            const params = {
              companyId: state.getCurrentCompany().id,
              redirectedThruIssue: true
            }

            if (state.getPointOfSale().id !== undefined && state.getPointOfSale().id !== '') {
              routeName = 'pointOfSale'
              params.locationId = state.getPointOfSale().id

              if (state.getCashRegister().id !== undefined && state.getCashRegister().id !== '') {
                routeName = 'pointOfSale.cashRegisters'

                this.getCashRegister()
                  .then(cashRegResp => {
                    const cashReg = cashRegResp.data()

                    this.getUser()
                      .then(userResp => {
                        const user = userResp.data()

                        if (cashReg.reserved_by !== undefined && cashReg.reserved_by !== '' && cashReg.reserved_by === user.name + ' ' + user.surname) {
                          registerReserved = true
                          this.$router.push({
                            name: 'cashRegister',
                            params: {
                              companyId: state.getCurrentCompany().id,
                              locationId: state.getPointOfSale().id,
                              registerId: state.getCashRegister().id,
                              redirectedThruIssue: true
                            }
                          })
                            .catch(() => {})
                          this.submitting = false
                        } else if (cashReg.reserved_by !== undefined && cashReg.reserved_by !== '' && cashReg.reserved_by !== user.name + ' ' + user.surname) {
                          routeName = 'cashRegister'
                          params.registerId = state.getCashRegister().id
                          routeFound = true
                          registerReserved = true

                          this.confirm({
                            title: 'Rezervacija blagajne',
                            message: `Trenutno je na blagajnu prijavljen ${
                         cashReg.reserved_by
                        }.<br>Da li se želite odjaviti tog korisnika i prijaviti sebe?`,
                            options: {
                              toolbar: true,
                              width: 410,
                              confirmText: 'DA',
                              cancelText: this.$t('$vuetify.close')
                            }
                          }).then(async (resp) => {
                            if (resp) {
                              this.confirmClose()
                              this.callReservation(cashReg, user)
                            } else {
                              this.confirmClose()
                              this.$router.push({
                                name: 'pointOfSale.cashRegisters',
                                params: {
                                  companyId: resp.company.id,
                                  locationId: resp.pSale.id,
                                  redirectedThruIssue: true
                                }
                              }).catch(() => {})
                              this.submitting = false
                            }
                          })
                        } else if (cashReg.reserved_by !== undefined && cashReg.reserved_by === '') {
                          registerReserved = true
                          this.callReservation(cashReg, user)
                        }
                      })
                  })
              }
            }

            if (!registerReserved) {
              this.$router
                .push({
                  name: routeName,
                  params: params
                })
                .catch(() => {})
              this.submitting = false
            }
          }

          if (!routeFound) {
            this.submitting = false
            switch (resp.status) {
              case 0: {
                this.$router.push({
                  path: '/companies',
                  params: {
                    redirectedThruIssue: true
                  }
                }).catch(() => {})
                break
              }

              case 1: {
                state.setCurrentCompany(resp.company)
                state.setAppTitle(resp.company.name)
                this.$router
                  .push({
                    name: 'company',
                    params: {
                      companyId: resp.company.id,
                      redirectedThruIssue: true
                    }
                  })
                  .catch(() => {})
                break
              }

              case 2: {
                state.setCurrentCompany(resp.company)
                state.setAppTitle(resp.company.name)
                state.setPointOfSale(resp.pSale)

                this.$router
                  .push({
                    name: 'pointOfSale',
                    params: {
                      companyId: resp.company.id,
                      locationId: resp.pSale.id,
                      redirectedThruIssue: true
                    }
                  })
                  .catch(() => {})
                break
              }

              default: {
                this.$router.push({
                  path: '/companies',
                  params: {
                    redirectedThruIssue: true
                  }
                }).catch(() => {})
              }
            }
          }
        })
    },
    async getDirectToRegisterData () {
      const companies = await df
        .doc(`user_companies/${auth.currentUser.uid}`)
        .get()

      if (
        !companies ||
        !companies.data() ||
        !companies.data().companies
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ status: 0 })
      }

      const allCompanies = Object.keys(companies.data().companies).map((key) => {
        return companies.data().companies[key]
      })

      if (
        allCompanies.filter(
          (company) =>
            company.status === 'OK' && company.resource_status === 'ACTIVE'
        ).length !== 1
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ status: 0 })
      }

      const company = allCompanies.find(
        (company) =>
          company.status === 'OK' && company.resource_status === 'ACTIVE'
      )

      const pointsOfSale = await df
        .doc(`user_locations/${auth.currentUser.uid}.${company.id}`)
        .get()

      if (
        !pointsOfSale ||
        !pointsOfSale.data() ||
        !pointsOfSale.data().locations
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: 1,
          company: company
        })
      }
      const locations = Object.keys(pointsOfSale.data().locations).map(
        (key) => {
          return pointsOfSale.data().locations[key]
        }
      )

      if (
        locations.filter(
          (location) =>
            location.status === 'OK' && location.resource_status === 'ACTIVE'
        ).length !== 1
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: 1,
          company: company
        })
      }

      const pointOfSale = locations.find(
        (location) =>
          location.status === 'OK' && location.resource_status === 'ACTIVE'
      )
      const registers = await df
        .doc(
          `user_cash_registers/${auth.currentUser.uid}.${company.id}.${pointOfSale.id}`
        )
        .get()

      this.cashRegisters = registers.data().cash_registers

      if (
        !registers ||
        !registers.data() ||
        !registers.data().cash_registers ||
        Object.keys(registers.data().cash_registers).length !== 1 ||
        Object.keys(registers.data().cash_registers).map((key) => {
          return registers.data().cash_registers[key]
        })[0].status !== 'OK'
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: 2,
          company: company,
          pSale: pointOfSale
        })
      }

      const register = Object.keys(registers.data().cash_registers).map(
        (key) => {
          return registers.data().cash_registers[key]
        }
      )[0]

      return {
        company: company,
        pSale: pointOfSale,
        register: register
      }
    }
  }
}
</script>
<style scoped>
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
