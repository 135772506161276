
import { checkPrinterStatus, printText, setCharacterset, setPosId } from '@/libs/bixolon/bxlpos'
// import state from '@/state'

export const printerInit = (issueID) => {
  checkPrinterStatus()
  setCharacterset(1250)
  setPosId(issueID)
}

export const calculateLineLength = (horizontal, vertical, lineLength) => {
  if (horizontal === 0 && vertical === 0) {
    return lineLength
  } else if (horizontal === 1 && vertical === 1) {
    return Math.round(lineLength / 2)
  } else if (horizontal === 2 && vertical === 2) {
    return Math.round(lineLength / 3)
  } else if (horizontal === 3 && vertical === 3) {
    return Math.round(lineLength / 4)
  }
}

export const align = (text, options) => {
  const lineLength = calculateLineLength(options.horizontal, options.vertical, options.printerConfiguration.line_length)
  const textLength = text.length
  let startPosition = 0
  switch (options.alignment) {
    case 0:
      break
    case 1:
      startPosition = Math.round(lineLength / 2) - Math.round(textLength / 2)
      break
    case 2:
      startPosition = lineLength - textLength
      break
  }
  if (startPosition < 0) startPosition = 0
  return `${' '.repeat(startPosition)}${text}`
}

export const printLine = (text, options) => {
  if (options.printerConfiguration.library === 'UNIVERSAL') {
    const bold = !!options.bold
    if (bold) {
      options.document.style([options.escpos.FontStyle.Bold])
    } else {
      options.document.style([options.escpos.FontStyle.Normal])
    }

    options.document.setCharacterCodeTable(options.printerConfiguration.char_table ?? 19)
    // options.document.font(options.escpos.FontFamily[options.printerConfiguration.font])
    // options.document.align(options.escpos.TextAlignment.LeftJustification)

    options.document.size(options.horizontal, options.vertical)
    options.document.text(align(text, options), options.printerConfiguration.code_page ?? 1250)
  } else {
    printText(
      text + '\n',
      options.horizontal,
      options.vertical,
      options.bold,
      options.invert,
      options.underline,
      options.font,
      options.alignment)
  }
  // console.info(text)
}

export const printItems = (parts, middleColOffset, middleColOffset2, options) => {
  const lineLength = options.printerConfiguration.line_length
  const segmentLength = Math.ceil(lineLength / parts.length)
  let text = ''
  const ostatakZnakova = lineLength - (parts[1].length + parts[2].length + parts[3].length + 15)

  let veci = false
  if (parts[0].length > ostatakZnakova) {
    veci = true
    if (options.printerConfiguration.library === 'UNIVERSAL') {
      printLine(parts[0], options)
    } else {
      printText(
        parts[0] + '\n',
        options.horizontal,
        options.vertical,
        options.bold,
        options.invert,
        options.underline,
        options.font,
        options.alignment)
    }
    // text += ' '.repeat(segmentLength + 5)
  } else {
    text += parts[0]
  }
  let repeat = 0
  if (veci === false) { repeat = ((segmentLength * 2 + 5) - parts[1].length - parts[0].length) < 0 ? (segmentLength * 2 + 5 - parts[1].length) : ((segmentLength * 2 + 5) - parts[1].length - parts[0].length) } else { repeat = (segmentLength * 2 + 5) - parts[1].length }
  text += ' '.repeat(repeat) + parts[1]
  if (parts.length > 3) {
    text += ' '.repeat(segmentLength - parts[2].length - middleColOffset2) + parts[2]
  } else {
    text += ' '.repeat(lineLength - text.length - parts[2].length) + parts[2]
  }
  if (parts.length > 3) {
    text += ' '.repeat((lineLength - text.length - parts[3].length) < 0 ? 0 : lineLength - text.length - parts[3].length) + parts[3]
  }

  if (options.printerConfiguration.library === 'UNIVERSAL') {
    printLine(text, options)
  } else {
    printText(
      text + '\n',
      options.horizontal,
      options.vertical,
      options.bold,
      options.invert,
      options.underline,
      options.font,
      options.alignment)
  }
  // console.info(text)
}

export const printMultiple = (parts, middleColOffset, middleColOffset2, options) => {
  const lineLength = options.printerConfiguration.line_length
  const segmentLength = Math.ceil(lineLength / parts.length)

  let text = ''
  let repeat = 0
  if ((segmentLength - parts[0].length) < 0) {
    if (options.printerConfiguration.library === 'UNIVERSAL') {
      printLine(parts[0], options)
    } else {
      printText(
        parts[0] + '\n',
        options.horizontal,
        options.vertical,
        options.bold,
        options.invert,
        options.underline,
        options.font,
        options.alignment)
    }
    text += ' '.repeat(segmentLength + 5)
  } else {
    repeat = segmentLength - parts[0].length + 5 < 0 ? 0 : segmentLength - parts[0].length + 5
    text += parts[0] + ' '.repeat(repeat)
  }
  repeat = segmentLength - parts[1].length - middleColOffset < 0 ? 0 : segmentLength - parts[1].length - middleColOffset
  text += ' '.repeat(repeat) + parts[1]
  if (parts.length > 3) {
    repeat = segmentLength - parts[2].length - middleColOffset2 < 0 ? 0 : segmentLength - parts[2].length - middleColOffset2
    text += ' '.repeat(repeat) + parts[2]
  } else {
    repeat = lineLength - text.length - parts[2].length < 0 ? 0 : lineLength - text.length - parts[2].length
    text += ' '.repeat(repeat) + parts[2]
  }
  if (parts.length > 3) {
    text += ' '.repeat((lineLength - text.length - parts[3].length) < 0 ? 0 : lineLength - text.length - parts[3].length) + parts[3]
  }

  if (options.printerConfiguration.library === 'UNIVERSAL') {
    printLine(text, options)
  } else {
    printText(
      text + '\n',
      options.horizontal,
      options.vertical,
      options.bold,
      options.invert,
      options.underline,
      options.font,
      options.alignment)
  }
  // console.info(text)
}

export const printMultipleShort = (parts, offset, options) => {
  // Offset is the number of characters by which the middle column will move to the left
  const lineLength = options.printerConfiguration.line_length
  const segmentLength = lineLength / parts.length

  const part1 = String(parts[0])
  let text = parts[0] + ' '.repeat(segmentLength - part1.length)
  if (parts[1]) {
    const part2 = String(parts[1])
    const spaceAround = segmentLength - part2.length
    text += ' '.repeat(spaceAround - offset) + part2
    if (parts[2]) {
      const part3 = String(parts[2])
      const spaceBefore = segmentLength - part3.length
      text += ' '.repeat(spaceBefore + offset) + part3
      if (parts[3]) {
        const part4 = String(parts[3])
        const currentLength = text.length
        const remainingPart = lineLength - currentLength - part4.length
        text += ' '.repeat(remainingPart) + part4
      }
    }
  }

  if (options.printerConfiguration.library === 'UNIVERSAL') {
    printLine(text, options)
  } else {
    printText(
      text + '\n',
      options.horizontal,
      options.vertical,
      options.bold,
      options.invert,
      options.underline,
      options.font,
      options.alignment)
  }
  // console.info(text)
}

export const printTotals = (label, amount, options) => {
  const lineLength = options.printerConfiguration.line_length
  let separation = lineLength - label.length - amount.length
  if (separation < 0) { separation = 0 }
  const text = label + ' '.repeat(separation) + amount

  if (options.printerConfiguration.library === 'UNIVERSAL') {
    printLine(text, options)
  } else {
    printText(
      text + '\n',
      options.horizontal,
      options.vertical,
      options.bold,
      options.invert,
      options.underline,
      options.font,
      options.alignment)
  }
  // console.info(text)
}

export const printSeparator = (options) => {
  const line = '-'.repeat(options.printerConfiguration.line_length)

  if (options.printerConfiguration.library === 'UNIVERSAL') {
    printLine(line, options)
  } else {
    printText(
      `${line}` + '\n',
      options.horizontal,
      options.vertical,
      options.bold,
      options.invert,
      options.underline,
      options.font,
      options.alignment)
  }
  // console.info(`${line}`)
}
