<template>
  <v-dialog
    v-model="visible"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    persistent
    width="350"
  >
    <v-form ref="addOibForm" @submit.prevent="submit">
      <v-card
        tile
        color="white"
        class="pb-3 overflow-auto"
        style="border-radius: 25px !important"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold">PDV Id</span>
        </v-card-title>
        <v-card-text class="pb-0 mb-0">
          <v-row class="pt-3">
            <v-col class="pb-0 mb-0">
              <v-text-field
                dense
                class="textField"
                autofocus
                outlined
                autocomplete="off"
                label="OIB"
                placeholder="OIB"
                id="associatedOib"
                v-model="associatedOib"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-2 mt-0 pb-0 mb-0">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <v-select
                dense
                class="textField"
                v-model="country"
                :items="countries"
                autocomplete="off"
                label="Država"
                outlined
                item-text="country"
                item-value="key"
                :rules="[rules.req]"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
          <v-btn id="success" class="btn successButton" type="submit">
            Dodaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

// import CountryFlag from 'vue-country-flag'
import rules from '@/plugins/rules'
export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  // components: { CountryFlag },
  data: () => ({
    visible: false,
    associatedOib: undefined,
    country: undefined,
    countries: [],
    rules: {
      req: rules.req(),
      money: rules.money()
    }
  }),
  methods: {
    open () {
      this.visible = true
      this.resetValues()
      let countries = {}

      const i18nIsoCountries = require('i18n-iso-countries')
      try {
        i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${this.$i18n.locale}.json`))
        countries = i18nIsoCountries.getNames(this.$i18n.locale, { select: 'official' })
      } catch {
        i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))
        countries = i18nIsoCountries.getNames('en', { select: 'official' })
      }
      this.countries = Object.keys(countries).map((key) => {
        return { key: key, country: countries[key] }
      })
    },
    close () {
      this.visible = false
    },
    submit () {
      if (!this.$refs.addOibForm.validate()) return
      this.$emit('add', { oib: this.associatedOib, countryCode: this.country })
      this.visible = false
    },
    resetValues () {
      this.associatedOib = undefined
      this.country = undefined
    }
  }
}
</script>
<style scoped>
.oibField >>> .v-input__append-inner {
  margin-top: 0px;
}
.selectField >>> .v-input__control > .v-input__slot::before {
  border-style: none !important;
}

.textField >>> .v-input__slot {
  border-radius: 12px !important;
}
</style>
