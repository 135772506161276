<template>
  <v-app-bar
    style="z-index: 11; left: 0px; border-left-style: hidden"
    outlined
    elevation="1"
    elevate-on-scroll
    clipped
    fixed
  >
    <v-app-bar-nav-icon @click.stop="setMini"></v-app-bar-nav-icon>
    <component :is="content"></component>

    <div v-if="!$vuetify.breakpoint.smAndDown" class="ml-2">
      <v-btn-toggle dense background-color="primary">
        <v-tooltip
          bottom
          :open-delay="tooltipDelay"
          :open-on-hover="true"
          :open-on-click="false"
          :open-on-focus="false"
        >
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              v-if="switchOnScreenKeyboard"
              @click="switchKeyboard(true)"
            >
              <v-icon>mdi-keyboard-outline</v-icon>
            </v-btn>
            <v-btn v-on="on" v-else @click="switchKeyboard(false)">
              <v-icon>mdi-keyboard-off-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("$vuetify.tooltips.enableOsk") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </div>
    <v-menu
      bottom
      :close-on-content-click="false"
      v-model="menu"
      offset-y
      nudge-bottom="10"
    >
      <template v-slot:activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" plain text>
              <v-avatar
                size="30"
                style="font-size: 25px; color: white"
                color="#5ad0cf"
                v-on="onMenu"
                >{{ avatar }}</v-avatar
              >
            </v-btn>
          </template>
          <span>Račun</span>
        </v-tooltip>
      </template>
      <account :menu="menu"></account>
    </v-menu>
    <div v-if="!$vuetify.breakpoint.smAndDown" style="padding: 5px 0px 0px 0px">
      <HelpButton :helpMode="getRoute()"></HelpButton>
    </div>
    <euro-calc-dialog ref="euroCalcDialog"></euro-calc-dialog>

    <notification
      ref="notification"
      :showNotif="showNotif"
      :notification="importantNotif"
    ></notification>
  </v-app-bar>
</template>
<script>
import CompaniesTopBar from '@/components/top-bar/components/CompaniesTopBar'
import EventBus from '@/plugins/event-bus'
import CompanyTopBar from '@/components/top-bar/components/CompanyTopBar'
import PointOfSaleTopBar from '@/components/top-bar/components/PointOfSaleTopBar'
import CashRegisterTopBar from '@/components/top-bar/components/CashRegisterTopBar'
import AbstractRegisterTopBar from '@/components/top-bar/components/AbstractRegisterTopBar'
import applicationSettings from '@/mixins/applicationSettings'
import Account from '@/components/top-bar/components/account/Account'
import EuroCalcDialog from '@/components/top-bar/components/EuroCalcDialog'
import Notification from '@/components/top-bar/components/Notification'
import state from '@/state'
import { auth, df } from '@/plugins/firebase'
import tables from '@/mixins/tables'

export default {
  components: { CompaniesTopBar, CompanyTopBar, PointOfSaleTopBar, CashRegisterTopBar, AbstractRegisterTopBar, Account, EuroCalcDialog, Notification },
  mixins: [applicationSettings, tables],
  inject: ['showMask', 'hideMask'],
  data: function () {
    return {
      mini: false,
      content: undefined,
      menu: false,
      unfiskReceipts: [],
      margin: true,
      switchOnScreenKeyboard: false,
      listeners: [],
      newVersion: 0,
      newSubversion: 0,
      tooltipDelay: 500,
      showOrderPrintLocationSelection: false,
      locations: [],
      location: undefined,
      importantNotif: undefined,
      showNotif: false,
      confirmation: ''
    }
  },
  computed: {
    color () {
      return 'red'
    },
    avatar () {
      return state.getUser()?.name?.charAt(0)?.toUpperCase() || ''
    }
  },
  created () {
    this.setVersionListener()
    this.tooltipDelay = state.getTooltipDelay()
  },
  mounted () {
    if (state.getCurrentCompany().kelner === true) {
      if (state.getOrderPrintLocation() && state.getOrderPrintLocation().id !== '') {
        this.tables()
      } else {
        this.getPointsOfSale()
        this.showOrderPrintLocationSelection = true
      }
    }

    this.setNotificationListener()

    this.drawer = !this.drawer
    if (this.$route && this.$route.meta) this.setTopBar(this.$route.meta.topBar)
  },
  watch: {
    '$route' (to) {
      if (to && to.meta) this.setTopBar(to.meta.topBar)
    },
    newVersion (val) {
      if (val > state.getVersion()) {
        var currentVersion = state.getVersion() + '.' + state.getSubversion()
        var latestVersion = this.newVersion + '.' + this.newSubversion
        this.showMask(currentVersion, latestVersion)
      }

      if (val <= state.getVersion() && this.newSubversion <= state.getSubversion()) {
        this.hideMask()
      }
    },
    newSubversion (val) {
      if (val > state.getSubversion()) {
        var currentVersion = state.getVersion() + '.' + state.getSubversion()
        var latestVersion = this.newVersion + '.' + this.newSubversion
        this.showMask(currentVersion, latestVersion)
      }

      if (val <= state.getSubversion() && this.newVersion <= state.getVersion()) {
        this.hideMask()
      }
    }
  },
  methods: {
    setVersionListener () {
      const listener = df.doc('project_constants/web_version').onSnapshot(doc => {
        this.newVersion = doc.data().version
        this.newSubversion = doc.data().subversion
      })
      this.listeners.push(listener)
    },
    setOrderLocation () {
      state.setOrderPrintLocation(this.location)
      this.tables()
      this.showOrderPrintLocationSelection = false
    },

    setNotificationListener () {
      const query = df.collection(`user_notifications/${auth.currentUser.uid}/notifications`)
        .orderBy('created', 'asc')
      const listener = query
        .onSnapshot((doc) => {
          if (doc.docs.length > 0) {
            this.showNotif = false
            this.importantNotif = undefined
            doc.docs.forEach((rec, key) => {
              var notification = rec.data()
              if (notification.readed === false) {
                setTimeout(() => {
                  this.importantNotif = rec.data()
                  this.showNotif = true
                }, 2000)
              }
            })
          } else {
            this.showNotif = false
            this.importantNotif = undefined
          }
        }
        )
      this.listeners.push(listener)
    },
    // submit () {
    //   if (this.checkConfirmation() === false) {
    //     return
    //   }
    //   var userID = state.getCashRegisterUser() && state.getCashRegisterUser().id !== undefined ? state.getCashRegisterUser().id : state.getUser().id
    //   var userName = state.getCashRegisterUser() && state.getCashRegisterUser().name !== undefined ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname
    //   df.doc(`company_notifications/${state.getCurrentCompany().id}/notifications/${this.importantNotif.id}`)
    //     .update({
    //       readed: true,
    //       reader_id: userID,
    //       reader_name: userName,
    //       readed_time: parseInt(Date.now() / 1000)
    //     })
    // },
    getRoute () {
      var result = /([^.]+$)/.exec(this.$route.name)[0]
      return result
    },

    switchKeyboard (value) {
      this.switchOnScreenKeyboard = !value
      state.setOsk(this.switchOnScreenKeyboard)
    },
    setMini () {
      // this.mini = !this.mini
      this.margin = !this.margin
      state.setMargin(this.margin)
      EventBus.$emit('set-mini')
    },
    openCalc () {
      // EventBus.$emit('open-euro-calc')
      this.$refs.euroCalcDialog.open()
    },
    setTopBar (topBar) {
      if (topBar && topBar !== this.content) this.content = topBar
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
    }
  }
}
</script>
<style scoped>
.notifications {
  overflow-y: scroll;
  color: black;
  font-size: 18px;
  max-height: 400px;
  overflow-y: scroll;
}
.redText {
  color: red;
}
.whiteText {
  color: white;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
