<template>
  <v-dialog max-width="640" v-model="showError">
    <v-card>
      <v-card-title style="color: white; background-color: darkred">
        <v-row class="pb-3 pt-3">
          <h3>Dogodila se greška</h3>
          <v-spacer></v-spacer>
          <v-icon large @click="showError = false">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-2">
        <div>{{ errorMessage }}</div>
      </v-card-text>
    </v-card></v-dialog
  >
</template>

<script>
export default {
  name: 'ShowError',
  data: () => ({
    showError: false,
    errorMessage: ''
  }),
  methods: {
    open (message) {
      this.errorMessage = message
      this.showError = true
    }
  }
}
</script>
