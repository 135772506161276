<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    persistent
    max-width="350"
  >
    <v-form ref="addBoardMemberForm" @submit.prevent="submit">
      <v-card
        tile
        color="white"
        class="pb-3 overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
        style="border-radius: 25px !important"
      >
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;
            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold"
            >Dodaj člana uprave</span
          >
        </v-card-title>
        <v-card-text class="pb-0 mb-0">
          <v-container>
            <v-row class="pt-0 mt-0 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  class="textField"
                  autofocus
                  dense
                  outlined
                  label="Ime"
                  v-model="firstName"
                  :rules="[rules.req]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  class="textField"
                  dense
                  outlined
                  label="Prezime"
                  v-model="lastName"
                  :rules="[rules.req]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-select
                  hide-details
                  class="textField"
                  outlined
                  dense
                  :items="[
                    { val: true, name: $t('$vuetify.yes') },
                    { val: false, name: $t('$vuetify.no') },
                  ]"
                  v-model="ceo"
                  label="Direktor"
                  item-text="name"
                  item-value="val"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex pt-3 mt-0 pr-7 justify-end">
          <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
          <v-btn id="success" class="btn successButton" type="submit">
            Dodaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
export default {
  data: function () {
    return {
      visible: false,
      firstName: undefined,
      lastName: undefined,
      idx: undefined,
      ceo: true,
      rules: {
        req: rules.req()
      }
    }
  },
  methods: {
    open (existing = null, idx = undefined) {
      if (idx || idx === 0) {
        this.idx = idx
      }
      if (existing) {
        this.firstName = existing.first_name
        this.lastName = existing.last_name
        this.ceo = existing.ceo
      } else {
        this.resetValues()
        this.$forceUpdate()
      }
      this.visible = true
    },
    close () {
      this.visible = false
    },
    submit () {
      if (!this.$refs.addBoardMemberForm.validate()) return
      // payload.client = { ...payload.client, ...{ [optionstaging/web option === 'iban' ? [this[option]] : this[option] } }

      this.$emit('add', { first_name: this.firstName, last_name: this.lastName, ceo: this.ceo, idx: this.idx })
      this.resetValues()
      this.visible = false
    },
    resetValues () {
      this.firstName = undefined
      this.lastName = undefined
      this.idx = undefined
      this.ceo = true
    }
  }
}
</script>
<style scoped>
.textField >>> .v-input__slot {
  border-radius: 12px !important;
}
</style>
