<template>
  <div>
    <v-dialog
      :max-width="width"
      v-model="dialog"
      v-if="dialog"
      persistent
      @keydown.esc="close"
      content-class="my-custom-dialog"
    >
      <v-card tile color="white">
        <v-card-title>
          <h2 class="text--secondary">Odaberite vrstu kartice</h2>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
      </v-card>
      <v-card-text class="white">
        <v-container>
          <v-row class="panel-row">
            <v-col
              v-for="(card, idxc) in cards"
              :key="idxc"
              :cols="12"
              class="mb-1 pb-0 mt-0 pt-0"
            >
              <v-btn
                style="border-radius: 12px"
                block
                tile
                depressed
                min-height="6rem"
                color="#295586"
                class="mb-0 pb-0"
                return-object
                @click="payWithCard(card)"
              >
                <span class="white--text tile btn-lg text-xs-center">{{
                  card.name
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      card: null,
      width: '500px',
      type: ''
    }
  },
  props: {
    cards: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open (type) {
      this.type = type
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    payWithCard (card) {
      if (!this.type || !card) {
        console.error('Patment type and/or card unknown.')
      } else {
        this.$emit('checkTerminal', 'K', this.type, undefined, card)
      }
      this.close()
    }
  }
}
</script>

<style scoped>
@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}
.btn-lg {
  font-size: 1.5rem;
  font-weight: 700;
}
.v-dialog__content >>> .my-custom-dialog {
  border-radius: 25px !important;
}
</style>
