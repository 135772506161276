<template>
  <v-dialog
    v-model="visible"
    persistent
    :max-width="width"
    @keydown.esc="close"
    content-class="my-custom-dialog"
  >
    <v-card tile color="white">
      <v-card-title>
        <h2 class="text--secondary">Terminali</h2>
        <v-spacer></v-spacer>
        <v-icon large @click="close">mdi-close</v-icon>
      </v-card-title>
    </v-card>
    <v-card-text class="white">
      <v-container>
        <!-- <v-row>
          <v-col
            cols="12"
            class="panel-cell"
            v-for="(terminal, key) in terminals"
            :key="key"
          >
            <v-btn
              style="
                display: inline-block;
                font-size: 1.4rem !important;
                font-weight: 700 !important;
                max-width: 320 !important;
              "
              block
              tile
              depressed
              color="#295586"
              min-height="3.5rem"
              @click="finish(terminal)"
            >
              <span class="text-wrap label">{{
                terminal.name ? terminal.name : ""
              }}</span>
            </v-btn>
          </v-col>
        </v-row> -->

        <v-row class="panel-row">
          <v-col
            v-for="(item, idxc) in terminals"
            :key="idxc"
            :cols="12"
            class="panel-cell"
            align="center"
          >
            <v-btn
              style="border-radius: 12px"
              block
              tile
              depressed
              min-height="6rem"
              color="#295586"
              class=""
              return-object
              @click="finish(item)"
            >
              <span class="white--text tile">{{ item.name }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12">
            <h4 style="margin-bottom: 1rem">Boja pozadine</h4>
            <v-color-picker
              show-swatches
              :swatches="swatches"
              canvas-height="100"
              mode="hexa"
              v-model="backgroundColor"
            ></v-color-picker>
          </v-col>
        </v-row> -->
      </v-container>
    </v-card-text>
    <!-- <v-card-actions class="white">
      <v-btn
        class="white--text okButton"
        height="45"
        @click="onAddItem('category')"
      >
        Spremi kategoriju
      </v-btn>
      <v-btn
        class="white--text okButton"
        height="45"
        @click="onAddItem('product')"
      >
        Spremi proizvod
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="white--text okButton" height="45" @click="onDeleteItem">
        Ukloni
      </v-btn>
    </v-card-actions> -->
  </v-dialog>
</template>

<script>

export default {
  data () {
    return {
      visible: false,
      dialogOpen: false,
      terminals: [],
      method: undefined,
      receiptType: undefined,
      paymentCard: undefined,
      width: '500px'
    }
  },
  props: {
    editItem: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
  },
  watch: {

  },
  methods: {
    close () {
      this.terminals = []
      this.method = undefined
      this.receiptType = undefined
      this.paymentCard = undefined
      this.visible = false
    },
    open (cashReg, paymentCard, method, receiptType) {
      this.paymentCard = paymentCard
      this.method = method
      this.receiptType = receiptType
      this.visible = true

      var terms

      if (cashReg.terminals) {
        terms = cashReg.terminals
        var termsWithCards = []
        Object.keys(terms).forEach(key => {
          // Ako nema kartica na terminalu, racunaj da su sve kartice na tom terminalu
          if (terms[key].cards && terms[key].cards.length === 0) {
            termsWithCards.push(terms[key])
          }

          // Ako ima kartica, provjeri koje kartice su na tom terminalu
          if (terms[key].cards && terms[key].cards.length > 0 && paymentCard && terms[key].cards.some(card => card.ID === paymentCard.id)) {
            termsWithCards.push(terms[key])
          }

          // Ako nema poslane kartice, onda mu daj sve dostupne terminale
          if (terms[key].cards && terms[key].cards.length > 0 && !paymentCard) {
            termsWithCards.push(terms[key])
          }
        })

        this.terminals = termsWithCards
      }
    },
    finish (terminal) {
      this.$emit('terminalSelected', terminal, this.paymentCard, this.method, this.receiptType)
      this.close()
    }

  },

  async mounted () {

  }
}
</script>
<style scoped>
.panel-container {
  margin-top: 1rem;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.panel-cell {
  padding: 0 1px 4px 0;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.label {
  font-size: 0.6rem;
  color: white;
}
span.tile {
  display: block;
  white-space: normal;
  max-width: 320px;
  font-weight: 700;
  font-size: 1.4rem;
}
.v-dialog__content >>> .my-custom-dialog {
  border-radius: 25px !important;
}
</style>
