<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    width="350"
    style="z-index: 6"
  >
    <v-form ref="certificateForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Certifikat</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="text-h6"> Firma: {{ company.name }} </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                autofocus
                label="Učitaj certifikat"
                accept=".p12, .pfx, .pem, .crt, .cer, .ca-bundle, .p7b, .p7c, .p7s"
                :rules="[rules.req, rules.certSize]"
                @change="certFileChange"
              >
              </v-file-input>
            </v-col>
          </v-row>
          <v-row style="padding-top: 15px">
            <v-col>
              <v-text-field
                label="Lozinka certifikata"
                v-model="certPass"
                ref="certPass"
                dense
                :rules="[rules.req]"
                :type="passVisible ? 'text' : 'password'"
                :append-icon="passVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (passVisible = !passVisible)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center mb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj certifikat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'

import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'CertificateUpload',
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  data: () => ({
    visible: false,
    certificate: undefined,
    certPass: undefined,
    business: undefined,
    company: {},
    passVisible: false,
    submitting: false,
    unsubscribe: undefined,
    unsubscribeError: undefined,
    listeners: [],
    rules: {
      req: rules.req(),
      certSize: (value) =>
        !value ||
        value.size < 1048487 ||
        'Najveća dozvoljena veličina dokumenta je 1MB.'
    }
  }),
  methods: {
    open (company) {
      this.company = Object.assign(this.company, company)
      this.visible = true
    },
    close () {
      this.$refs.certificateForm.reset()
      this.submitting = false
      this.visible = false
      this.unsubscribeListeners()
    },
    resetForm () {
      this.$refs.certificateForm.reset()
    },
    toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async certFileChange (_file) {
      if (_file) {
        const file = await this.toBase64(_file)
        try {
          this.certificate = file.split(',')[1]
        } catch (err) {
          this.certificate = undefined
          this.showMsgBox({
            text: err,
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
        }
      }
    },
    unsubscribeListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    submit () {
      if (!this.$refs.certificateForm.validate()) return
      const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'set',
          entity: 'certificate',
          params: {
            cert: this.certificate,
            // hash: `web.${this.certPass}.${auth.currentUser.uid}`,
            company_id: this.company.id,
            password: this.certPass
          }
        }
      }

      this.submitting = true
      df.doc(`request/${reqId}`)
        .set({
          msg_id: reqId,
          user_id: `${auth.currentUser.uid}`,
          device_id: 'web',
          created: `${new Date().getTime()}`,
          type: 'certificate',
          payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
        })
        // .then(function (doc) {
        // })
        // .catch(function (error) {
        // })
        .finally(() => {})

      const docRef = df.doc(`response/${reqId}`)
      this.listeners.push(
        docRef.onSnapshot((doc) => {
          if (doc && doc.data() && doc.data().header && doc.data().header.code === 200) {
            this.submitting = false
            this.showMsgBox({
              text: 'Certifikat je uploadan!',
              actions: ['cancel'],
              cancelBtnText: 'OK'
            })
            this.close()
          }
        })
      )

      const errorRef = df.collection('errors')
      this.listeners.push(
        errorRef
          .where('error_id', '==', `${auth.currentUser.uid}.${reqId}`)
          .onSnapshot((documentSnapshots) => {
            if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
              documentSnapshots.docs.forEach((item) => {
                const error = item.data()
                if (error && error.error_status) {
                  let erStatus = ''
                  if (error.error_status.includes('Invalid certificate password:')) {
                    erStatus = 'Neispravna lozinka certifikata'
                    this.$refs.certPass.reset()
                  }
                  this.showMsgBox({
                    text: erStatus || error.error_status,
                    actions: ['cancel'],
                    cancelBtnText: 'OK',
                    color: 'error'
                  })
                  this.submitting = false
                }
              })
            }
          })
      )
    }
  },
  beforeDestroy () {
    this.unsubscribeListeners()
  }
}
</script>
<style scoped>
</style>
