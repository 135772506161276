<template>
  <div>
    <v-speed-dial
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      fixed
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
      v-if="
        $route.name !== 'cashRegister' &&
        !$route.params.redirectedThruIssue &&
        !$route.name.includes('recapitulation') &&
        !$route.name.includes('orders') &&
        !$route.name.includes('companies') &&
        !$route.name.includes('suppliers') &&
        !$route.name.includes('documentsv2')
      "
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="#7BD500" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-menu </v-icon>
        </v-btn>
      </template>

      <v-btn
        style="z-index: 99; margin-right: 35px; margin-top: 5px !important"
        dark
        medium
        color="green"
        @click="openCrudDialog"
      >
        <v-icon>mdi-plus</v-icon>
        <span><b>Račun</b></span>
      </v-btn>

      <v-btn
        style="z-index: 99; margin-right: 63px"
        dark
        medium
        color="#2697c5"
        @click="openMaster"
      >
        <v-icon>mdi-menu</v-icon>
        <span><b>Izvještaji</b></span>
      </v-btn>

      <v-btn
        v-if="showNewWarehouseDocumentBtn"
        style="z-index: 99; margin-right: 40px"
        dark
        medium
        color="orange"
        @click="openWarehouseNewDocument"
      >
        <v-icon>mdi-plus</v-icon>
        <span><b>Primka</b></span>
      </v-btn>
    </v-speed-dial>
    <crud-template-dialog ref="crudTemplateDialog"></crud-template-dialog>
  </div>
</template>
<script>

import state from '@/state'
import CrudTemplateDialog from '@/modules/cash-register/components/dialogs/CrudTemplateDialog'

export default {
  name: 'FabMenu',
  inject: [],
  components: { CrudTemplateDialog },
  data: function () {
    return {
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-x-reverse-transition',
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null
    }
  },
  computed: {
    showNewWarehouseDocumentBtn () {
      var show = false
      if (state.getCurrentCompany().warehouse === 'module') {
        show = true
      }

      return show
    }

  },
  created () {
  },
  mounted () {

  },
  methods: {
    openWarehouseNewDocument () {
      var targetModifier = this.getTargetModifier()
      var companyId = this.getCompanyId()
      var locationId = this.getLocationId()

      var route = {
        name: `${targetModifier}.warehouseModule.documents`,
        params: {
          companyId: companyId
        }
      }

      if (locationId !== '') {
        route.locationId = locationId
      }

      this.$router.push(route)
    },
    openMaster () {
      var targetModifier = this.getTargetModifier()
      var companyId = this.getCompanyId()
      var locationId = this.getLocationId()

      var route = {
        name: `${targetModifier}.all-reports.reportMenu`,
        params: {
          companyId: companyId
        }
      }

      if (locationId !== '') {
        route.locationId = locationId
      }

      this.$router.push(route)
    },

    getCompanyId () {
      var companyId = ''
      if (state.getCurrentCompany() && state.getCurrentCompany().id && state.getCurrentCompany().id !== null && state.getCurrentCompany().id !== undefined) {
        companyId = state.getCurrentCompany().id
      }

      return companyId
    },

    getLocationId () {
      var locationId = ''
      if (state.getPointOfSale() && state.getPointOfSale().id && state.getPointOfSale().id !== null && state.getPointOfSale().id !== undefined) {
        locationId = state.getPointOfSale().id
      }

      return locationId
    },

    getTargetModifier () {
      var sideNav = this.$route.meta.sideNav

      var targetModifier = ''
      switch (sideNav) {
        case 'Companies':
          break
        case 'Company':
          targetModifier = 'company'
          break
        case 'PointOfSale':
          targetModifier = 'pointOfSale'
          break
        case 'CashRegister':
          targetModifier = 'pointOfSale'
          break
      }

      return targetModifier
    },
    openCrudDialog () {
      this.loadingDialog = true
      this.$refs.crudTemplateDialog.open(this.loadingDialog)
    }
  }
}
</script>
