import { reprint } from '@/libs/bixolon/reprint'
import { print58 } from '@/libs/bixolon/print58'
import { printVirman } from '@/libs/bixolon/virman'
import { printVirman58 } from '@/libs/bixolon/virman58'
import { dateTimeToUnixTimestamp } from '@/libs/receiptIssuing/commons'
import EventBus from '@/plugins/event-bus'
import { auth, df, functions } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import { validateParamsForReceipt } from '@/libs/receiptIssuing/validation'
import { DAY } from '@/plugins/utils'
import LogData from '@/model/LogData'

let userId = auth.currentUser.uid
if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
  userId = state.getCashRegisterUser().id
}

export const prepareReceiptLogCall = (receipt) => {
  var req = new LogData()

  let userId = auth.currentUser.uid
  if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
    userId = state.getCashRegisterUser().id
  }

  var separator = '-'
  if (receipt.company_details.rcpt_separator !== '') {
    separator = receipt.company_details.rcpt_separator
  }

  var description = 'Račun broj: ' + receipt.designation.number + separator + receipt.designation.location_id + separator + receipt.designation.register_number
  description += '\n'

  if (receipt.items.length > 0) {
    receipt.items.forEach((item, k) => {
      if (k > 0 && k < receipt.items.length) {
        description += '\n'
      }

      var price = item.price
      if (item && item.discount && item.discount.active) {
        price = item.discounted_price
      }

      var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })

      var priceFormatted = formatter.format(price / 100)
      var amt = item.amt / 1000
      description += amt.toFixed(2).replace('.', ',') + ' x ' + item.name + ' ' + priceFormatted
    })

    description += '\n'
  }

  var totalFormatted = (receipt.total / 100).toFixed(2).replace('.', ',')
  description += 'Ukupno račun: ' + totalFormatted + '€'

  req.action_name = 'Naplata'
  req.action_id = '7'
  req.description = description
  req.user_id = userId

  var userLogApi = functions.httpsCallable('userlogapi')

  if (!req.user_id) {
    req.user_id = auth.currentUser.uid
  }

  req.company_id = state.getCurrentCompany().id ?? ''
  req.company_name = state.getCurrentCompany().name ?? ''
  req.location_id = state.getPointOfSale().id ?? ''
  req.location_name = state.getPointOfSale().name ?? ''
  req.cash_register_id = state.getCashRegister().id ?? ''
  req.cash_register_name = state.getCashRegister().name ?? ''

  userLogApi({
    action: {
      operation: 'set',
      entity: 'log',
      params: req
    }
  })
    .then((result) => {
      // console.log('result', result)
    }
    )
}

export const sendPubSub = (data, payload, openPdfPrint, hideLoader) => {
  const reqId = uuidv4()

  df.doc(`request/${reqId}`)
    .set({
      user_id: `${userId}`,
      device_id: 'web',
      created: `${dateTimeToUnixTimestamp(new Date())}`,
      type: 'receipt',
      payload: btoa(
        unescape(encodeURIComponent(JSON.stringify(payload)))
      )
    })
    .catch((err) => {
      this.showMsgBox({
        text: err && err !== '' ? err : 'An error has occurred',
        actions: ['cancel'],
        cancelBtnText: this.$t('$vuetify.close'),
        color: 'error'
      })
    })
  const docRef = df.doc(`response/${reqId}`)

  const unsubscribe = docRef.onSnapshot((doc) => {
    if (doc.data()) {
      unsubscribe()
      if (!doc.data().body || !doc.data().body.document) {
        let logError = this.$t('$vuetify.errors.nullReceipt')
        if (doc.data().header.error) {
          logError = doc.data().header.error
        }
        this.showMsgBox({
          text: logError,
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })

        hideLoader()
        return
      }

      if (state.getPrinterLib() === 'BIXOLON80') {
        // printReceipt('Printer1', data, doc.data(), payload)
        // reprint('Printer1', result.data.full_receipt, 2)
      } else if (state.getPrinterLib() === 'UNIVERSAL') {
        // reprint('Printer1', result.data.full_receipt, 2)
      } else { openPdfPrint(doc.data().body.document) }
      hideLoader()
    }
  })
}

export const sendHttp = async (data, items, payload, args, printer, additionalData = null) => {
  const reqId = uuidv4()
  var receiptApi = functions.httpsCallable('receiptapi')

  // let printer = null

  // const printerResponse = await df.doc(`location_printers/${state.getPointOfSale().id}/printers/${state.getCashRegister().printer}`).get()
  // if (printerResponse) {
  //   printer = printerResponse.data()
  // }
  const companyId = additionalData?.companyId ?? state.getCurrentCompany().id
  const locationId = additionalData?.locationId ?? state.getPointOfSale().id
  const registerId = additionalData?.registerId ?? state.getCashRegister().id

  const params = {
    company_id: companyId,
    location_id: locationId,
    register_id: registerId,
    issue_date_time: args.issueDateTime,
    payment_method: args.paymentMeth,
    seller_oib: '',
    currency: data.currency,
    payment_reference: payload.action.params.payment_reference ?? '',
    language: args.language.value,
    type: payload.action.params.type,
    items: items,
    note: payload.action.params.note,
    number: parseInt(args.receiptNumber),
    fiscalize: args.fiscalize,
    reqid: reqId
  }

  if (payload.action?.params?.wholesale_receipt_type) {
    params.wholesale_receipt_type = payload.action.params.wholesale_receipt_type
  }

  if (args.delivery_date) { params.delivery_date = args.delivery_date }

  if (['T58', 'T80', 'A4'].includes(printer.width)) {
    params.print_size = printer.width
    params.generate_pdf = true
  } else {
    params.generate_pdf = false
    params.print_size = 'BIXOLON80'
  }

  if (data.discount !== undefined) {
    params.discount = data.discount
  }

  if (data.discount) {
    params.discount = data.discount
  }

  if (data.fullTotal && data.fullTotal > 0) {
    params.full_total = Math.round(data.fullTotal)
  } else {
    params.full_total = Math.round(data.total)
  }

  if (data.total && data.total > 0) {
    params.total = Math.round(data.total)
  }

  if (data.totalWithDiscount && data.totalWithDiscount > 0) {
    params.total = Math.round(data.totalWithDiscount)
  }

  if (args.paymentMeth === 'OSTALO' && args.reprezentacija && args.reprezentacija === true) {
    if (!params.tags) {
      params.tags = []
    }
    params.tags.push({ receipt_type: 'REPREZENTACIJA' })

    var reprezentacijaAmount = 200
    if (Date.now() / 1000 > 1672527599) {
      reprezentacijaAmount = 50
    }

    const tempTotal = params.total - reprezentacijaAmount
    params.total = reprezentacijaAmount

    const discount = {
      active: true,
      amount: tempTotal,
      basketDiscount: true,
      company_id: state.getCurrentCompany().id,
      id: uuidv4(),
      name: 'Reprezentacija',
      type: 'AMOUNT'
    }
    params.discount = discount
    data.discount = discount
    data.total = reprezentacijaAmount / 100
  }

  if (args.delivery && args.delivery === true) {
    if (!params.tags) {
      params.tags = []
    }
    params.tags.push({ receipt_type: 'DELIVERY' })
    params.tags.push({ delivery_brand: args.deliveryName })
  }

  if (args.paymentCard && args.paymentCard !== '') {
    if (!params.tags) {
      params.tags = []
    }
    params.tags.push({ payment_card: args.paymentCard })
  }

  var userID = auth.currentUser.uid
  if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
    userID = state.getCashRegisterUser().id
  }

  params.seller_id = userID

  if (payload.terminal) {
    if (!params.tags) {
      params.tags = []
    }
    params.tags.push({ pos_type: payload.terminal.type_name }, { device_id: payload.terminal.device_id }, { pos_id: payload.terminal.pos_id })

    // if (state.getCashRegister().terminal_type === 'before') {
    const rID = uuidv4()
    const pl = {
      action: {
        operation: 'set',
        entity: 'pos',
        params: {
          company_id: state.getCurrentCompany().id,
          cash_register_id: state.getCashRegister().id,
          pos_type: payload.terminal.type_name,
          device_id: payload.terminal.device_id,
          pos_id: payload.terminal.pos_id,
          amount: params.total,
          merchant_reference: state.getCurrentCompany().id + '.' + reqId
        }
      }
    }

    df.doc(`request/${rID}`)
      .set({
        user_id: `${userId}`,
        device_id: 'web',
        created: `${dateTimeToUnixTimestamp(new Date())}`,
        type: 'cardpos',
        payload: btoa(
          unescape(encodeURIComponent(JSON.stringify(pl)))
        )
      })
      .catch((err) => {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      })
    // }
  }

  var cashRegisterType = additionalData?.cashRegister?.type ?? state.getCashRegister().type

  var validate = true
  if (cashRegisterType === 'ALL' && params.payment_method === 'TRANSAKCIJSKI RAČUN') {
    validate = false
  }

  if (validate) {
    var validationData = validateParamsForReceipt(params)

    if (validationData.valid === false) {
      args.hideLoader()
      EventBus.$emit('error-return')
      EventBus.$emit('showError', validationData)
      return
    }
  }

  if (params.type.flag === 'R') {
    if (args.overrideValueDateTime) {
      params.payment_due = args.overrideValueDateTime
    } else {
      params.payment_due = parseInt(dateTimeToUnixTimestamp(new Date(new Date().getTime() + 8 * DAY)))
    }
  }

  if (payload.action.entity === 'offer') {
    delete params.fiscalize
    delete params.print_size
    delete params.seller_id
    delete params.seller_oib
    params.type.flag = 'P'
    // params.offer_due = parseInt(dateTimeToUnixTimestamp(new Date(new Date().getTime() + 8 * DAY)))
    params.items = payload.action.params.items

    if (args.overrideValueDateTime) {
      params.offer_due = args.overrideValueDateTime
    } else {
      params.offer_due = parseInt(dateTimeToUnixTimestamp(new Date(new Date().getTime() + 8 * DAY)))
    }
  }

  // console.log('params', params)

  receiptApi({
    action: {
      operation: 'set',
      entity: payload.action.entity,
      params: params
    }
  })
    .then((result) => {
      var receipttemplate = functions.httpsCallable('receiptTemplate')

      let printTemplate = ''
      if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
        printTemplate = state.getCurrentCompany().print_template
      }

      var errorReceiptMsg = 'računa'
      if (payload.action.entity === 'offer') {
        errorReceiptMsg = 'ponude'
      }

      if (result && result.data && result.data && result.data.code !== 200) {
        args.hideLoader()
        EventBus.$emit('error-return')

        var confirm = args.confirm
        var confirmClose = args.confirmClose
        confirm({
          title: 'Pogreška prilikom izdavanja ' + errorReceiptMsg,
          message: 'Molimo provjerite da li se račun kojeg ste upravo izdali nalazi na listi računa.',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'OK',
            hideCancel: true
          }
        }).then(_ => {
          confirmClose()
          EventBus.$emit('open_receipts')
        })
        return
      }

      if (result && result.data && result.data.zki !== '') {
        if (state.getCurrentCompany().save_log) {
          prepareReceiptLogCall(result?.data?.full_receipt)
        }
        data.removeOrderFromTable = args.removeOrderFromTable
        if ((!printer.width || printer.width === '80') && result.data.full_receipt.payment_method !== 'TRANSAKCIJSKI RAČUN') {
          // Printer stare konfiguracije nema definiranu širinu
          reprint(printer, result.data.full_receipt, 2)
          args.hideLoader()
          EventBus.$emit('stop-loader')
        } else if (printer.width && printer.width === '58' && result.data.full_receipt.payment_method !== 'TRANSAKCIJSKI RAČUN') {
          print58(printer, result.data.full_receipt, 2)
          args.hideLoader()
          EventBus.$emit('stop-loader')
        } else if ((printer.width && ['T58', 'T80', 'A4'].includes(printer.width)) || result.data.full_receipt.payment_method === 'TRANSAKCIJSKI RAČUN') {
          var documentType = 'racun'
          if (payload.action.entity === 'offer') {
            documentType = 'ponuda'
          }

          var documentSubType = 'racun'
          if (state.getCurrentCompany().receipt_type && state.getCurrentCompany().receipt_type !== '') {
            documentSubType = state.getCurrentCompany().receipt_type
          }

          receipttemplate({
            template: printTemplate,
            companyId: result.data.full_receipt.company_details.id,
            documentId: result.data.full_receipt.id,
            documentSubType: documentSubType,
            documentType: documentType,
            rawJson: ''
          })
            .then((result) => {
              if (result.data?.document !== '') {
                args.openPdfPrint(result.data?.document)
                args.hideLoader()
                EventBus.$emit('clear-receipt-data')
                EventBus.$emit('stop-loader')
              }
            })
        }
      } else if (payload.action.entity === 'offer') {
        receipttemplate({
          template: printTemplate,
          companyId: companyId,
          documentId: params.reqid,
          documentSubType: 'racun',
          documentType: 'ponuda',
          rawJson: ''
        })
          .then((result) => {
            if (result.data?.document !== '') {
              args.openPdfPrint(result.data?.document)
              args.hideLoader()
              EventBus.$emit('stop-loader')
            }
          })
      } else {
        args.hideLoader()
        EventBus.$emit('error-return')
        alert('Dogodila se greška pri izdavanju ' + errorReceiptMsg + ' : ' + result.data.header.error)
      }
    })
}

export const sendVirmanHttp = async (data, items, payload, args, printer, additionalData = null) => {
  const reqId = uuidv4()

  var receiptApi = functions.httpsCallable('receiptapi')

  // const printerResponse = await df.doc(`location_printers/${state.getPointOfSale().id}/printers/${state.getCashRegister().printer}`).get()
  // if (printerResponse) {
  //   printer = printerResponse.data()
  // }

  var virmanNumber = 0
  const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()

  if (register.data().virman_sequence && register.data().virman_sequence > 0) {
    virmanNumber = register.data().virman_sequence
  }

  const companyId = additionalData?.companyId ?? state.getCurrentCompany().id
  const locationId = additionalData?.locationId ?? state.getPointOfSale().id
  const registerId = additionalData?.registerId ?? state.getCashRegister().id

  const params = {
    company_id: companyId,
    location_id: locationId,
    register_id: registerId,
    issue_date_time: args.issueDateTime,
    payment_method: 'TRANSAKCIJSKI RAČUN',
    currency: data.currency,
    payment_reference: payload.action.params.payment_reference ?? '',
    language: args.language.value,
    type: payload.action.params.type,
    items: payload.action.params.items,
    note: payload.action.params.note,
    number: parseInt(virmanNumber + 1),
    reqid: reqId
  }

  if (data.discount !== undefined) {
    params.discount = data.discount
  }

  if (data.discount !== undefined) {
    params.discount = data.discount
  }

  if (data.fullTotal !== undefined && data.fullTotal > 0) {
    params.full_total = data.fullTotal
  } else {
    params.full_total = data.total
  }

  if (data.total !== undefined && data.total > 0) {
    params.total = data.total
  }

  if (data.totalWithDiscount !== undefined && data.totalWithDiscount > 0) {
    params.total = data.totalWithDiscount
  }

  // if (['T58', 'T80', 'A4'].includes(printer.width)) {
  //   params.print_size = printer.width
  // } else {
  //   params.print_size = 'BIXOLON80'
  // }

  var userID = auth.currentUser.uid
  if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
    userID = state.getCashRegisterUser().id
  }

  params.seller_id = userID

  var validationData = validateParamsForReceipt(params)
  if (validationData.valid === false) {
    args.hideLoader()
    EventBus.$emit('showError', validationData)
    EventBus.$emit('error-return')
    return
  }

  receiptApi({
    action: {
      operation: 'set',
      entity: 'virman',
      params: params
    }
  })
    .then(async (result) => {
      if (result && result.data && result.data.header && result.data.header.code !== 200) {
        args.hideLoader()
        EventBus.$emit('error-return')
        alert('Dogodila se greška pri izdavanju računa: ' + result.data.header.error)
        return
      }

      if (result && result.data && result.data.full_virman) {
        data.removeOrderFromTable = args.removeOrderFromTable

        if (!printer.width || printer.width === '80') {
          await printVirman(printer, data, result, payload)
          await printVirman(printer, data, result, payload)
          args.hideLoader()
          EventBus.$emit('stop-loader')
        } else if (printer.width && printer.width === '58') {
          await printVirman58(printer, data, result, payload)
          await printVirman58(printer, data, result, payload)
          args.hideLoader()
          EventBus.$emit('stop-loader')
        } else if (printer.width && ['T58', 'T80', 'A4'].includes(printer.width)) {
          var receipttemplate = functions.httpsCallable('receiptTemplate')

          var documentType = 'virman'

          receipttemplate({
            template: 'racun02',
            companyId: companyId,
            documentId: result.data.full_virman.id,
            documentSubType: 'racun',
            documentType: documentType,
            rawJson: ''
          })
            .then((result) => {
              if (result.data?.document !== '') {
                args.openPdfPrint(result.data?.document)
                args.hideLoader()
                EventBus.$emit('stop-loader')
                EventBus.$emit('clear-receipt-data')
                EventBus.$emit('clear-selection')
              }
            })
        }
      } else {
        args.hideLoader()
        EventBus.$emit('error-return')
        alert('Dogodila se greška pri izdavanju računa: ' + result.data.header.error)
      }
    })
}

export const sendPreview = async (data, items, payload, args, printer) => {
  const reqId = uuidv4()
  var receiptPreview = functions.httpsCallable('receiptpreview')

  const params = {
    company_id: state.getCurrentCompany().id,
    location_id: state.getPointOfSale().id,
    register_id: state.getCashRegister().id,
    issue_date_time: args.issueDateTime,
    payment_method: args.paymentMeth,
    seller_oib: '',
    currency: data.currency,
    payment_reference: payload.action.params.payment_reference ?? '',
    language: args.language.value,
    type: payload.action.params.type,
    items: payload.action.params.items,
    note: payload.action.params.note,
    number: parseInt(args.receiptNumber),
    fiscalize: false,
    reqid: reqId
  }

  params.print_size = 'A4'

  if (printer && printer.width && ['T58', 'T80'].includes(printer.width)) {
    params.print_size = printer.width
  }

  var userID = auth.currentUser.uid
  if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
    userID = state.getCashRegisterUser().id
  }

  params.seller_id = userID

  if (payload.action.entity === 'offer_preview') {
    delete params.fiscalize
    delete params.print_size
    delete params.seller_id
    delete params.seller_oib
    params.offer_due = parseInt(dateTimeToUnixTimestamp(new Date()))
  }

  if (payload.action.entity === 'virman_preview') {
    delete params.fiscalize
    delete params.print_size
    delete params.seller_oib
  }

  if (data.discount !== undefined) {
    params.discount = data.discount
  }

  if (data.discount) {
    params.discount = data.discount
  }

  if (data.fullTotal && data.fullTotal > 0) {
    params.full_total = data.fullTotal
  } else {
    params.full_total = data.total
  }

  if (data.total && data.total > 0) {
    params.total = data.total
  }

  if (data.totalWithDiscount && data.totalWithDiscount > 0) {
    params.total = data.totalWithDiscount
  }

  if (args.paymentMeth === 'OSTALO' && args.reprezentacija && args.reprezentacija === true) {
    if (!params.tags) {
      params.tags = []
    }
    params.tags.push({ receipt_type: 'REPREZENTACIJA' })

    var reprezentacijaAmount = 200
    if (Date.now() / 1000 > 1672527599) {
      reprezentacijaAmount = 50
    }

    const tempTotal = params.total - reprezentacijaAmount
    params.total = reprezentacijaAmount

    const discount = {
      active: true,
      amount: tempTotal,
      basketDiscount: true,
      company_id: state.getCurrentCompany().id,
      id: uuidv4(),
      name: 'Reprezentacija',
      type: 'AMOUNT'
    }
    params.discount = discount
    data.discount = discount
    data.total = reprezentacijaAmount / 100
  }

  var validationData = validateParamsForReceipt(params)
  if (validationData.valid === false) {
    args.hideLoader()
    EventBus.$emit('showError', validationData)
    return
  }

  receiptPreview({
    action: {
      operation: 'set',
      entity: payload.action.entity,
      params: params
    }
  })
    .then((result) => {
      if (result && result.data && result.data.header && result.data.header.code !== 200) {
        args.hideLoader()
        alert('Dogodila se greška pri pregledu računa: ' + result.data.header.error)
        return
      }

      const docRef = df.doc(`response/${reqId}`)

      const unsubscribe = docRef.onSnapshot((doc) => {
        if (doc.data()) {
          unsubscribe()
          if (!doc.data().body || !doc.data().body.document) {
            let logError = this.$t('$vuetify.errors.nullReceipt')
            if (doc.data().header.error) {
              logError = doc.data().header.error
            }
            this.showMsgBox({
              text: logError,
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })

            args.hideLoader()
            return
          }

          args.openPdfPrint(doc.data().body.document)

          args.hideLoader()
        }
      })
    })
}
