<template>
  <v-card-text class="ma-0 pa-0">
    <v-row>
      <v-col class="d-flex justify-start">
        <v-avatar
          size="50"
          style="font-size: 25px; color: white"
          color="#5ad0cf"
        >
          {{ avatar }}
        </v-avatar>
        <div
          class="d-flex align-center text-capitalize primary--text font-weight-bold ml-2 text-h6"
        >
          {{ fullName }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list-item-title class="pl-0 text--secondary">{{
          email
        }}</v-list-item-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list-item-title class="pl-0 text--secondary"
          >Verzija {{ version }}</v-list-item-title
        >
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col class="ma-0 pa-0">
        <v-list dense>
          <!-- <v-list-item link class="pr-0" @click="setStep('language')">
            <v-icon>mdi-web</v-icon>
            <span class="pl-2">{{ $t("$vuetify.appLanguageTxt") }}</span>

            <v-spacer></v-spacer>
            <v-icon large>mdi-chevron-right</v-icon>
          </v-list-item> -->
          <v-list-item @click="openDeleteAccount()">
            <v-icon>mdi-delete-empty-outline</v-icon>
            <span class="pl-2" style="color: red; font-weight: bold"
              >OBRIŠI RAČUN</span
            >
          </v-list-item>
          <v-list-item link class="pr-0" @click="setStep('theme')">
            <v-icon>mdi-theme-light-dark</v-icon>
            <span class="pl-2">{{ $t("$vuetify.theme") }}</span>
            <v-spacer></v-spacer>
            <v-icon large>mdi-chevron-right</v-icon>
          </v-list-item>
          <v-list-item link class="pr-0" @click="setStep('general-settings')">
            <v-icon>mdi-cog</v-icon>
            <span class="pl-2">Opcije aplikacije</span>
            <v-spacer></v-spacer>
            <v-icon large>mdi-chevron-right</v-icon>
          </v-list-item>
          <v-list-item
            v-if="company.kelner === true"
            link
            class="pr-0"
            @click="setStep('order-print-settings')"
          >
            <v-icon>mdi-cellphone-text</v-icon>
            <span class="pl-2">Printanje narudžbi</span>
            <v-spacer></v-spacer>
            <v-icon large>mdi-chevron-right</v-icon>
          </v-list-item>
          <v-list-item @click="logOut">
            <v-icon>mdi-logout</v-icon>
            <span class="pl-2">{{ $t("$vuetify.formDetails.logOut") }}</span>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import state from '@/state'
import logOutUser from '@/mixins/logOutUser'
import EventBus from '@/plugins/event-bus'

export default {
  name: 'MainAccountView',
  mixins: [logOutUser],
  data: function () {
    return {
      user: {
        avatar: undefined,
        name: undefined,
        email: undefined,
        sureName: undefined
      }
    }
  },
  computed: {
    avatar () {
      return state.getUser()?.name?.charAt(0)?.toUpperCase() || ''
    },
    superadmin () {
      return state.getUser()?.roles?.SUPERADMIN || false
    },
    email () {
      return state.getUser()?.email || ''
    },
    fullName () {
      return `${state.getUser()?.name || ''} ${state.getUser()?.surname || ''}`
    },
    version () {
      return state.getVersion() + '.' + state.getSubversion()
    },
    company () {
      return state.getCurrentCompany()
    }
  },
  methods: {
    setStep (step) {
      this.$emit('stepChange', step)
    },
    openDeleteAccount () {
      EventBus.$emit('deleteAccount')
    }
  }
}
</script>
