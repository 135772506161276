var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"myTest",staticStyle:{"z-index":"200","border-radius":"25px"},attrs:{"height":"auto","width":"50%","scrollable":"","draggable":"","resizable":true,"clickToClose":false,"name":"notifications"}},[_c('v-card',{staticStyle:{"border-radius":"25px"}},[_c('v-card-title',{class:[
        'd-flex',
        _vm.notification && _vm.notification.type === 'WARNING'
          ? 'notificationWarning'
          : 'notificationInfo' ]},[_c('span',{staticClass:"text-h5",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.notification ? _vm.notification.subject : ""))])]),_c('v-card-text',{staticClass:"pt-5"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"notifications",attrs:{"height":"150"},domProps:{"innerHTML":_vm._s(_vm.notification ? _vm.notification.text : '')},on:{"scroll":_vm.onScroll}})]),(_vm.notification && _vm.notification.type === 'CONFIRMATION')?_c('div',{staticClass:"pt-10"},[_c('div',{class:[_vm.scrolled === false ? 'redText' : 'whiteText'],staticStyle:{"text-align":"center"}},[_c('b',[_vm._v("Molimo pročitajte cijeli tekst!")])]),_vm._v(" Potvrdite čitanje obavijesti upisivanje riječi: "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.keyword)}})]):_vm._e(),(_vm.notification && _vm.notification.type === 'CONFIRMATION')?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(
            _vm.notification &&
            _vm.notification.keyword &&
            _vm.notification.keyword !== ''
          )?_c('v-text-field',{attrs:{"autofocus":"","outlined":"","dense":""},model:{value:(_vm.confirmationText),callback:function ($$v) {_vm.confirmationText=$$v},expression:"confirmationText"}}):_vm._e(),_c('v-row',{staticClass:"justify-end pr-5"},[_c('v-btn',{staticClass:"btn successButton",attrs:{"disabled":!_vm.checkConfirmation() || !_vm.scrolled},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Potvrdi")])],1)],1):_vm._e(),(_vm.notification && _vm.notification.type !== 'CONFIRMATION')?_c('v-form',[_c('v-row',{staticClass:"justify-end pr-5 pt-5 pb-3"},[_c('v-btn',{staticClass:"btn successButton",on:{"click":function($event){return _vm.submit()}}},[_vm._v("Potvrdi")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }