import { functions, auth } from '@/plugins/firebase'
import state from '@/state'

export default {
  methods: {
    async setLog (data) {
      var userLogApi = functions.httpsCallable('userlogapi')

      if (!data.user_id) {
        data.user_id = auth.currentUser.uid
      }

      data.company_id = state.getCurrentCompany().id ?? ''
      data.company_name = state.getCurrentCompany().name ?? ''
      data.location_id = state.getPointOfSale().id ?? ''
      data.location_name = state.getPointOfSale().name ?? ''
      data.cash_register_id = state.getCashRegister().id ?? ''
      data.cash_register_name = state.getCashRegister().name ?? ''

      userLogApi({
        action: {
          operation: 'set',
          entity: 'log',
          params: data
        }
      })
        .then((result) => {
          // console.log('result', result)
        }
        )
    }
  }

}
