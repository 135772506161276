import { requestPrint } from '@/libs/bixolon/bxlcommon'
import { checkPrinterStatus, cutPaper, getPosData, setCharacterset, setPosId } from '@/libs/bixolon/bxlpos'
import { printLine, printTotals, printSeparator } from '@/libs/bixolon/printer'
import state from '@/state'
import { df } from '@/plugins/firebase'
import * as JSPM from 'jsprintmanager'

export const printerInit = (issueID) => {
  checkPrinterStatus()
  setCharacterset(1250)
  setPosId(issueID)
}

const viewResult = (result) => {
  if (result === 'Cannot connect to server') {
    alert('Pisač je nedostupan.')
  }
}

export const getTableOrders = async (oldTable, orderId) => {
  let dbOrder
  await df.doc(`location_orders/${state.getPointOfSale().id}/tables/${oldTable.id}`)
    .get()
    .then((tableData) => {
      const table = tableData.data()
      if (table && table.orders && table.orders.length > 0) {
        table.orders.forEach((order, index) => {
          if (order.id === orderId) {
            dbOrder = table.orders[index]
          }
        })
      }
    })

  return dbOrder
}

export const printOrder = async (its, printerData, table = undefined, order = undefined, orgUnit = undefined, otherUnits = undefined, note = undefined, hideLoader = undefined) => {
  // Fix za staru konfiguraciju
  if (!['UNIVERSAL', 'NONE'].includes(state.getPrinterLib())) {
    if (!printerData.font) printerData.font = 0
    if (!printerData.size) printerData.size = 0
    if (!printerData.width) printerData.width = '80'
    if (!printerData.line_length) printerData.line_length = 48
    if (!printerData.library) printerData.library = 'BIXOLON'
  }

  let [escpos, doc, printerConfiguration] = [null, null, null]
  let items = []
  items = its
  printerConfiguration = printerData

  if (state.getPrinterLib() === 'UNIVERSAL') {
    escpos = window.Neodynamic.JSESCPOSBuilder
    doc = new escpos.Document()
  }

  // Delete items that are menu, and group them in menu
  const menus = {}
  let hasMenu = false

  items.forEach((item, key) => {
    if (item.menuId && item.menuId !== '') {
      if (!menus[item.menuId]) {
        menus[item.menuId] = []
      }
      hasMenu = true
      menus[item.menuId].push(item)

      menus[item.menuId].tags = {}
      menus[item.menuId].tags = item.tags

      delete items[key]
    }
  })

  const user = state.getCashRegisterUser() && state.getCashRegisterUser().id ? state.getCashRegisterUser() : state.getUser()

  const options = {
    horizontal: 0,
    vertical: 0,
    bold: false,
    invert: false,
    underline: false,
    alignment: 0,
    printerConfiguration: printerConfiguration,
    font: printerConfiguration.font,
    document: doc,
    escpos: escpos
  }

  if (state.getPrinterLib === 'UNIVERSAL') {
    options.font = printerConfiguration.font
  } else {
    if (printerConfiguration.font === 'A') options.font = 0
    else if (printerConfiguration.font === 'B') options.font = 1
    else if (printerConfiguration.font === 'C') options.font = 2
  }

  function getOptions (option) {
    const modifiedOptions = JSON.parse(JSON.stringify(options))
    Object.keys(option).forEach(key => {
      modifiedOptions[key] = option[key]
    })
    modifiedOptions.document = options.document
    modifiedOptions.escpos = options.escpos
    modifiedOptions.printerConfiguration = options.printerConfiguration
    return modifiedOptions
  }

  if (table && table.name && table.name !== '') {
    printLine(`${table.name}`, getOptions({ alignment: 1, bold: 1, font: 0, horizontal: 2, vertical: 2 }))
  }

  if (order && order.order_number) {
    printLine('NARUDŽBA ' + order.order_number, getOptions({ alignment: 1, bold: 1, font: 0, horizontal: 1, vertical: 1 }))
  } else {
    printLine('NARUDŽBA ', getOptions({ alignment: 1, bold: 1, font: 0, horizontal: 2, vertical: 2 }))
  }

  printSeparator(options)
  var date = new Date()

  printLine(`Operater: ${user.name}`, getOptions({ font: 0, bold: 1 }))
  printTotals('Datum: ' + ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear(), 'Vrijeme: ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2), options)

  printSeparator(options)

  items.forEach((item) => {
    const lines = item.name.match(/.{1,20}/g)
    printLine(item.quantity + ' X ' + lines[0].toUpperCase(), getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 }))
    if (lines[1]) { printLine('    ' + lines[1].toUpperCase(), getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 })) }
    if (lines[2]) { printLine('    ' + lines[2].toUpperCase(), getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 })) }
    if (item.notes && item.notes.length > 0) {
      const note = item.notes.find(n => n.orderId === order.id)
      if (note && note.message) {
        printLine(`- ${note.message}`, getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 }))
      }
    }
  })

  if (hasMenu === true) {
    Object.keys(menus).forEach((key, kindex) => {
      if (kindex > 0) {
        printSeparator(options)
      }

      if (menus[key] && menus[key].length > 0) {
        menus[key].forEach((menuItem, index) => {
          if (index === 0) {
            printLine('1 X ' + menuItem.menuData.name.toUpperCase(), getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 }))
          }

          printLine('    ' + menuItem.menuData.productName.toUpperCase(), getOptions({ font: 0, vertical: 1, horizontal: 1 }))
          printLine('    ∙' + menuItem.name, getOptions({ font: 0, horizontal: 1, vertical: 1 }))

          // Zakomentirano da se ne ispisuje opcije kraj svakog artikla, potrebno kasnije dodati
          // if (menuItem.tags && menuItem.tags.length > 0) {
          //   menuItem.tags.forEach((tag, tagIndex) => {
          //     if (tagIndex === 0) {
          //       printLine('Odabrane opcije', getOptions({ alignment: 0 }))
          //     }

          //     let allTags = ''
          //     if (tag.tagItems && tag.tagItems.length > 0) {
          //       tag.tagItems.forEach((tagOption, key) => {
          //         if (key === 0) {
          //           allTags += ' ' + tagOption
          //         } else {
          //           allTags += ', ' + tagOption
          //         }
          //       })
          //     }
          //     printLine(`${tag.tagName}: ${allTags}`, getOptions({ alignment: 0 }))
          //   })
          // }
        })

        if (menus[key].tags && menus[key].tags.length > 0) {
          menus[key].tags.forEach((tag, tagIndex) => {
            if (tag.tagItems && tag.tagItems.length > 0) {
              printLine(`    ${tag.tagName.toUpperCase()}`, options)

              tag.tagItems.forEach((tagOption) => {
                printLine('    ∙' + tagOption, options)
              })
            }
          })
        }
      }
    })
  }

  var printOtherUnitsArray = []
  if (otherUnits && otherUnits.length > 0) {
    printSeparator(options)
    otherUnits.forEach(unit => {
      if (unit.id !== orgUnit.id) {
        printOtherUnitsArray.push(unit.name)
      }
    })

    if (printOtherUnitsArray && printOtherUnitsArray.length > 0) {
      printLine('Ostatak narudžbe u:', getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 }))
      printOtherUnitsArray.forEach(unitName => {
        printLine(' - ' + unitName, getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 }))
      })
      printLine('\n'.repeat(3), options)
    }
  }

  if (note) {
    printSeparator(options)
    printLine(`- ${note.message}`, getOptions({ font: 0, bold: 1, vertical: 1, horizontal: 1 }))
  }

  printLine('\n'.repeat(5), options)
  /* TODO: remove */

  try {
    console.info(`Printing order on ${printerConfiguration.library}`)
    if (printerConfiguration.library === 'UNIVERSAL') {
      doc.feed(1).cut()
      var escposCommands = options.document.generateUInt8Array()

      // create ClientPrintJob
      var cpj = new JSPM.ClientPrintJob()

      // Set Printer info
      // const myPrinter = new JSPM.NetworkPrinter(9100, '172.29.35.88')

      const printer = printerConfiguration

      let selectedPrinter
      if (printer.ip_address && printer.ip_address !== '') {
        if (printer.port && printer.port !== 9100) {
          selectedPrinter = new JSPM.NetworkPrinter(printer.port, printer.ip_address)
        } else {
          selectedPrinter = new JSPM.NetworkPrinter(9100, printer.ip_address)
        }
      } else {
        selectedPrinter = new JSPM.InstalledPrinter(printer.printer_name)
      }

      cpj.clientPrinter = selectedPrinter

      // Set the ESC/POS commands
      cpj.binaryPrinterCommands = escposCommands

      // Send print job to printer!
      await cpj.sendToClient()
      return true
    } else if (state.getPrinterLib() === 'BIXOLON80') {
      cutPaper()
      var strSubmit = getPosData()
      requestPrint(printerConfiguration.printer_name, strSubmit, viewResult, printerConfiguration.ip_address)
    }
  } catch (err) {
    alert('Greška pri printanju narudžbe!')
    console.error(err)

    if (hideLoader) {
      hideLoader()
    }
  }

  return true
}
