<template>
  <v-card-text class="ma-0 pa-0">
    <v-list>
      <v-list-item class="pl-0" @click="setStep('main-account-window')">
        <v-icon large>mdi-arrow-left</v-icon>
        <span class="ml-2">{{$t('$vuetify.theme')}}</span>
      </v-list-item>
      <v-divider></v-divider>
      <div class="pl-14">
        <v-radio-group v-model="dark">
          <v-radio
            v-for="theme in themes"
            :key="theme.value"
            :value="theme.value"
            :label="$t(theme.name)"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-list>
  </v-card-text>
</template>
<script>
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
export default {
  mixins: [applicationSettings],
  data: function () {
    return {
      dark: false
    }
  },
  computed: {
    themes () {
      return [
        { name: '$vuetify.themes.light', value: false },
        { name: '$vuetify.themes.dark', value: true }
      ]
    }
  },
  watch: {
    dark (nv) {
      this.$vuetify.theme.dark = nv
      state.setDark(nv)
    }
  },
  mounted () {
    this.dark = this.$vuetify.theme.dark
  },
  methods: {
    setStep (step) {
      this.$emit('stepChange', step)
    }
  }
}
</script>
