<template>
  <v-card width="350" v-if="menu">
    <v-card-text>
      <v-window v-model="step">
        <v-window-item value="main-account-window">
          <main-account-view @stepChange="setStep"></main-account-view>
        </v-window-item>
        <v-window-item value="language">
          <language @stepChange="setStep"></language>
        </v-window-item>
        <v-window-item value="theme">
          <theme @stepChange="setStep"></theme>
        </v-window-item>
        <v-window-item value="general-settings">
          <general-settings @stepChange="setStep"></general-settings>
        </v-window-item>
        <v-window-item value="order-print-settings">
          <order-print-settings @stepChange="setStep"></order-print-settings>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>
<script>
import MainAccountView from '@/components/top-bar/components/account/steps/MainAccountView'
import Language from '@/components/top-bar/components/account/steps/Language'
import Theme from '@/components/top-bar/components/account/steps/Theme'
import GeneralSettings from '@/components/top-bar/components/account/steps/GeneralSettings'
import OrderPrintSettings from '@/components/top-bar/components/account/steps/OrderPrintSettings'

export default {
  name: 'GeneralOptions',
  components: { MainAccountView, Language, Theme, GeneralSettings, OrderPrintSettings },
  props: ['menu'],
  data: function () {
    return {
      step: 'main-account-window'
    }
  },
  watch: {
    menu (val) {
      if (!val) this.step = 'main-account-window'
    }
  },
  methods: {
    setStep (step) {
      this.step = step
    }
  }
}
</script>
