var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"z-index":"11","left":"0px","border-left-style":"hidden"},attrs:{"outlined":"","elevation":"1","elevate-on-scroll":"","clipped":"","fixed":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.setMini.apply(null, arguments)}}}),_c(_vm.content,{tag:"component"}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"ml-2"},[_c('v-btn-toggle',{attrs:{"dense":"","background-color":"primary"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.switchOnScreenKeyboard)?_c('v-btn',_vm._g({on:{"click":function($event){return _vm.switchKeyboard(true)}}},on),[_c('v-icon',[_vm._v("mdi-keyboard-outline")])],1):_c('v-btn',_vm._g({on:{"click":function($event){return _vm.switchKeyboard(false)}}},on),[_c('v-icon',[_vm._v("mdi-keyboard-off-outline")])],1)]}}],null,false,1140768256)},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.enableOsk")))])])],1)],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false,"offset-y":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","text":""}},'v-btn',attrs,false),on),[_c('v-avatar',_vm._g({staticStyle:{"font-size":"25px","color":"white"},attrs:{"size":"30","color":"#5ad0cf"}},onMenu),[_vm._v(_vm._s(_vm.avatar))])],1)]}}],null,true)},[_c('span',[_vm._v("Račun")])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('account',{attrs:{"menu":_vm.menu}})],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticStyle:{"padding":"5px 0px 0px 0px"}},[_c('HelpButton',{attrs:{"helpMode":_vm.getRoute()}})],1):_vm._e(),_c('euro-calc-dialog',{ref:"euroCalcDialog"}),_c('notification',{ref:"notification",attrs:{"showNotif":_vm.showNotif,"notification":_vm.importantNotif}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }