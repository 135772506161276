import { df } from '@/plugins/firebase'
import state from '@/state'

export const formatCurrencyFull = (currency, amount) => {
  return new Intl.NumberFormat('hr-HR', { style: 'currency', currency: currency }).format(amount)
}

export const formatCurrencyCustom = (currencySymbol, amount) => {
  return `${new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)} ${currencySymbol}`
}

export const formatCurrency = (currency, amount) => {
  const formatted = formatCurrencyFull(currency, amount)
  return formatted.replace(/kn|eur|€/gi, '').trimEnd()
}

export const replaceDiacritics = (str) => {
  const mapObj = { č: 'c', ć: 'c', ž: 'z', š: 's', đ: 'dj', Č: 'C', Ć: 'C', Ž: 'Z', Š: 'S', Đ: 'Dj' }
  const re = new RegExp(Object.keys(mapObj).join('|'), 'g')
  str = str.replace(re, function (matched) {
    return mapObj[matched]
  })
  return str
}

export const getCummulatedDiscount = (fullReceipt) => {
  let discount = 0
  if (fullReceipt.discount) {
    discount += fullReceipt.discount.amount / 100
  }
  fullReceipt.items.forEach(item => {
    if (item.discount) {
      discount += (item.discount.amount / 100) * (item.amt / 1000)
    }
  })
  return discount
}

export const getTotalDiscount = (data) => {
  if (data.discount) {
    return data.discount.amount / 100
  }
  return 0
}

export const getFullDiscount = (data) => {
  let sum = 0
  data.items.forEach(item => {
    if (item.discount) {
      sum = sum + (item.discount.amount / 100 * item.amt / 1000)
    }
  })
  if (data.discount) {
    sum = sum + (data.discount.amount / 100)
  }

  return sum
}

export const getDiscountForItem = (item) => {
  if (item.discount) {
    return `${item.discount.rate / 100}%`
  }
  return '0%'
}

export const getDiscountAmountForItem = (item) => {
  var currency = 'HRK'
  if (Date.now() / 1000 > 1672527599) {
    currency = 'EUR'
  }

  if (item.discount) {
    return formatCurrency(currency, item.discount.amount / 100)
  }
  return '0,00'
}

export const getDiscountedPriceForItem = (item) => {
  var currency = 'HRK'
  if (Date.now() / 1000 > 1672527599) {
    currency = 'EUR'
  }

  if (item.discounted_price) {
    return item.discounted_price / 100
  }
  if (item.prices && item.prices[currency] && item.prices[currency].price && item.prices[currency].price > 0) {
    return item.prices[currency].price / 100
  }

  if (item.price && item.price > 0) {
    return item.price / 100
  }
}

export const hasDiscount = (data) => {
  const hasGlobalDiscount = data.discount && data.discount.amount > 0
  const hasItemsDiscount = data.items.some(el => el.discount && el.discount.amount > 0)

  return hasGlobalDiscount || hasItemsDiscount
}

export const hasGlobalDiscount = (data) => {
  const hasGlobalDiscount = data.discount && data.discount.amount > 0
  return hasGlobalDiscount
}

export const hasRateDiscount = (data) => {
  const hasItemsDiscount = data.items.some(el => el.discount && el.discount.type === 'RATE')
  const hasGlobalDiscount = data.discount && data.discount.amount > 0 && data.discount.type === 'RATE'

  return hasGlobalDiscount || hasItemsDiscount
}

export const calculateTaxRecapitulation = (data, currency) => {
  const taxRecapitulation = {
    taxObj25: { name: 'PDV', rate: 2500, base: 0, total: 0 },
    taxObj13: { name: 'PDV', rate: 1300, base: 0, total: 0 },
    taxObj5: { name: 'PDV', rate: 500, base: 0, total: 0 }
  }

  if (data && data.discount && data.discount.name === 'Reprezentacija') {
    var hrkPeriod = true
    if (Date.now() / 1000 > 1672527599) {
      hrkPeriod = false
    }

    if (hrkPeriod === true) {
      taxRecapitulation.taxObj25.base = 1.6
      taxRecapitulation.taxObj25.total = 0.4
    } else {
      taxRecapitulation.taxObj25.base = 0.4
      taxRecapitulation.taxObj25.total = 0.1
    }
  } else {
    data.items.forEach((item) => {
      var price = item.prices[currency].price
      if (item.discount && item.discounted_price) {
        price = item.discounted_price
      }
      const itemPrice = price / 100
      const itemTaxRate = (item.prices[currency].taxes[0].rate / 10000 + 1)
      const basePrice = (itemPrice * item.quantity) / itemTaxRate
      const rate = (itemPrice * item.quantity) - basePrice

      switch (item.prices[currency].taxes[0].rate) {
        case 2500:
          taxRecapitulation.taxObj25.base += basePrice
          taxRecapitulation.taxObj25.total += rate
          break
        case 1300:
          taxRecapitulation.taxObj13.base += basePrice
          taxRecapitulation.taxObj13.total += rate
          break
        case 500:
          taxRecapitulation.taxObj5.base += basePrice
          taxRecapitulation.taxObj5.total += rate
          break
        default:
          break
      }
    })
  }

  // Micanje poreza ako je dodan popust na iznos
  if (data && data.discount && data.discount.amount > 0 && data.discount.name !== 'Reprezentacija') {
    let totalRemove = data.discount.amount

    if (taxRecapitulation.taxObj25.base > 0 && taxRecapitulation.taxObj25.total > 0) {
      let totalPrice = taxRecapitulation.taxObj25.base + taxRecapitulation.taxObj25.total

      if (totalPrice >= (1 + (taxRecapitulation.taxObj25.rate / 10000))) {
        let removedAllPossible = false
        let remove = totalRemove

        // kreni unazad, uzmi ukupni iznos za skidanje i probaj skinuti
        for (var j = totalRemove; j > 0 && removedAllPossible === false; j--) {
          // ako oduzmes iznos i on je veci od 100 na kraju i ustvari si skinuo sve, tu zavrsi
          if ((totalPrice - j) >= (1 + (taxRecapitulation.taxObj25.rate / 10000))) {
            removedAllPossible = true
            remove = j
          }
        }
        if (removedAllPossible === true) {
          totalPrice -= remove
          totalRemove -= remove
        }
      }

      taxRecapitulation.taxObj25.base = parseInt(totalPrice / (100 + (taxRecapitulation.taxObj25.rate / 100)))
      taxRecapitulation.taxObj25.total = totalPrice - taxRecapitulation.taxObj25.base
    }

    if (taxRecapitulation.taxObj13.base > 0 && taxRecapitulation.taxObj13.total > 0) {
      let totalPrice = taxRecapitulation.taxObj13.base + taxRecapitulation.taxObj13.total

      if (totalPrice >= (1 + (taxRecapitulation.taxObj13.rate / 10000))) {
        let removedAllPossible = false
        let remove = totalRemove

        // kreni unazad, uzmi ukupni iznos za skidanje i probaj skinuti
        for (j = totalRemove; j > 0 && removedAllPossible === false; j--) {
          // ako oduzmes iznos i on je veci od 100 na kraju i ustvari si skinuo sve, tu zavrsi
          if ((totalPrice - j) >= (1 + (taxRecapitulation.taxObj13.rate / 10000))) {
            removedAllPossible = true
            remove = j
          }
        }
        if (removedAllPossible === true) {
          totalPrice -= remove
          totalRemove -= remove
        }
      }

      taxRecapitulation.taxObj13.base = parseInt(totalPrice / (100 + (taxRecapitulation.taxObj13.rate / 100)))
      taxRecapitulation.taxObj13.total = totalPrice - taxRecapitulation.taxObj13.base
    }

    if (taxRecapitulation.taxObj5.base > 0 && taxRecapitulation.taxObj5.total > 0) {
      let totalPrice = taxRecapitulation.taxObj5.base + taxRecapitulation.taxObj5.total

      if (totalPrice >= (1 + (taxRecapitulation.taxObj5.rate / 10000))) {
        let removedAllPossible = false
        let remove = totalRemove

        // kreni unazad, uzmi ukupni iznos za skidanje i probaj skinuti
        for (j = totalRemove; j > 0 && removedAllPossible === false; j--) {
          // ako oduzmes iznos i on je veci od 100 na kraju i ustvari si skinuo sve, tu zavrsi
          if ((totalPrice - j) >= (1 + (taxRecapitulation.taxObj5.rate / 10000))) {
            removedAllPossible = true
            remove = j
          }
        }
        if (removedAllPossible === true) {
          totalPrice -= remove
          totalRemove -= remove
        }
      }

      taxRecapitulation.taxObj5.base = parseInt(totalPrice / (100 + (taxRecapitulation.taxObj5.rate / 100)))
      taxRecapitulation.taxObj5.total = totalPrice - taxRecapitulation.taxObj5.base
    }
  }

  return taxRecapitulation
}

export const getPosNote = async () => {
  const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
  if (register.data() && register.data().note_pos && register.data().note_pos.length > 0) {
    return register.data().note_pos
  } else {
    const pointOfSale = await df.doc(`locations/${state.getPointOfSale().id}`).get()
    if (pointOfSale.data().note_pos && pointOfSale.data().note_pos.length > 0) {
      return pointOfSale.data().note_pos
    } else {
      const company = await df.doc(`companies/${state.getCurrentCompany().id}`).get()
      if (company.data().note_pos && company.data().note_pos.length > 0) {
        return company.data().note_pos
      }
    }
  }
  return ''
}
