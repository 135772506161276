
import { df } from '@/plugins/firebase'
// import { auth, df } from '@/plugins/firebase'
import state from '@/state'
// import { v4 as uuidv4 } from 'uuid'
// import { dateTimeToUnixTimestamp, getPosNote } from '@/libs/receiptIssuing/commons'
// import { remapItems } from '@/libs/receiptIssuing/items'
// import { sendHttp, sendVirmanHttp } from '@/libs/receiptIssuing/payment'

export default {
  methods: {
    async getCashRegisterNumber (offer = false) {
      var cashRegister = await this.getCashRegister()

      if (!cashRegister) {
        return
      }

      if (offer) {
        return cashRegister.offer_sequence + 1
      } else {
        return cashRegister.bill_sequence + 1
      }
    },

    async getCashRegister () {
      if (state.getCashRegister() && state.getCashRegister().id && state.getCashRegister().id !== '') {
        const response = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
        if (!response.data()) {
          throw new Error('Nije moguće dohvatiti podatke o blagajni')
        } else {
          return response.data()
        }
      }
    }

    // getDefaultUnit (warehouseUnits) {
    //   const defaultunit = warehouseUnits.default_sale
    //   const unitId = Object.keys(warehouseUnits.units).find((key) => {
    //     return key === defaultunit
    //   })
    //   if (!unitId) {
    //     console.error('Default key is not defined')
    //   }
    //   return unitId
    // },

    // async payReceipt () {
    //   const method = this.paymentType.value

    //   this.cart.forEach(it => {
    //     it.data.quantity = it.quantity
    //   })

    //   const formattedItems = this.cart.map(i => {
    //     return i.data
    //   })

    //   const data = {
    //     currency: this.currency,
    //     fullTotal: this.total * 100,
    //     items: formattedItems,
    //     paymentConfig: {},
    //     registerType: this.cashRegisterType,
    //     title: '',
    //     total: this.total * 100,
    //     totalShoppingCart: 0,
    //     totalWithDiscount: 0,
    //     type: method
    //   }

    //   const remappedItems = await remapItems(data, method)

    //   var userID = auth.currentUser.uid
    //   if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
    //     userID = state.getCashRegisterUser().id
    //   }

    //   const args = {
    //     entity: 'receipt',
    //     reqId: uuidv4(),
    //     seller_id: userID,
    //     currency: this.currency,
    //     paymentMethod: 'NOVČANICE',
    //     language: {
    //       name: 'Hrvatski',
    //       value: 'hr'
    //     },
    //     type: {
    //       flag: 'BO'
    //     },
    //     posNote: await getPosNote(state),
    //     fiscalize: true
    //   }

    //   const httpArgs = {
    //     issueDateTime: dateTimeToUnixTimestamp(new Date()),
    //     paymentMeth: args.paymentMethod,
    //     language: args.language,
    //     type: args.type,
    //     openPdfPrint: this.openPdfPrint,
    //     receiptNumber: this.cashRegisterNumber
    //   }

    //   const items = remappedItems

    //   const payload = this.getPayload(args, items)

    //   httpArgs.msgBox = this.showMsgBox
    //   httpArgs.confirm = this.confirm
    //   httpArgs.confirmClose = this.confirmClose
    //   httpArgs.hideLoader = this.hideLoader

    //   // if (data.discount !== undefined) {
    //   //   payload.action.params.discount = data.discount
    //   // }

    //   data.fullTotal = Math.round(data.fullTotal)
    //   data.total = Math.round(data.total)

    //   if (data.fullTotal !== undefined && data.fullTotal > 0) {
    //     payload.action.params.full_total = data.fullTotal
    //   } else {
    //     payload.action.params.full_total = data.total
    //   }

    //   if (data.total !== undefined && data.total > 0) {
    //     payload.action.params.total = data.total
    //   }

    //   payload.action.params.number = this.cashRegisterNumber
    //   payload.action.params.full_total = this.total * 100
    //   payload.action.params.total = this.total * 100

    //   if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
    //     payload.action.params.print_size = state.getPrinter().value
    //   } else {
    //     payload.action.params.print_size = 'BIXOLON80'
    //   }

    //   if (args.entity === 'virman') {
    //     sendVirmanHttp(data, items, payload, httpArgs, this.selectedPrinter)
    //   } else {
    //     sendHttp(data, items, payload, httpArgs, this.selectedPrinter)
    //   }

    //   this.close()
    // },

    // getPayload (args, items) {
    //   return {
    //     action: {
    //       operation: 'set',
    //       entity: args.entity,
    //       params: {
    //         company_id: state.getCurrentCompany().id,
    //         location_id: state.getPointOfSale().id,
    //         register_id: state.getCashRegister().id,
    //         issue_date_time: dateTimeToUnixTimestamp(new Date()),
    //         payment_method: args.paymentMethod,
    //         seller_oib: '',
    //         currency: args.currency,
    //         payment_reference: '',
    //         language: args.language.value,
    //         type: args.type,
    //         items: items,
    //         note: args.posNote,
    //         fiscalize: args.fiscalize
    //       }
    //     }
    //   }
    // }
  }
}
