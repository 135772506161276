<template>
  <div>
    <v-card-text class="ma-0 pa-0">
      <v-list>
        <v-list-item class="pl-0" @click="setStep('main-account-window')">
          <v-icon large>mdi-arrow-left</v-icon>
          <span class="ml-2">Opcije aplikacije</span>
        </v-list-item>
        <v-divider></v-divider>
        <div class="pl-5 pt-3">
          <span class="text-overline font-weight-bold">Gustoća</span>
          <v-radio-group v-model="dens" class="mt-0">
            <v-radio
              v-for="density in densities"
              :key="density.value"
              :value="density.value"
              :label="density.name"
            ></v-radio>
          </v-radio-group>
        </div>
        <div class="pl-5 pt-3">
          <span class="text-overline font-weight-bold">Printer</span>
          <v-select
            class="pb-3"
            outlined
            dense
            label="Odaberite printer"
            :items="printers"
            item-text="name"
            item-value="id"
            return-object
            hide-details
            v-model="print"
          ></v-select>
        </div>
        <div class="pl-5 pt-3">
          <span class="text-overline font-weight-bold">Postavke tražilice</span>
          <v-select
            class="pb-3"
            outlined
            dense
            label="Odaberite ponašanje"
            :items="searchTypes"
            item-text="name"
            item-value="id"
            hide-details
            v-model="searchType"
            @change="changeSearchType()"
          ></v-select>
        </div>
        <!-- <div class="pl-5 pt-3">
          <span class="text-overline font-weight-bold">Printer</span>
          <v-radio-group v-model="print" class="mt-0">
            <v-radio
              v-for="printer in printers"
              :key="printer.id"
              :value="printer"
              :label="printer.name"
            ></v-radio>
          </v-radio-group>
        </div> -->
      </v-list>
    </v-card-text>
  </div>
</template>

<script>
/* eslint-disable */
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import { df } from '@/plugins/firebase'
import applicationSettings from '@/mixins/applicationSettings'
import { printerInit } from '@/libs/bixolon/printer'
import { initPrintManager } from '@/libs/jsPrintManager/utils'

export default {
  mixins: [applicationSettings],
  data: function () {
    return {
      dens: undefined,
      print: undefined,
      registerPrinter: null,
      printers: [],
      searchTypes: [
        {name: 'Otvara se na klik', id: true},
        {name: 'Uvijek otvorena', id: false},
      ],
      searchType: undefined,
      selectedPrinter: {}
    }
  },
  computed: {
    densities () {
      return [
        { name: 'Velika', value: 'greater' },
        { name: 'Srednja', value: 'normal' },
        { name: 'Mala', value: 'smaller' }
      ]
    }
  },
  watch: {
    dens (nv) {
      state.setDensity(nv)
    },
    print (nv, ov) {
      if (nv !== ov) {
        if (!nv.library || nv.library === 'BIXOLON') {
          state.setPrinterLib('BIXOLON80')
          const issueID = 1
          printerInit(issueID)
          state.setPosPrinterSettings(2)
        } else if (nv.library === 'UNIVERSAL') {
          state.setPrinterLib('UNIVERSAL')
          state.setPosPrinterSettings('A')
          initPrintManager()
        } else {
          state.setPrinterLib('NONE')
        }
        state.setSelectedPrinter(nv)
        EventBus.$emit('set-selected-printer', nv)
      }
    }
  },
  methods: {
    setStep (step) {
      this.$emit('stepChange', step)
    },
    changeSearchType(){
      state.setSearchType(this.searchType)
    }
  },

  mounted () {
    this.searchType = state.getSearchType()
    this.printers = state.getInstalledPrinters()
    this.selectedPrinter = state.getSelectedPrinter()
    this.dens = state.getDensity()
    const print = this.printers[this.printers.findIndex(i => i.id === this.selectedPrinter.id)]
    this.print = print
    this.$forceUpdate()
  }
}
</script>
