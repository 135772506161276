import state from '@/state'
import { df } from '@/plugins/firebase'
import { printOrder } from '@/libs/bixolon/order'

export default {
  methods: {
    tables () {
      var printers = this.getPrinters()
      var orgUnits = this.getOrgUnits()
      var listeners = []
      const query = df.collection(`location_orders/${state.getOrderPrintLocation().id}/tables`).orderBy('number')
      const listener = query.onSnapshot((doc) => {
        if (doc.metadata.fromCache) return

        doc.docChanges().forEach((change) => {
          var table = change.doc.data()
          console.log(change)
          var items = this.getItems(table)
          var otherUnits = this.checkOtherOrgUnits(items, orgUnits)
          this.groupPrint(items, printers, orgUnits, table, otherUnits)
        })
      })

      listeners.push(listener)
    },
    getItems (table) {
      var itemsInOrgUnits = []
      var order = table.orders[table.orders.length - 1]
      if (order && order.order_items && order.order_items.length > 0) {
        order.order_items.forEach(item => {
          if (item.organizational_unit_id && item.organizational_unit_id !== '') {
            if (!itemsInOrgUnits[item.organizational_unit_id]) {
              itemsInOrgUnits[item.organizational_unit_id] = []
            }
            itemsInOrgUnits[item.organizational_unit_id].push(item)
          }
        })

        return itemsInOrgUnits
      }
    },
    getPrinters () {
      this.printers = []
      var query = df.collection(`location_printers/${state.getOrderPrintLocation().id}/printers`)
      query.onSnapshot((doc) => {
        doc.docs.forEach((rec) => {
          this.printers.push(rec.data())
        })
      })

      return this.printers
    },
    getOrgUnits () {
      this.orgUnits = []
      const query = df.collection(`location_units/${state.getOrderPrintLocation().id}/units`)
      query.onSnapshot((doc) => {
        doc.docs.forEach((rec) => {
          this.orgUnits.push(rec.data())
        })
      })
      return this.orgUnits
    },
    groupPrint (itemsInOrgUnits, printers, orgUnits, table, otherUnits) {
      Object.keys(itemsInOrgUnits).forEach(unitId => {
        orgUnits.forEach(orgUnit => {
          if (orgUnit.id === unitId) {
            printers.forEach(printer => {
              if (printer.id === orgUnit.printer || printer.name === orgUnit.printer) {
                this.callPrinter(itemsInOrgUnits[unitId], printer, orgUnit, table, table.orders[table.orders.length - 1], otherUnits)
              }
            })
          }
        })
      })
    },
    checkOtherOrgUnits (itemsInOrgUnits, orgUnits) {
      var otherUnits = []
      Object.keys(itemsInOrgUnits).forEach(unitId => {
        orgUnits.forEach(orgUnit => {
          if (orgUnit.id === unitId) {
            otherUnits.push(orgUnit)
          }
        })
      })

      return otherUnits
    },
    callPrinter (items, printer, orgUnit, table, order, otherUnits) {
      let note = null
      if (order.notes) {
        note = order.notes.find(n => n.unitId === orgUnit.id)
      }

      if (order.order_status === 'ORDERED') {
        state.setPosPrinterSettings(2)
        printOrder(items, printer, table, order, orgUnit, otherUnits, note, this.hideLoader)
        this.updateOrderPrinted(table, order)
      }
    },
    updateOrderPrinted (table, order) {
      table.orders.forEach((or, key) => {
        if (order.id === or.id) {
          table.orders[key].order_status = 'PRINTED'
        }
      })

      df.doc(`location_orders/${state.getOrderPrintLocation().id}/tables/${table.id}`).update({ orders: table.orders })
        .catch((err) => {
          console.error(err)
          this.showMsgBox({ text: `Pogreška prilikom promjene status narudžbe: ${err}`, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
      console.log(table)
    }
  }

}
