<template>
  <div>
    <v-card-text class="ma-0 pa-0">
      <v-list>
        <v-list-item class="pl-0" @click="setStep('main-account-window')">
          <v-icon large>mdi-arrow-left</v-icon>
          <span class="ml-2">Postavke printanja narudžbi</span>
        </v-list-item>
        <v-divider></v-divider>
        <div class="pl-5 pt-3">
          <span class="text-overline font-weight-bold"
            >Printaj narudžbe sa ovog prodajnog mjesta</span
          >
          <v-select
            class="pb-3"
            outlined
            dense
            label="Odaberite prodajno mjesto"
            :items="locations"
            item-text="name"
            item-value="id"
            return-object
            hide-details
            v-model="location"
          ></v-select>
        </div>
      </v-list>
    </v-card-text>
  </div>
</template>

<script>
/* eslint-disable */
import state from '@/state'
import { auth, df } from '@/plugins/firebase'
import applicationSettings from '@/mixins/applicationSettings'
import tables from '@/mixins/tables'


export default {
  mixins: [applicationSettings, tables],
  data: function () {
    return {
      dens: undefined,
      location: undefined,
      registerPrinter: null,
      locations: [],
      searchType: undefined,
      selectedPrinter: {}
    }
  },
  computed: {
  },
  watch: {
    dens (nv) {
      state.setDensity(nv)
    },
    location (nv, ov) {
      if (nv !== ov) {
        state.setOrderPrintLocation(nv)
        this.tables()
      }
    }
  },
  methods: {
    setStep (step) {
      this.$emit('stepChange', step)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
    }
  },

  mounted () {
    if (state.getOrderPrintLocation().id !== ''){
      this.location = state.getOrderPrintLocation()
    }
    this.getPointsOfSale()
    this.$forceUpdate()
  }
}
</script>
