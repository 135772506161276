import { requestPrint } from '@/libs/bixolon/bxlcommon'
import { df } from '@/plugins/firebase'
import state from '@/state'
import { getPosNote, formatCurrency, formatCurrencyCustom, getCummulatedDiscount } from '@/libs/bixolon/helpers'
import { cutPaper, getPosData, printQRCode } from '@/libs/bixolon/bxlpos'
import { printLine, printMultipleShort, printSeparator, printTotals } from '@/libs/bixolon/printer'
import EventBus from '@/plugins/event-bus'
import { printerErrors } from '@/libs/jsPrintManager/utils'
import * as JSPM from 'jsprintmanager'

export const print58 = async (printer, data, mode) => {
  if (!['UNIVERSAL', 'NONE'].includes(state.getPrinterLib())) {
    if (!printer.font) printer.font = 0
    if (!printer.size) printer.size = 0
    if (!printer.width) printer.width = '80'
    if (!printer.line_length) printer.line_length = 48
    if (!printer.library) printer.library = 'BIXOLON'
  }

  let [escpos, doc, printerConfiguration] = [null, null, null]

  const cashRegisterResponse = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
  const cashRegister = cashRegisterResponse.data()
  const currencySymbol = printer.euro_symbol_type ?? '€'

  printerConfiguration = printer

  if (state.getPrinterLib() === 'UNIVERSAL') {
    escpos = window.Neodynamic.JSESCPOSBuilder
    doc = new escpos.Document()
  }

  const currency = 'EUR'

  const posNote = await getPosNote()

  const company = state.getCurrentCompany()
  const pointOfSale = state.getPointOfSale()

  // const conversionRate = 7.53450
  // const conversionRateString = '7.53450'
  const paymentMethod = data.payment_method

  const companyAddress = `${company.address}, ${company.zip_code} ${company.city}`
  const pointOfSaleAddress = `${pointOfSale.address}, ${pointOfSale.zip_code} ${pointOfSale.city}`

  const total = data.total / 100
  const fullTotal = data.full_total / 100
  const totalWithDiscount = data.totalWithDiscount ? data.totalWithDiscount : total

  const [zki, jir] = [data.zki, data.jir]

  const options = {
    horizontal: 0,
    vertical: 0,
    bold: false,
    invert: false,
    underline: false,
    alignment: 0,
    printerConfiguration: printerConfiguration,
    document: doc,
    escpos: escpos
  }

  if (state.getPrinterLib === 'UNIVERSAL') {
    options.font = printerConfiguration.font
  } else {
    if (printerConfiguration.font === 'A') options.font = 0
    else if (printerConfiguration.font === 'B') options.font = 1
    else if (printerConfiguration.font === 'C') options.font = 2
  }

  function getOptions (option) {
    const modifiedOptions = JSON.parse(JSON.stringify(options))
    Object.keys(option).forEach(key => {
      modifiedOptions[key] = option[key]
    })
    modifiedOptions.document = options.document
    modifiedOptions.escpos = options.escpos
    modifiedOptions.printerConfiguration = options.printerConfiguration
    return modifiedOptions
  }
  var date = new Date(data.issue_date_time * 1000)

  printLine(company.name, getOptions({ alignment: 1 }))
  printLine(companyAddress + ', ' + company.country, getOptions({ alignment: 1 }))
  printLine(pointOfSale.name, getOptions({ alignment: 1 }))
  printLine(pointOfSaleAddress, getOptions({ alignment: 1 }))
  printLine(`OIB: ${company.oib}`, getOptions({ alignment: 1 }))
  if (mode === 0) { printLine('*** REPRINT ***', getOptions({ bold: true, alignment: 1, horizontal: 1, vertical: 1 })) }
  printSeparator(options)

  let rcptSeparator = '-'
  if (data.company_details.rcpt_separator !== undefined && data.company_details.rcpt_separator !== '') {
    rcptSeparator = data.company_details.rcpt_separator
  }

  if (data.designation) {
    data.receiptNumber = `${data.designation.number}${rcptSeparator}${data.designation.location_id}${rcptSeparator}${data.designation.register_number}`
  }

  if (data.company_details.rcpt_num_year === true) {
    var d = new Date(data.issue_date_time * 1000)

    data.receiptNumber += `  ${d.getFullYear()} `
  }

  const operater = data.seller_id && data.seller_id !== '' ? data.seller_id : data.seller_name
  const datum = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
  const vrijeme = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)

  printTotals('Račun br:', data.receiptNumber, options)
  printTotals('Način plaćanja:', paymentMethod, options)
  printTotals('Operater:', operater, options)
  printTotals('Blagajna:', cashRegister ? String(cashRegister.number) : 'X', options)
  printTotals('Datum: ', datum, options)
  printTotals('Vrijeme: ', vrijeme, options)

  printSeparator(options)

  const type = data.type
  if (type.payer_oib) {
    const customerAddress = `${type.payer_address}, ${type.payer_zip_code} ${type.payer_city}`

    printLine('Kupac', options)
    printLine(`Naziv: ${type.payer_name.toUpperCase()}`, options)
    printLine(`Adresa: ${customerAddress.toUpperCase()}`, options)
    printLine(`Oib: ${type.payer_oib.toUpperCase()}`, options)

    printSeparator(options)
  }
  const lineLength = printerConfiguration.line_length

  printLine('Artikl', options)
  printMultipleShort(['Cijena', 'Kol', 'Ukupno'], 2, options)

  if (mode === 0 || mode === 2) {
    data.items.forEach(item => {
      printLine(`${item.name.toUpperCase()}`.substring(0, lineLength), getOptions({ bold: true }))
      printMultipleShort([
        formatCurrency(currency, item.single_item_price / 100),
        formatCurrency(currency, item.amt / 1000),
        formatCurrency(currency, (item.amt / 1000) * (item.single_item_price / 100))
      ], 2, options)
      if (item.discount) {
        printMultipleShort([
          'Popust:',
          ' ',
          formatCurrency(currency, ((item.amt / 1000) * (item.single_item_price / 100)) - item.price / 100)
        ], 2, options)
      }
      // }
    })
  } else {
    if (mode === 1) {
      printLine('Konzumacija jela i pića', options)
      printMultipleShort([
        formatCurrency(currency, data.total / 100),
        formatCurrency(currency, 1),
        formatCurrency(currency, data.total / 100)
      ], 2, options)
    }
  }

  printSeparator(options)

  printMultipleShort([
    'Vrsta',
    'Stopa',
    'Osnovica',
    'Ukupno'
  ], 4, options)

  data.pdv.forEach((item) => {
    switch (item.rate) {
      case 0:
        printMultipleShort([
          'PDV 0%',
          '0%',
          formatCurrency(currency, item.base / 100),
          formatCurrency(currency, 0)
        ], 4, options)
        break

      default:
        printMultipleShort([
          'PDV ' + `${item.rate / 100}% `,
          `${item.rate / 100}% `,
          formatCurrency(currency, item.base / 100),
          formatCurrency(currency, item.total / 100)
        ], 4, options)
    }
  })

  if (data.pnp && data.pnp !== null) {
    var pnp = data.pnp
    printMultipleShort([
      'PNP ' + `${pnp.rate / 100} % `,
      `${pnp.rate / 100} % `,
      formatCurrency(currency, pnp.base / 100),
      formatCurrency(currency, pnp.total / 100)
    ], 4, options)
  }

  printSeparator(options)

  const cummulatedDiscount = getCummulatedDiscount(data)

  if (data.discount && data.discount.type && data.discount.name && data.discount.name === 'Reprezentacija') {
    printTotals('Popust - reprezentacija', formatCurrencyCustom(currencySymbol, data.discount.amount / 100), options)
  } else {
    if (cummulatedDiscount > 0) {
      printTotals('Popust na iznos', formatCurrencyCustom(currencySymbol, data.discount.amount / 100), options)
    }
  }
  if (cummulatedDiscount > 0) {
    printTotals('Cij. bez pop.', formatCurrencyCustom(currencySymbol, fullTotal), options)
    printTotals('Ukupni popust', formatCurrencyCustom(currencySymbol, cummulatedDiscount), options)
  }

  printTotals('Ukupno', formatCurrencyCustom(currencySymbol, total), getOptions({ bold: false, vertical: 0, horizontal: 1 }))

  // if (company.print_eur === undefined || company.print_eur === null || company.print_eur === true) {
  //   var exchangeCurrency = ''
  //   var conversionOperation = ''
  //   switch (currency) {
  //     case 'HRK':
  //       exchangeCurrency = 'EUR'
  //       conversionOperation = 'divide'
  //       break
  //     case 'EUR':
  //       exchangeCurrency = 'HRK'
  //       conversionOperation = 'multiply'
  //       break
  //   }

  //   var exchangeAmount = 0
  //   switch (conversionOperation) {
  //     case 'multiply':
  //       exchangeAmount = total * conversionRate
  //       break
  //     case 'divide':
  //       exchangeAmount = total / conversionRate
  //       break
  //   }

  // printTotals('', formatCurrencyFull(exchangeCurrency, exchangeAmount), getOptions({ bold: false, vertical: 0, horizontal: 1 })) // printLine('\n'.repeat(1), options)
  // printTotals('Tečaj', `1 ${currencySymbol} = ${conversionRateString}`, options)
  // }

  printSeparator(options)

  if (zki || jir) {
    generateQrCode(zki, jir, totalWithDiscount, options, date)
  }
  if (zki && zki !== '') {
    printLine('ZKI:', getOptions({ alignment: 0 }))
    printLine(zki, getOptions({ alignment: 0 }))
  }
  if (jir && jir !== '') {
    printLine('JIR:', getOptions({ alignment: 0 }))
    printLine(jir, getOptions({ alignment: 0 }))
  }

  printSeparator(options)
  printLine(posNote, getOptions({ alignment: 1 }))
  printLine('\n'.repeat(3), options)

  if (state.getPrinterLib() === 'UNIVERSAL') {
    doc.feed(1).cut()
    var escposCommands = options.document.generateUInt8Array()

    var cpj = new JSPM.ClientPrintJob()
    let selectedPrinter
    if (printerConfiguration.ip_address) {
      if (printerConfiguration.port) {
        selectedPrinter = new JSPM.NetworkPrinter(printerConfiguration.port, printerConfiguration.ip_address)
      } else {
        selectedPrinter = new JSPM.NetworkPrinter(9100, printerConfiguration.ip_address)
      }
    } else {
      selectedPrinter = new JSPM.InstalledPrinter(printerConfiguration.printer_name)
    }

    cpj.clientPrinter = selectedPrinter

    cpj.binaryPrinterCommands = escposCommands

    EventBus.$emit('clear-receipt-data')
    try {
      await cpj.sendToClient()
    } catch (err) {
      printerErrors('JSPM_not_running')
    }

    return true
  } else if (state.getPrinterLib() === 'BIXOLON80') {
    cutPaper()
    var strSubmit = getPosData()

    printSeparator(options)

    let printerUrl, pName
    if (printerConfiguration && printerConfiguration.ip_address && printerConfiguration.ip_address !== '') {
      printerUrl = printerConfiguration.ip_address
      pName = printerConfiguration.printer_name
    }

    try {
      requestPrint(pName, strSubmit, viewResult, printerUrl)
    } catch (err) {
      alert('Greška pri reprintu računa, molimo pokušajte ponovno!')
      EventBus.$emit('clear-receipt-data')

      console.error(err)
    } finally {
      EventBus.$emit('clear-receipt-data')
    }

    return true
  }
}

const generateQrCode = (zki, jir, amt, options, date) => {
  if ((zki === '') && (jir === '')) return
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let minute = date.getMinutes()

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute
  const formattedDate = `${year}${month}${day}_${hour}${minute}`

  amt *= 100
  amt = Math.round(amt * 100) / 100

  let rawUri = `https://porezna.gov.hr/rn/?datv=${formattedDate}&izn=${amt}`
  if (jir !== '') {
    rawUri = rawUri + `&jir=${jir}`
  }
  if (zki !== '') {
    rawUri = rawUri + `&zki=${zki}`
  }
  // 'https://porezna.gov.hr/rn/?datv=20220719_1521&izn=104%2C00&jir=d3cfcc2f-7f8b-444f-9622-1bd2c10b51e8'
  const uri = encodeURI(rawUri)

  if (options.document) {
    options.document.qrCode(uri, new options.escpos.BarcodeQROptions(options.escpos.QRLevel.L, 5))
  } else {
    printQRCode(uri, 0, 1, 4, 0)
  }
}

const viewResult = (result) => {
  if (result === 'Cannot connect to server') {
    alert('Pisač je nedostupan.')
  }
}
