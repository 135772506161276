<template>
  <v-container fluid class="fill-height pa-0" ref="main">
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        v-if="dialog"
        content-class="my-custom-dialog"
        persistent
        max-width="60%"
        style="overflow-y: auto; border-radius: 20px !important"
        :scrollable="true"
      >
        <v-overlay
          v-if="loading"
          z-index="1000"
          :absolute="absolute"
          :opacity="opacity"
          style="height: 100%; width: 100%; opacity: 1 !important"
        >
          <v-progress-circular
            :size="100"
            :width="10"
            style="color: #04aa6d; z-index: 1000"
            indeterminate
            >Pričekajte</v-progress-circular
          >
        </v-overlay>

        <!-- <v-overlay v-if="loading" absolute>
          <v-progress-circular
            :size="90"
            :absolute="absolute"
            :opacity="opacity"
            :width="10"
            style="color: #04aa6d; z-index: 1000"
            indeterminate
            >Pričekajte</v-progress-circular
          >
        </v-overlay> -->
        <v-card>
          <v-card-title
            style="background-color: #1577da; font-weight: bold; color: white"
          >
            <span class="text-h5" style="font-weight: bold">Novi račun</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-container class="pb-0">
              <v-row dense>
                <v-col class="pb-4" cols="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="receiptNumber"
                    label="Broj računa"
                    readonly
                    :class="
                      this.location && this.cashRegister
                        ? 'normal'
                        : 'attention'
                    "
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col class="pb-4" cols="3">
                  <v-select
                    outlined
                    dense
                    v-model="location"
                    :items="locations"
                    label="Prodajno mjesto"
                    item-text="name"
                    item-value="id"
                    return-object
                    :disabled="locationDisabled"
                    @change="onSelectLocation"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col class="pb-4" cols="3">
                  <v-select
                    outlined
                    dense
                    v-model="cashRegister"
                    :items="cashRegisters"
                    label="Blagajna"
                    item-text="text"
                    item-value="id"
                    return-object
                    @change="onSelectRegister"
                    hide-details
                  >
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    outlined
                    dense
                    v-model="paymentType"
                    :items="paymentTypes"
                    label="Način plaćanja"
                    item-text="name"
                    item-value="value"
                    required
                    return-object
                    @change="onSelectPaymentType"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>

              <div
                v-for="(item, counter) in cart"
                class="pa-3"
                v-bind:key="counter"
                style="
                  background-color: aliceblue;
                  border-style: solid;
                  margin-bottom: 10px;
                "
              >
                <v-row>
                  <v-col cols="1" class="pt-2 pl-6"
                    ><span
                      style="
                        font-size: 20px !important;
                        font-weight: bold !important;
                      "
                      >{{ counter + 1 }}.</span
                    ></v-col
                  >
                  <v-spacer></v-spacer>
                  <v-col cols="1" class="pr-4 pb-0 pt-0" align="right">
                    <v-btn icon @click="removeCartItem(counter)">
                      <v-icon color="red" medium>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-combobox
                      outlined
                      dense
                      v-model="selectedItems[counter]"
                      :items="items"
                      :loading="isLoading"
                      @input="selectItem(counter)"
                      hide-no-data
                      hide-selected
                      item-text="data.name"
                      item-value="data.id"
                      label="Artikl"
                      placeholder="Pocnite tipkati za pretragu"
                      :disabled="!cashRegister"
                      return-object
                      append-icon=""
                    >
                      <template v-slot:item="{ item }">
                        <template>
                          <v-list-item-avatar>
                            <img
                              v-if="item.data.picture"
                              :src="item.data.picture"
                            />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                item.data.name.toUpperCase()
                              }}</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              getCategory(item)
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ showItemPrice(item) }}
                          </v-list-item-action>
                        </template>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row dense style="margin-top: -1rem">
                  <v-col cols="3">
                    <v-combobox
                      dense
                      outlined
                      v-model="cart[counter].unit"
                      :items="cart[counter].units"
                      label="Jed. mjere"
                      item-text="storageUnit"
                      item-value="price[currency].price"
                      return-object
                      @change="updateCartItem(counter)"
                      :disabled="!cashRegister"
                      hide-details
                    ></v-combobox>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      dense
                      outlined
                      v-model="cart[counter].tax"
                      :label="$t('$vuetify.taxName')"
                      :items="taxes"
                      item-text="name"
                      item-value="rate"
                      @change="updateCartItem(counter)"
                      :disabled="!cashRegister"
                      return-object
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      outlined
                      dense
                      v-model="cart[counter].formattedQuantity"
                      hide-spin-buttons
                      type="number"
                      label="Količina"
                      suffix="x"
                      min="0"
                      @change="updateQuantity(counter)"
                      :disabled="!cashRegister"
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      hide-spin-buttons
                      v-model="cart[counter].formattedPrice"
                      value="1"
                      label="Cijena"
                      min="0"
                      @change="updatePrice(counter)"
                      :disabled="!cashRegister"
                      :suffix="currencySymbol"
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      dense
                      outlined
                      hide-spin-buttons
                      v-model="cart[counter].formattedDiscount"
                      type="number"
                      label="Popust"
                      suffix="%"
                      min="0"
                      @change="updateDiscount(counter)"
                      :disabled="!cashRegister"
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-if="cart[counter].data.freeItem === true"
                  class="pt-0 mt-0"
                >
                  <v-col
                    cols="12"
                    class="d-flex pt-2 pb-2 mt-0 py-0 justify-end"
                  >
                    <v-radio-group
                      class="pt-0 mt-0 small-radio"
                      row
                      v-model="cart[counter].data.type"
                      :rules="[(v) => !!v || 'Molimo odaberite']"
                      required
                      hide-details
                      dense
                    >
                      <v-radio
                        v-for="type in itemTypes"
                        :key="type.value"
                        :value="type.value"
                        :label="type.name"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
              <div class="d-flex mb-3 totals-row">
                <div>
                  <v-btn
                    id="plusBtn"
                    :disabled="canAddNewItem() || !cashRegister"
                    color="warning"
                    style="font-size: 2rem"
                    @click="addEmptyCartItem()"
                  >
                    +
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div align="right">
                  <span
                    ref="formatted_total"
                    class="total-price"
                    v-html="showTotal()"
                  ></span>
                </div>
                <div>
                  <v-select
                    v-model="selectedCurrency"
                    class="curreny-selector"
                    :items="currencies"
                    outlined
                    dense
                    hide-details
                  >
                  </v-select>
                </div>
              </div>
              <v-row dense>
                <v-col class="pb-3" cols="3">
                  <label class="datepickerLabel">{{ invoiceDateLabel }}</label>
                  <date-picker
                    :clearable="false"
                    prefix-class="xmx"
                    :append-to-body="false"
                    class="datetimepicker"
                    :disabled="datePickersDisabled"
                    v-model="invoiceDate"
                    type="datetime"
                    valueType="timestamp"
                    :placeholder="invoiceDateLabel"
                    format="DD.MM.YYYY HH:mm:ss"
                    :default-value="new Date()"
                    :popup-style="{
                      top: '-274px',
                    }"
                  ></date-picker>
                </v-col>
                <v-col class="pb-3" cols="3">
                  <label class="datepickerLabel">{{ valueDateLabel }}</label>
                  <date-picker
                    :clearable="false"
                    prefix-class="xmx"
                    :append-to-body="false"
                    class="datetimepicker"
                    :disabled="datePickersDisabled"
                    v-model="valueDate"
                    type="datetime"
                    valueType="timestamp"
                    :placeholder="valueDateLabel"
                    format="DD.MM.YYYY HH:mm:ss"
                    :default-value="new Date()"
                    :popup-style="{
                      top: '-274px',
                    }"
                  ></date-picker> </v-col
                ><v-col class="pb-3" cols="3">
                  <label class="datepickerLabel">{{ deliveryDateLabel }}</label>
                  <date-picker
                    :clearable="false"
                    prefix-class="xmx"
                    :append-to-body="false"
                    class="datetimepicker"
                    :disabled="datePickersDisabled"
                    v-model="deliveryDate"
                    type="datetime"
                    valueType="timestamp"
                    :placeholder="deliveryDateLabel"
                    format="DD.MM.YYYY HH:mm:ss"
                    :default-value="new Date()"
                    :popup-style="{
                      top: '-274px',
                    }"
                  ></date-picker>
                </v-col>
                <v-col class="pb-3" cols="3">
                  <label class="datepickerLabel">Jezik računa</label>
                  <v-select
                    style="max-height: 34px !important"
                    v-model="selectedLanguage"
                    :items="languages"
                    outlined
                    dense
                    item-text="name"
                    item-value="value"
                    required
                    return-object
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-expansion-panels
                style="margin-bottom: 10px"
                v-model="associatesPanel"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Dodaj podatke o kupcu
                    <template v-slot:actions>
                      <v-icon> mdi-account-multiple-outline </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense style="margin-top: -1rem">
                      <v-col cols="4" sm="4" md="4">
                        <v-combobox
                          label="Kupac"
                          outlined
                          dense
                          autofocus
                          :items="associates"
                          v-model="associateByName"
                          item-text="name"
                          item-value="text"
                          :disabled="!cashRegister"
                          @change="onSelectAssociate"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="4" sm="4" md="4">
                        <v-text-field
                          v-model="associateAddress"
                          label="Adresa"
                          outlined
                          dense
                          persistent-hint
                          :disabled="!cashRegister"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" sm="4" md="4">
                        <v-combobox
                          label="OIB"
                          outlined
                          dense
                          :items="associates"
                          v-model="associateByOib"
                          item-text="oibs[0]"
                          item-value="text"
                          :disabled="!cashRegister"
                          @change="onSelectAssociateOib"
                          @keyup.enter="onSelectAssociateOib"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense style="margin-top: -1.3rem">
                      <v-col cols="4" sm="4" md="4">
                        <v-text-field
                          v-model="associateZip"
                          label="Poštanski broj"
                          outlined
                          dense
                          persistent-hint
                          :disabled="!cashRegister"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" sm="4" md="4">
                        <v-text-field
                          v-model="associateCity"
                          label="Grad"
                          outlined
                          dense
                          persistent-hint
                          :disabled="!cashRegister"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" sm="4" md="4">
                        <v-select
                          :items="countries"
                          :label="$t('$vuetify.formDetails.country')"
                          v-model="associateCountry"
                          outlined
                          dense
                          item-text="country"
                          item-value="country"
                          class="uppercased-input"
                          :disabled="!cashRegister"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        inEu &&
                        associateCountry !== 'Hrvatska' &&
                        associateCountry !== undefined &&
                        (this.paymentType.value === 'T' ||
                          this.paymentType.value === 'P')
                      "
                      style="margin-top: -3rem"
                    >
                      <v-spacer></v-spacer>
                      <v-col cols="4">
                        <v-radio-group
                          v-model="selectedVies"
                          :rules="[(v) => !!v || 'Molimo odaberite']"
                          required
                        >
                          <v-radio
                            v-for="vies in vieses"
                            :key="vies.value"
                            :value="vies.value"
                            :label="vies.name"
                            :rules="[rules.req]"
                          ></v-radio>
                        </v-radio-group>
                        <v-btn
                          href="https://ec.europa.eu/taxation_customs/vies/?locale=hr"
                          target="_blank"
                        >
                          Provjera VIES baze
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        !inEu &&
                        associateCountry !== 'Hrvatska' &&
                        associateCountry !== undefined &&
                        (this.paymentType.value === 'T' ||
                          this.paymentType.value === 'P')
                      "
                      style="margin-top: -3rem"
                    >
                      <v-spacer></v-spacer>
                      <v-col cols="4">
                        <v-radio-group
                          v-model="selectedCustomerType"
                          :rules="[(v) => !!v || 'Molimo odaberite']"
                          required
                          class="small-radio"
                        >
                          <v-radio
                            v-for="type in customerTypes"
                            :key="type.value"
                            :value="type.value"
                            :label="type.name"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row
                v-if="showPnb()"
                justify="center"
                style="margin-bottom: -2rem"
              >
                <v-col xl="2" lg="2" md="2" sm="2">
                  <v-text-field
                    v-model="pnbModel"
                    prefix="HR"
                    label="Model"
                    persistent-placeholder
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col xl="4" lg="5" md="6" sm="6">
                  <v-text-field
                    v-model="pnb"
                    label="Poziv na broj"
                    persistent-placeholder
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col xl="4" lg="4" md="4" sm="4">
                  <v-select
                    :items="pnbTypes"
                    v-model="pnbType"
                    item-text="text"
                    item-value="value"
                    persistent-placeholder
                    outlined
                    dense
                    @change="onPnbTypeChange"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-expansion-panels
                style="margin-bottom: 10px"
                v-model="commentPanel"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Dodaj napomenu
                    <template v-slot:actions>
                      <v-icon> mdi-receipt-text-outline </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense style="margin-top: -1rem">
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          v-model="note"
                          :items="languages"
                          label="Napomena"
                          rows="3"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-card-text>
          <v-card-actions class="pr-10 pl-10">
            <v-row dense style="margin-top: 1rem; margin-bottom: 1rem">
              <!-- ZA PREDUJAM SAKRIVENO -->
              <v-menu
                bottom
                left
                flat
                rounded="false"
                nudge-bottom="60px"
                min-width="200px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="avans"
                    class="btn warningButton"
                    width="35%"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!cashRegister"
                  >
                    <b>Račun za predujam</b>
                  </v-btn>
                </template>
                <v-list style="min-width: 100%">
                  <v-list-item :disabled="!cashRegister" @click="openAvans">
                    <v-list-item-title> Račun za predujam </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="!cashRegister"
                    @click="openAvansSummary"
                  >
                    <v-list-item-title>
                      Poveži račun za predujam
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn
                class="btn infoButton"
                style="margin-right: 1rem"
                @click="resetAll"
              >
                Počisti
              </v-btn>
              <v-btn
                class="btn dangerButton"
                style="margin-right: 1rem"
                @click="close"
              >
                Odustani
              </v-btn>
              <v-btn
                id="success"
                class="btn successButton"
                :disabled="!cashRegister"
                @click="validateForm"
                ref="footer"
              >
                Završi
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <show-error ref="showError"></show-error>
      <avans-dialog ref="avans"></avans-dialog>
      <avans-summary ref="avansSummary"></avans-summary>
    </v-row>
  </v-container>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import templatePayment from '@/mixins/templatePayment.js'
import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import countries from '@/mixins/countries'
import ShowError from '@/components/ShowError'
import rules from '@/plugins/rules'
import AvansDialog from '@/modules/cash-register/components/dialogs/avans/AvansDialog.vue'
import AvansSummary from '@/modules/cash-register/components/dialogs/avans/AvansSummary.vue'
import AvansDeducted from '@/model/AvansDeducted'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hr.js'

export default {
  components: {
    ShowError,
    AvansDialog,
    AvansSummary,
    DatePicker
  },
  mixins: [templatePayment, countries],
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'openPdfPrint', 'showMsgBoxHtml'],
  data () {
    return {
      dialog: false,
      isLoading: false,
      config: null,
      multiplier: null,
      selectedPrinter: null,
      selectedItems: [{ data: { name: '', id: '' } }],
      cartItem: { units: [] },
      currency: 'EUR',
      selectedCurrency: 'EUR', // samo za odabir simbola
      total: 0,
      fullTotal: 0,
      formatedTotal: '0,00',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // invoiceDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      invoiceDate: Date.now(),
      issueDateTime: (Math.floor(new Date().getTime() / 1000)),
      valueDate: this.getNextWorkingDay(Date.now()),
      deliveryDate: Date.now(),
      dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      locations: [],
      location: null,
      locationDisabled: false,
      cashRegisters: [],
      cashRegister: null,
      user: null,
      userIsSalesman: false,
      cashRegisterNumber: 0,
      cashRegisterType: '',
      listeners: [],
      items: [],
      cart: [],
      takenOibs: [],
      associates: [],
      invoiceDateOpen: false,
      valueDateOpen: false,
      deliveryDateOpen: false,
      invoiceDateLabel: 'Datum računa',
      valueDateLabel: 'Datum valute',
      deliveryDateLabel: 'Datum isporuke',
      paymentType: {},
      paymentTypes: [],
      itemTypes: [
        { name: 'Usluga', value: 'SERVICE' },
        { name: 'Materijalno dobro', value: 'GOODS' }
      ],
      taxes: [
        { name: 'PDV 25%', value: 1.25, rate: 0.25 },
        { name: 'PDV 13%', value: 1.13, rate: 0.13 },
        { name: 'PDV 5%', value: 1.05, rate: 0.05 },
        { name: 'PDV 0%', value: 1, rate: 0 }
      ],
      languages: [
        { name: 'Hrvatski', value: 'hr' },
        { name: 'English', value: 'en' }
      ],
      currencies: ['EUR', 'USD', 'GBP'],
      euCountries: [],
      inEu: false,
      selectedVies: undefined,
      associatesPanel: [],
      associate: undefined,
      associateByName: undefined,
      associateByOib: undefined,
      associateAddress: undefined,
      associateZip: undefined,
      associateCity: undefined,
      associateCountry: undefined,
      pnb: undefined,
      pnbModel: undefined,
      pnbType: 0,
      pnbTypes: [{ text: 'Generiraj poziv po broju računa', value: 0, disabled: false }, { text: 'Generiraj poziv po OIB-u', value: 1, disabled: true }],
      selectedLanguage: { name: 'Hrvatski', value: 'hr' },
      loading: false,
      absolute: true,
      opacity: 1,
      commentPanel: [],
      note: '',
      selectedCustomerType: undefined,
      wholeSaleRcptType: '',
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      avansDeducted: new AvansDeducted(),
      cartItems: [],
      formattedTotalMp: '0,00',
      formattedTotalVp: '0,00',
      registerListener: undefined
    }
  },

  computed: {
    receiptNumber () {
      if (!this.location) { return 'Broj računa' }
      if (!this.cashRegister) { return 'Broj računa' }
      return this.cashRegisterNumber +
      state.getCurrentCompany().receipt_numeration_separator +
      this.location.location_id +
      state.getCurrentCompany().receipt_numeration_separator +
      this.cashRegister.number
    },

    currencySymbol () {
      switch (this.selectedCurrency) {
        case 'USD':
          return '$'
        case 'GBP':
          return '£'
        case 'EUR':
        default: {
          return '€'
        }
      }
    },

    computedInvoiceDateFormatted () {
      return this.formatDate(this.invoiceDate)
    },

    computedValueDateFormatted () {
      return this.formatDate(this.valueDate)
    },

    computedDeliveryDateFormatted () {
      return this.formatDate(this.deliveryDate)
    },

    today () {
      const d = new Date()
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    },

    datePickersDisabled () {
      if (this.paymentType.value === 'G' || this.paymentType.value === 'K' || this.paymentType.value === 'O') {
        this.setDateToNow()
      } else {
        this.setDateDefault()
      }
      return this.paymentType.value === 'G' || this.paymentType.value === 'K' || this.paymentType.value === 'O'
      //  this.cashRegisterType === 'MP'
    },

    vieses () {
      return [
        { name: 'Kupac unutar VIES baze', value: 'inside' },
        { name: 'Kupac izvan VIES baze', value: 'outside' }
      ]
    },

    customerTypes () {
      return [
        { name: 'Kupac - pravna osoba', value: 'legal' },
        { name: 'Kupac - fizička osoba', value: 'person' }
      ]
    }
  },

  watch: {
    invoiceDate (nv) {
      if (this.paymentType.value === 'P' || this.paymentType.value === 'T') {
        this.valueDate = this.getNextWorkingDay(nv)
      }
    },
    associateCountry (nv) {
      this.inEu = false
      this.euCountries.forEach(country => {
        if (country === nv) {
          this.inEu = true
        }
      })
    }
  },

  methods: {
    setDateDefault () {
      this.invoiceDate = Date.now()
      this.valueDate = this.getNextWorkingDay(Date.now())
      this.deliveryDate = Date.now()
    },
    setDateToNow () {
      this.invoiceDate = Date.now()
      this.valueDate = Date.now()
      this.deliveryDate = Date.now()
    },
    showTotal () {
      if (this.paymentType.value !== 'T' && this.paymentType.value !== 'P') {
        return this.formatedTotal
      } else {
        return this.formattedTotalVp + ' <span style="font-size: 16px">(' + this.formattedTotalMp + ')</span>'
      }
    },
    showItemPrice (item) {
      if (this.paymentType.value !== 'T' && this.paymentType.value !== 'P') {
        return this.formatNumber(
          item,
          item.data.prices[this.currency].price / 100)
      } else {
        return this.formatNumber(
          item,
          item.data.prices[this.currency].base / 100
        ) + ' (' + this.formatNumber(
          item,
          item.data.prices[this.currency].price / 100
        ) + ')'
      }
    },
    openAvansSummary () {
      if (!this.cart[0]?.data?.name) {
        this.showMsgBox({ text: 'Potreban minimalno jedan artikl u košarici', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      let validated = true
      this.cart.forEach(it => {
        if (!this.validate(it)) {
          this.loading = false
          validated = false
          this.showMsgBox({ text: 'Podaci o proizvodu/proizvodima nepotpuni.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        }
      })
      if (!validated) return

      this.avansDeducted.discount = this.discount
      this.avansDeducted.cashRegister = this.cashRegister
      this.avansDeducted.location = this.location
      this.avansDeducted.initialPayment = this.paymentType.value
      this.$refs.avansSummary.open(this.avansDeducted)
    },
    openAvans () {
      this.$refs.avans.open(this.cashRegister, this.location)
    },
    async open (loader, register = undefined) {
      await this.setData()

      this.user = await this.getUser()
      if (this.user) {
        if (this.userIsSalesman) {
          this.dialog = true
        } else {
          this.showMsgBoxHtml({ text: 'Nemate privilegiju prodavača na ovom prodajnom mjestu, te niste u mogućnosti izdavati račune.<br/>Da biste dodali privilegiju prodavača odaberite<br/> Postavke → Korisnici te za korisnika odaberite privilegiju “PRODAVAČ”.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        }
      }
      EventBus.$emit('stopButtonLoader')

      this.getAssociates()

      if (register) {
        this.cashRegister = register
        await this.onSelectRegister()
      }
    },

    close () {
      this.resetAll()
      this.dialog = false
    },

    showPnb () {
      // (cashRegisterType === 'VP' || cashRegisterType === 'ALL')
      if (this.cashRegisterType === 'VP' || this.cashRegisterType === 'ALL') {
        if (this.paymentType.type === 'R' || this.paymentType.type === 'V' || this.paymentType.type === 'P') {
          return true
        }
      }
      return false
    },

    addHR () {
      this.pnbModel = 'HR'
    },

    getNextWorkingDay (date) {
      // Prvi radni dan se računa nakon 8 dana
      const futureDate = new Date(date)
      futureDate.setDate(futureDate.getDate() + 8)

      // Provera da li je to vikend, ako jeste, pomera na prvi radni dan
      while (futureDate.getDay() === 0 || futureDate.getDay() === 6) {
        futureDate.setDate(futureDate.getDate() + 1)
      }

      return futureDate.getTime()
    },

    formatNumber (item, number) {
      if (item.data.manual_price_input) return '- EUR'
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
        number
      )
    },

    async onSelectLocation () {
      await this.getCashRegisters()
      // state.setPointOfSale(this.location)
    },

    async onSelectRegister () {
      // Need to refresh register
      this.cashRegisterType = this.cashRegister.type
      this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister)

      await this.setPaymentTypes()
      await this.getItems()

      if (this.cashRegister?.type === 'VP' || this.cashRegister?.type === 'ALL') {
        this.calculateReferenceNumber()
      }

      this.listeners.push(
        df
          .doc(`cash_registers/${this.cashRegister.id}`)
          .onSnapshot((doc) => {
            if (doc && doc.data()) {
              this.registerListener = doc.data()

              if (this.paymentType.value && this.paymentType.value === 'P') {
                this.cashRegisterNumber = this.registerListener.offer_sequence + 1
              } else if (this.paymentType.value && this.paymentType.value === 'V') {
                this.cashRegisterNumber = this.registerListener.virman_sequence + 1
              } else {
                this.cashRegisterNumber = this.registerListener.bill_sequence + 1
              }
              this.calculateReferenceNumber()
            }
          })
      )
    },

    onSelectPaymentType () {
      if (this.paymentType.value === 'P') {
        this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister, true, false)
        this.valueDateLabel = 'Datum važenja ponude'
      } else if (this.paymentType.value === 'V') {
        this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister, false, true)
        this.valueDateLabel = 'Datum valute'
      } else {
        this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister, false, false)
        this.valueDateLabel = 'Datum valute'
      }
      this.calculateReferenceNumber()
    },

    onPnbTypeChange () {
      this.calculateReferenceNumber()
    },

    resetAll () {
      this.cart = []
      this.selectedItems = []
      this.total = 0
      this.fullTotal = 0
      this.formatedTotal = '0.00'
      this.formattedTotalMp = '0.00'
      this.formattedTotalVp = '0.00'
      this.addEmptyCartItem(0)
      this.associate = undefined
      this.associateByName = undefined
      this.associateByOib = undefined
      this.associateAddress = undefined
      this.associateZip = undefined
      this.associateCity = undefined
      this.associateCountry = undefined
      this.note = ''
      // this.invoiceDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.invoiceDate = Date.now()
      this.valueDate = this.getNextWorkingDay(this.invoiceDate)
      this.deliveryDate = Date.now()
      this.issueDateTime = (Math.floor(new Date().getTime() / 1000))
      this.pnbModel = ''
      this.pnb = ''
      this.wholeSaleRcptType = ''
      this.associatesPanel = []
      this.avansDeducted = new AvansDeducted()
      this.commentPanel = []
      this.cashRegisters.forEach(r => {
        r.disabled = false
      })
      this.locationDisabled = false
      this.calculateReferenceNumber()
    },

    onSelectAssociate () {
      this.populateAddressFields(this.associateByName)
      if (this.associateByName?.oibs && this.associateByName.oibs.length > 0) {
        this.associateByOib = this.associateByName.oibs[0]
      } else {
        this.associateByOib = undefined
      }
      this.pnbTypes[1].disabled = false
      this.calculateReferenceNumber()
    },

    onSelectAssociateOib () {
      const hasOibs = this.associateByOib?.oibs && this.associateByOib.oibs.length > 0
      if (hasOibs && this.takenOibs.includes(this.associateByOib.oibs[0])) {
        this.populateAddressFields(this.associateByOib)
        this.associateByName = this.associateByOib?.name ?? undefined
      }
      this.pnbTypes[1].disabled = false
      this.calculateReferenceNumber()
    },

    populateAddressFields (collection) {
      this.associateAddress = collection?.address ?? undefined
      this.associateZip = collection?.zip_code ?? undefined
      this.associateCity = collection?.city ?? undefined
      this.associateCountry = collection?.country ?? undefined
    },

    canAddNewItem () {
      let canAddNewItem = false
      for (let i = 1; i < this.cart.length; i++) {
        if (!this.cart[i].data?.name || !this.cart[i].unit || !this.cart[i].tax || !this.cart[i].price) {
          canAddNewItem = true
        }
      }

      return canAddNewItem
    },

    addEmptyCartItem (mode = 1) {
      let canAddNewItem = true
      for (let i = 0; i < this.cart.length; i++) {
        if (!this.cart[i].data?.name || !this.cart[i].unit || !this.cart[i].tax || !this.cart[i].price) {
          canAddNewItem = false
        }
      }

      if (!canAddNewItem) return

      this.cart.push({ data: { name: '', type: undefined } })
      if (this.$refs.formatted_total && mode === 1) {
        this.$nextTick(() =>
          this.$refs.formatted_total.scrollIntoView({ behavior: 'smooth', block: 'start' })
        )
      }
    },

    removeCartItem (counter) {
      // Ne daj obrisati jedini item na računu
      const tempCart = clone(this.cart)
      this.cart = []
      if (tempCart.length > 1) {
        tempCart.splice(counter, 1)
        this.selectedItems.splice(counter, 1)
        tempCart.forEach(item => {
          this.cart.push({ ...item })
        })
      } else {
        this.resetAll()
      }
      this.calculateTotals()
    },

    prepareItemForManualInput (name, index) {
      const itemId = uuidv4()
      const prices = {
        EUR: {
          price: 0,
          taxes: []
        }
      }
      prices.EUR.price = 0

      const item = {
        data: {
          id: itemId,
          active: true,
          status: 'OK',
          name: name,
          manual_price_input: true,
          prices: prices,
          type: 'SERVICE',
          freeItem: true
        },
        unitsAndPrices: []
      }

      // Ako su ova polja već popunjena u formi, prekopiraj ih ovdje
      if (this.cart[index].unitsAndPrices) {
        item.unitsAndPrices = this.cart[index].unitsAndPrices
      }
      if (this.cart[index].unit) {
        item.unit = this.cart[index].unit
      }
      if (this.cart[index].tax) {
        item.tax = { ...this.cart[index].tax }
        const tax = { ...item.tax }
        tax.rate *= 10000
        prices[this.currency].taxes.push(tax)
      }
      if (this.cart[index].quantity) {
        item.quantity = this.cart[index].quantity
      }
      if (this.cart[index].price) {
        item.price = this.cart[index].price
        prices[this.currency].price = item.price * 100
      }

      if (this.cart[index].discountAmt) {
        item.discountAmt = this.cart[index].discountAmt
      }
      return item
    },

    selectItem (index) {
      if (!this.selectedItems[index]) return
      // Kod ručnog unosa vrati string, a kod odabira object
      if (typeof (this.selectedItems[index]) === 'string') {
        this.selectedItems[index] = this.prepareItemForManualInput(this.selectedItems[index], index)
      }
      this.cart[index] = clone(this.selectedItems[index])

      this.updateCartItem(index)
    },

    updateQuantity (index) {
      this.cart[index].quantity = Number(this.cart[index].formattedQuantity)
      this.cart[index].formattedQuantity = this.cart[index].quantity.toFixed(2).replace('.', ',')
      this.updateCartItem(index)
    },

    updatePrice (index) {
      this.cart[index].manualPrice = Number(this.cart[index].formattedPrice)
      this.cart[index].price = this.cart[index].manualPrice

      // if ((this.paymentType.value === 'T' || this.paymentType.value === 'P') && this.cart[index].tax) {
      //   this.cart[index].manualPrice *= (1 + this.cart[index].tax.rate)
      // }

      this.cart[index].formattedPrice = this.cart[index].price.toFixed(2).replace('.', ',')

      this.updateCartItem(index)
    },

    updateDiscount (index) {
      this.cart[index].discountAmt = Number(this.cart[index].formattedDiscount)
      this.cart[index].formattedDiscount = this.cart[index].discountAmt.toFixed(2).replace('.', ',')
      this.updateCartItem(index)
    },

    updateCartItem (index) {
      // Ako artikl nema količinu, stavi 1
      if (!this.cart[index].quantity) {
        this.cart[index].quantity = 1
        this.cart[index].fomattedQuantity = '1,00'
      }

      // Ako artik nema popust, stavi 0
      if (!this.cart[index].discountAmt) {
        this.cart[index].discountAmt = 0
        this.cart[index].formattedDiscount = '0,00'
      }

      if (this.cart[index].data && this.cart[index].unitsAndPrices) {
        this.cart[index].units = this.cart[index].unitsAndPrices

        const taxes = this.cart[index].data.prices[this.currency].taxes
        if (taxes && taxes.length > 0) {
          if (!this.cart[index].tax) {
            const tax = { ...taxes[0] }
            if (tax) {
              tax.rate /= 10000
              this.cart[index].tax = tax
            }
          }
        }

        const defaultSalesUnit = this.cart[index].data.warehouse_units?.default_sale
        if (defaultSalesUnit) {
          const unit = this.cart[index].units.find(unit => unit.id === defaultSalesUnit)
          if (!this.cart[index].unit) {
            if (unit) {
              this.cart[index].unit = unit
              if (unit.price[this.currency]?.price) {
                if (!this.cart[index].manualPrice) {
                  this.cart[index].pricePdv = clone(unit.price[this.currency].price)
                  this.cart[index].priceBase = clone(unit.price[this.currency].base)

                  this.cart[index].price = unit.price[this.currency].price / 100
                }
              }
            } else {
              if (this.cart[index].units?.length > 0) {
                const unit = this.cart[index].units[0]
                this.cart[index].unit = unit

                if (!this.cart[index].manualPrice) {
                  this.cart[index].pricePdv = clone(unit.price[this.currency].price)
                  this.cart[index].priceBase = clone(unit.price[this.currency].base)

                  this.cart[index].price = unit.price[this.currency].price / 100
                }
              }
            }
          } else {
            if (!this.cart[index].manualPrice) {
              this.cart[index].pricePdv = clone(this.cart[index].unit.price[this.currency].price)
              this.cart[index].priceBase = clone(this.cart[index].unit.price[this.currency].base)

              this.cart[index].price = this.cart[index].unit.price[this.currency].price / 100
            }
          }
        }

        // Korigiranje cijene za transakcijski racun
        if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
          if (this.cart[index].unit?.price && this.cart[index].unit.price[this.currency]?.price?.base) {
            if (!this.cart[index].manualPrice) {
              this.cart[index].price = this.cart[index].unit.price[this.currency].base
            }
          } else {
            if (this.cart[index]?.unit?.price) {
              const rate = this.cart[index]?.unit?.price[this.currency].taxes[0].rate / 10000 + 1
              if (!this.cart[index].manualPrice) {
                this.cart[index].price = Number((this.cart[index].unit.price[this.currency].price / rate / 100).toFixed(2))
              }
            }
          }
        }

        // Dodaj popust
        this.cart[index].data.discounted_price = 0

        if (this.cart[index].discountAmt !== undefined && this.cart[index].discountAmt > 0) {
          this.cart[index].data.discounted_price = Number(this.calculateDiscount(this.cart[index]).toFixed(2))

          if (!this.cart[index].manualPrice) {
            this.cart[index].data.single_item_price = this.cart[index].data.prices[this.currency].price
          } else {
            this.cart[index].data.single_item_price = this.cart[index].manualPrice * 100
          }

          this.cart[index].data.discount = {
            active: true,
            amount: this.cart[index].data.single_item_price - this.cart[index].data.discounted_price * 100,
            name: 'Popust',
            rate: Number(this.cart[index].discountAmt) * 100,
            type: 'RATE'
          }
        }
      } else {
        // Firma nema skladište
        if (this.cart[index].data.prices[this.currency]?.active) {
          if (!this.cart[index].manualPrice) {
            this.cart[index].pricePdv = clone(this.cart[index].data.prices[this.currency].price)
            this.cart[index].priceBase = clone(this.cart[index].data.prices[this.currency].base)

            this.cart[index].price = this.cart[index].data.prices[this.currency].price / 100
          }
          const taxRate = this.cart[index].data.prices[this.currency].taxes[0].rate / 10000
          if (taxRate !== undefined && taxRate !== null) {
            this.cart[index].tax = this.taxes.find(item => item.rate === taxRate)
          }
          if (this.cart[index].data.selling_unit) {
            this.cart[index].unit = ({ storageUnit: this.cart[index].data.selling_unit })
          }

          // Korigiranje cijene za - transakcijski racun
          if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
            if (this.cart[index].data.prices[this.currency]?.base) {
              if (!this.cart[index].manualPrice) {
                this.cart[index].price = this.cart[index].data.prices[this.currency].base / 100
                this.cart[index].data.single_item_price = this.cart[index].data.prices[this.currency].base
              }
            } else {
              const rate = this.cart[index].tax.rate + 1
              if (!this.cart[index].manualPrice) {
                this.cart[index].price = Number((this.cart[index].data.prices[this.currency].price / rate / 100).toFixed(2))
                this.cart[index].data.single_item_price = this.cart[index].price
              }
            }
          }

          // Dodaj popust
          this.cart[index].data.discounted_price = 0
          if (this.cart[index].discountAmt !== undefined && this.cart[index].discountAmt > 0) {
            this.cart[index].data.discounted_price = Number(this.calculateDiscount(this.cart[index]).toFixed(2))

            if (!this.cart[index].manualPrice) {
              this.cart[index].data.single_item_price = this.cart[index].data.prices[this.currency].price
            } else {
              this.cart[index].data.single_item_price = this.cart[index].manualPrice * 100
            }

            this.cart[index].data.discount = {
              active: true,
              amount: this.cart[index].data.single_item_price - this.cart[index].data.discounted_price * 100,
              name: 'Popust',
              rate: Number(this.cart[index].discountAmt) * 100,
              type: 'RATE'
            }
          }
        }
      }

      if (this.cart[index].price && this.cart[index].price > 0 && this.cart[index].tax && (this.paymentType.value === 'T' || this.paymentType.value === 'P')) {
        this.cart[index].priceBase = Math.round(this.cart[index].price * 100)
        this.cart[index].pricePdv = this.cart[index].priceBase * (1 + this.cart[index].tax.rate)
      } else if (this.cart[index].price && this.cart[index].price > 0 && this.cart[index].tax) {
        this.cart[index].pricePdv = Math.round(this.cart[index].price * 100)
        this.cart[index].priceBase = this.cart[index].pricePdv / (1 + this.cart[index].tax.rate)
      }

      this.cart[index].formattedPrice = this.cart[index].price ? this.cart[index].price.toFixed(2) : ''
      this.cart[index].formattedQuantity = this.cart[index].quantity ? this.cart[index].quantity.toFixed(2) : ''
      this.cart[index].formattedDiscount = this.cart[index].discountAmt ? this.cart[index].discountAmt.toFixed(2) : ''

      const firstItemIsInserted = this.cart[0].price && this.cart[0].quantity && this.cart[0].data.name
      this.cashRegisters.forEach(r => {
        r.disabled = firstItemIsInserted && r.type !== this.cashRegister.type
      })
      this.locationDisabled = !!firstItemIsInserted

      this.avansDeducted.items = []
      this.cart.forEach((itm, i) => {
        var it = clone(itm)
        var item = clone(it.data)

        item.quantity = it.quantity

        item.discounted_price = it.data.discounted_price
        item.price = it.price
        item.total = it.price * 100
        item.discounted_price *= 100
        item.prices[this.currency].price = item.price * 100

        if (it.data.single_item_price > 0) {
          item.unitPrice = it.data.single_item_price
        }

        if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
          if (item.discount?.amount) {
            item.discount.amount = (it.price - itm.data.discounted_price) * 100
          }

          item.single_item_price = item.price
        }

        if (it.tax) {
          if (item.prices[this.currency].taxes && item.prices[this.currency].taxes.length === 0) {
            var newTax = {
              name: it.tax.name,
              rate: it.tax.rate * 10000
            }
            item.prices[this.currency].taxes = [newTax]
            this.cart[i].data.prices[this.currency].taxes = [newTax]
          } else {
            item.prices[this.currency].taxes[0].name = it.tax.name
            item.prices[this.currency].taxes[0].rate = it.tax.rate * 10000

            this.cart[i].data.prices[this.currency].taxes[0].rate = it.tax.rate * 10000
          }
        }

        this.avansDeducted.items.push(item)
      })

      this.calculateTotals()
    },

    calculateTotals () {
      let accumulator = 0
      this.cart.forEach(item => {
        if (item.data.discounted_price) {
          accumulator += item.data.discounted_price * item.quantity * 100
        } else {
          if (item.price && item.quantity) {
            accumulator += item.price * item.quantity * 100
          }
        }
      })
      const fullTotal = this.cart.reduce((accumulator, item) => accumulator + (item.price * item.quantity * 100), 0)
      const total = accumulator

      this.fullTotal = Number(fullTotal.toFixed(2))
      this.total = Number(total.toFixed(2))
      if (isNaN(this.total)) { this.total = 0 }
      this.formatedTotal = (Math.round(this.total) / 100).toFixed(2).replace('.', ',')

      // NOVI IZRACUN SAMO ZA TRANSAKCIJSKI RACUN SA VP CIJENAMA
      var totalMp = 0
      var totalVp = 0
      var fullTotalMp = 0
      var fullTotalVp = 0
      var pdvs = []

      this.cart.forEach((item, i) => {
        if (this.isDefined(item.quantity) && this.isDefined(item.priceBase) && this.isDefined(item.pricePdv)) {
          if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
            totalVp += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.priceBase * item.quantity
            totalMp += item.data.discounted_price ? item.data.discounted_price * 100 * (1 + item.tax.rate) * item.quantity : item.pricePdv * item.quantity

            fullTotalVp += item.priceBase * item.quantity
            fullTotalMp += item.pricePdv * item.quantity
          } else {
            totalMp += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.pricePdv * item.quantity
            totalVp += item.data.discounted_price ? (item.data.discounted_price * 100) / (1 + item.tax.rate) * item.quantity : item.priceBase * item.quantity

            fullTotalVp += item.priceBase * item.quantity
            fullTotalMp += item.pricePdv * item.quantity
          }

          var pdvName = 'p' + parseInt(item.tax.rate * 10000)
          if (!pdvs[pdvName]) {
            pdvs[pdvName] = {
              base: 0,
              pdv: 0,
              total: 0
            }
          }

          if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
            pdvs[pdvName].base += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.priceBase * item.quantity
            pdvs[pdvName].total += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * (1 + item.tax.rate) * item.quantity : item.pricePdv * item.quantity
            pdvs[pdvName].rate = item.tax.rate * 10000
            pdvs[pdvName].pdv += pdvs[pdvName].total - pdvs[pdvName].base
          } else {
            pdvs[pdvName].total += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.pricePdv * item.quantity
            pdvs[pdvName].base += this.isDefined(item.data.discounted_price) ? (item.data.discounted_price * 100) / (1 + item.tax.rate) * item.quantity : item.priceBase * item.quantity
            pdvs[pdvName].rate = item.tax.rate * 10000
            pdvs[pdvName].pdv = pdvs[pdvName].total - pdvs[pdvName].base
          }

          if (item.discount?.amount > 0) {
            item.discount.amount = fullTotalMp - totalMp
          }
        }
      })
      if (isNaN(totalVp)) { totalVp = 0 }
      if (isNaN(totalMp)) { totalMp = 0 }
      if (isNaN(fullTotalVp)) { fullTotalVp = 0 }
      if (isNaN(fullTotalMp)) { fullTotalMp = 0 }
      this.avansDeducted.totalMp = totalMp
      this.avansDeducted.totalVp = totalVp
      this.avansDeducted.fullTotalVp = fullTotalVp
      this.avansDeducted.fullTotalMp = fullTotalMp
      this.avansDeducted.totalDiscountPdv = fullTotalMp - totalMp
      this.avansDeducted.formattedTotalDiscountPdv = (this.avansDeducted.totalDiscountPdv / 100).toFixed(2).replace('.', ',')

      this.avansDeducted.pdvs = pdvs
      this.formattedTotalVp = (totalVp / 100).toFixed(2).replace('.', ',')
      this.formattedTotalMp = (totalMp / 100).toFixed(2).replace('.', ',')
    },

    isDefined (param) {
      if (param === undefined) {
        return false
      }

      if (param === null) {
        return false
      }

      if (param === 0) {
        return false
      }

      return true
    },

    calculateDiscount (item) {
      if (!item.manualPrice) {
        return item.price - (item.price * item.discountAmt) / 100
      }
      return item.manualPrice - (item.manualPrice * item.discountAmt) / 100
    },

    getCategory (item) {
      var categories = ''
      if (item.data.categories && item.data.categories.length > 0) {
        item.data.categories.forEach(cat => {
          categories += cat.name.toUpperCase() + ' | '
        })
      }
      return categories.replace(/\|([^|]*)$/, '$1')
    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    getItems () {
      this.isLoading = true
      this.items = []
      if (!this.location.id) { return }
      df.collection(`location_pricelist/${this.location.id}/items`)
        .where('status', '==', 'OK')
        .where('active', '==', true)
        .where(`prices.${this.currency}.active`, '==', true)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((it) => {
              const item = {}
              item.data = it.data()
              // TODO remove after script adds base to all
              if (item.data.prices.EUR?.base === null || item.data.prices.EUR?.base === undefined) {
                item.data.prices.EUR.base = Math.round(item.data.prices.EUR.price / (1 + (item.data.prices.EUR.taxes[0].rate / 10000)))
              }

              if (item.data.warehouse_units?.units) {
                item.unitsAndPrices = []
                Object.keys(item.data.warehouse_units.units).forEach(key => {
                  const units = item.data.warehouse_units.units[key]
                  if (units?.storage_unit && units?.prices && units?.prices[this.currency]?.active) {
                    if (item.data.manual_price_input) {
                      units.prices[this.currency].price = '0'
                    }

                    // TODO remove after script adds base to all
                    if (units.prices && units.prices.EUR && (units.prices.EUR?.base === null || units.prices.EUR?.base === undefined)) {
                      units.prices.EUR.base = Math.round(units.prices.EUR.price / (1 + (units.prices.EUR.taxes[0].rate / 10000)))
                    }

                    item.unitsAndPrices.push({ id: key, storageUnit: units.storage_unit, price: units.prices })
                  }
                })
              }
              if (item.data.active) {
                this.items.push(item)
              }
            })
          }
        }).catch(() => {
          console.error('An error has occured')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getAssociates () {
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((resp) => {
          if (resp && resp.data()) {
            this.defaultAssociates = Object.keys(resp.data().associates)
              .map((key) => {
                return resp.data().associates[key]
              })
              .filter((associate) => associate.status === 'OK')
            this.defaultAssociates.push({ name: 'Kupac građanin', id: 'kupac-gradanin' })
            this.associates = clone(this.defaultAssociates.sort((a, b) => a.name.localeCompare(b.name)))
            // Get all used up OIBs

            if (this.associates && this.associates.length > 0) {
              this.associates.forEach(item => {
                if (item.oibs && item.oibs.length > 0) {
                  item.oibs.forEach(oib => {
                    this.takenOibs.push(oib)
                  })
                }
              })
            }
          }
        })
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    async setData () {
      await this.getPointsOfSale()
      this.selectedPrinter = state.getSelectedPrinter()
    },

    setPaymentTypes () {
      if (this.cashRegisterType === 'VP') {
        this.paymentTypes = [
          { name: 'Transakcijski račun', value: 'T', type: 'R' },
          { name: 'Ponuda', value: 'P', type: 'P' },
          { name: 'Virman', value: 'V', type: 'V' }
        ]
        this.paymentType = { name: 'Transakcijski račun', value: 'T', type: 'R' }
      } else if (this.cashRegisterType === 'ALL') {
        this.paymentTypes = [
          { name: 'Transakcijski račun', value: 'T', type: 'R' },
          { name: 'Ponuda', value: 'P', type: 'P' },
          { name: 'Virman', value: 'V', type: 'V' }
        ]

        this.paymentType = { name: 'Transakcijski račun', value: 'T', type: 'R' }

        if (state.getCurrentCompany().certificate !== undefined && state.getCurrentCompany().certificate !== null && state.getCurrentCompany().certificate !== '') {
          this.paymentTypes = [
            { name: 'Transakcijski račun', value: 'T', type: 'R' },
            { name: 'Ponuda', value: 'P', type: 'P' },
            { name: 'Gotovina', value: 'G', type: 'BO' },
            { name: 'Kartice', value: 'K', type: 'BO' },
            { name: 'Ostalo', value: 'O', type: 'BO' },
            { name: 'Virman', value: 'V', type: 'V' }
          ]
          this.paymentType = { name: 'Gotovinski račun', value: 'G', type: 'BO' }
        }
      } else {
        this.paymentTypes = [
          { name: 'Gotovina', value: 'G', type: 'BO' },
          { name: 'Kartice', value: 'K', type: 'BO' },
          { name: 'Ostalo', value: 'O', type: 'BO' }
        ]
        this.paymentType = { name: 'Gotovina', value: 'G', type: 'BO' }
      }
    },

    async getUser () {
      let user
      const doc = await df.doc(`users/${auth.currentUser.uid}`).get()
      if (doc && doc.data()) {
        user = doc.data()
        this.roles = Object.keys(user.roles)
        if (this.roles.includes('PRODAVAČ')) {
          this.userIsSalesman = true
        }
      }
      return user
    },

    rValidation () {
      if (!this.associateByName && !this.associateByOib) {
        this.confirm({
          title: 'Kupac nije odabran',
          message: 'Želite li izdati račun / ponudu s nepoznatim kupcem?',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'OK',
            cancelText: 'Odustani'
          }
        }).then(response => {
          if (response) {
            this.confirmClose()
            this.associateByName = { name: 'Kupac građanin', id: 'kupac-gradanin' }
            this.createReceipt()
          } else {
            // eslint-disable-next-line no-useless-return
            return
          }
        })
      } else {
        this.createReceipt()
      }
    },

    validateForm () {
      if (!this.cart[0]?.data?.name) {
        this.showMsgBox({ title: 'test', text: 'Potreban minimalno jedan artikl u košarici', actions: ['ok'], okBtnText: 'OK', color: 'error' })
        return
      }
      if (this.paymentType.type !== 'BO') {
        this.rValidation()
      } else {
        this.createReceipt()
      }
    }
  },

  async mounted () {
    this.getMultiplier()
    this.addEmptyCartItem()

    this.getEuCountries()

    EventBus.$off('set-selected-printer')
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })

    EventBus.$on('stop-loader', async () => {
      this.loading = false
      // await this.getCashRegisters()
      await this.getCashRegister()
      this.onSelectPaymentType()
      this.resetAll()
      this.calculateReferenceNumber()
    })

    EventBus.$off('error-return')
    EventBus.$on('error-return', () => {
      this.loading = false
    })

    EventBus.$off('showError')
    EventBus.$on('showError', (data) => {
      this.$refs.showError.open(data.message)
    })

    this.selectedVies = 'inside'
    this.selectedCustomerType = 'legal'
  },

  beforeDestroy () {
    this.detachListeners()
  }
}
</script>

<style scoped>
.totals-row {
  max-height: 3rem;
}

.total-price {
  display: block;
  font-size: 1.8rem;
  color: black;
  margin-top: 0.3rem;
  margin-right: 1rem;
}

.curreny-selector {
  max-width: 6rem;
}

.attention >>> .v-text-field__slot input {
  color: red;
}
.normal >>> .v-text-field__slot input {
  color: black;
}
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 30px;
  border-radius: 25px !important;
}
::v-deep .small-radio i {
  font-size: 15px;
}

::v-deep .delete-icon i {
  font-size: 6px;
}
::v-deep .small-radio label {
  font-size: 15px;
  padding-left: 0px;
  margin-left: -4px;
}
::v-deep .small-radio .v-radio {
  padding: 0px;
}
::v-deep .small-radio [class*="__ripple"] {
  left: 0;
}

.my-overlay >>> .v-overlay__content {
  width: 100%;
  height: 100%;
}

.datetimepicker {
  width: 100%;
  height: 40px !important;
}

.datepickerLabel {
  font-size: 12px !important;
  font-weight: bold;
}
</style>
