<template>
  <v-dialog
    v-model="dialogOpen"
    content-class="my-custom-dialog"
    persistent
    scrollable
    max-width="800"
    @keydown.esc="close"
  >
    <v-container>
      <a href="https://www.aikasa.app/" target="_blank">
        <v-row>
          <v-col
            class="d-flex justify-center align-center header-bar"
            style="max-width: calc(100% - 1.4rem)"
            cols="12"
          >
            <img :src="require('@/assets/logo-1x.png')" alt="AiKasa Logo" />
          </v-col>
        </v-row>
      </a>
      <v-row>
        <v-col class="d-flex justify-center align-center" cols="12"> </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <!-- RIGHT -->

        <v-col cols="12">
          <v-row style="margin-bottom: -1.5rem">
            <v-col cols="12">
              <v-card
                class="pa-2 text-center"
                style="background-color: bisque"
                outlined
                tile
              >
                <h4>
                  Prema zakonu o uvođenju EUR tečaj koji se koristi u konverziji
                  je 1 EUR = {{ conversionRateString }}
                </h4>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top: -1.5rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile> Iznos računa </v-card>
            </v-col>
            <v-col
              class="calculator-row"
              style="margin-top: 11px; margin-left: -12px"
              cols="2"
            >
              <v-text-field
                ref="invoiceAmtEur"
                autofocus
                outlined
                dense
                class="inputs"
                v-model="rightCalc.invoiceAmtEur"
                @keypress="filter($event)"
                @blur="onEnter(0)"
                @click="rightCalc.invoiceAmtEur = ''"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile>EUR</v-card>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-text-field
                outlined
                dense
                readonly
                class="inputs"
                v-model="rightCalc.invoiceAmtHrk"
                tabindex="-1"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile> HRK </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" style="height: 2.4rem"></v-col>
          </v-row>
          <v-row style="margin-top: -2.4rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile> Plaćeno EUR </v-card>
            </v-col>
            <v-col
              class="calculator-row"
              style="margin-top: 0.7rem; margin-left: -0.8rem"
              cols="2"
            >
              <v-text-field
                outlined
                dense
                class="inputs"
                v-model="rightCalc.paidAmtEur"
                @keypress="filter($event)"
                @blur="onEnter(1)"
                @click="rightCalc.paidAmtEur = ''"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile> EUR </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top: -2.4rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile> Plaćeno HRK </v-card>
            </v-col>
            <v-col
              class="calculator-row"
              style="margin-top: 0.7rem; margin-left: -0.8rem"
              cols="2"
            >
              <v-text-field
                outlined
                dense
                class="inputs"
                v-model="rightCalc.paidAmtHrk"
                @keypress="filter($event)"
                @blur="onEnter(2)"
                @click="rightCalc.paidAmtHrk = ''"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile> HRK </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" style="height: 4.1rem"></v-col>
          </v-row>

          <v-row style="margin-top: -2.4rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile> Za vratiti </v-card>
            </v-col>
            <v-col
              class="calculator-row"
              :class="
                diff === 0 ? 'back-white' : diff < 0 ? 'back-red' : 'back-green'
              "
              style="margin-top: 0.7rem; margin-left: -0.8rem"
              cols="2"
            >
              <v-text-field
                outlined
                dense
                readonly
                class="inputs"
                v-model="rightCalc.changeEur"
                tabindex="-1"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile> EUR </v-card>
            </v-col>
            <v-col
              class="calculator-row"
              :class="
                diff === 0 ? 'back-white' : diff < 0 ? 'back-red' : 'back-green'
              "
              style="margin-top: 0.7rem"
              cols="2"
            >
              <v-text-field
                outlined
                dense
                readonly
                class="inputs"
                v-model="rightCalc.changeHrk"
                tabindex="-1"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile> HRK </v-card>
            </v-col>
          </v-row>

          <!-- VRAĆENO -->

          <v-row style="margin-top: 0rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile>
                Vraćeno
              </v-card> </v-col
            ><v-col
              class="calculator-row"
              style="margin-top: 0.7rem; margin-left: -0.8rem"
              cols="2"
            >
              <v-text-field
                outlined
                dense
                class="inputs"
                v-model="returnedAmountEur"
                @keypress="filter($event)"
                @blur="onEnter(3)"
                @click="returnedAmountEur = ''"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile> EUR </v-card> </v-col
            ><v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-text-field
                outlined
                dense
                readonly
                class="inputs"
                v-model="differenceHrk"
                tabindex="-1"
              ></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem" cols="2">
              <v-card class="pa-2 cards" outlined tile> HRK </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-start align-start">
              <v-btn
                style="margin-right: 1rem"
                color="primary"
                depressed
                @click="clearAll"
                >Obriši sve</v-btn
              >
              <v-btn
                style="margin-right: 1rem"
                color="primary"
                depressed
                @click="onCalcuate"
                >Izračunaj</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="close">Zatvori</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogOpen: false,
      conversionRateString: undefined,
      conversionRate: undefined,
      rightCalc: {},
      invoiceAmtEurValue: 0,
      paidAmtEurValue: 0,
      paidAmtHrkValue: 0,
      changeEur: 0,
      changeHrk: 0,
      returnedAmountEur: '',
      differenceHrk: '',
      diff: 0,
      currencyEur: {
        type: String,
        default: 'EUR'
      }
    }
  },
  methods: {
    open () {
      this.conversionRateString = '7.53450'
      this.conversionRate = parseFloat(this.conversionRateString)
      this.rightCalc =
            {
              invoiceAmtHrk: '',
              invoiceAmtEur: '',
              paidAmtHrk: '',
              paidAmtEur: '',
              changeHrk: '',
              changeEur: '',
              totalPaid: ''
            }
      this.dialogOpen = true
    },
    close () {
      this.dialogOpen = false
    },
    onClick (idx) {
      if (idx === 0) {
        this.rightCalc.invoiceAmtEur = null
      }
    },
    onEnter (idx) {
      if (idx === 0) {
        this.convertForRegister('invoiceAmtEur')
      } else if (idx === 1) {
        this.convertForRegister('paidAmtEur')
      } else if (idx === 2) {
        this.convertForRegister('paidAmtHrk')
      } else if (idx === 3) {
        this.convertForRegister('returnedAmount')
      }
    },
    onCalcuate () {
      if (this.rightCalc.invoiceAmtEur) {
        this.convertForRegister('invoiceAmtEur')
      }
      this.calculateChange()
    },
    convertToNumber (fieldValue) {
      if (fieldValue && typeof (fieldValue) === 'string') {
        return parseFloat(fieldValue.replace('.', '').replace(',', '.'))
      }
      return ''
    },
    convertForRegister (target) {
      switch (target) {
        case 'invoiceAmtEur':
          this.rightCalc.invoiceAmtEur = this.convertToNumber(this.rightCalc.invoiceAmtEur)
          if (this.rightCalc.invoiceAmtEur !== '') {
            this.invoiceAmtEurValue = this.rightCalc.invoiceAmtEur
            this.rightCalc.invoiceAmtEur = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.invoiceAmtEurValue)
            this.rightCalc.invoiceAmtHrk = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.invoiceAmtEurValue * this.conversionRate)
          }
          break
        case 'paidAmtEur':
          this.calculateChange()
          break
        case 'paidAmtHrk':
          this.calculateChange()
          break
        case 'returnedAmount':
          this.calculateDifference()
          break
        default:
          break
      }
      this.$forceUpdate()
    },
    calculateChange () {
      if (this.rightCalc.invoiceAmtEur === '') {
        this.rightCalc.changeEur = ''
        this.rightCalc.changeHrk = ''
        return
      }
      this.totalPaid = 0
      this.rightCalc.paidAmtEur = this.convertToNumber(this.rightCalc.paidAmtEur)
      if (this.rightCalc.invoiceAmtEur !== '' && this.rightCalc.paidAmtEur !== '') {
        this.totalPaid += parseFloat(this.rightCalc.paidAmtEur)
        this.rightCalc.paidAmtEur = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.rightCalc.paidAmtEur)
      }
      this.rightCalc.paidAmtHrk = this.convertToNumber(this.rightCalc.paidAmtHrk)
      if (this.rightCalc.paidAmtHrk !== '') {
        const paidHrkToEur = parseFloat(this.rightCalc.paidAmtHrk / this.conversionRate)
        this.totalPaid += parseFloat(paidHrkToEur)
        this.rightCalc.paidAmtHrk = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.rightCalc.paidAmtHrk)
      }
      this.diff = this.totalPaid - this.invoiceAmtEurValue
      this.changeEur = this.totalPaid - this.invoiceAmtEurValue
      this.changeHrk = this.changeEur * this.conversionRate

      this.rightCalc.changeEur = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.totalPaid - this.invoiceAmtEurValue)
      this.rightCalc.changeHrk = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.changeEur * this.conversionRate)
    },
    calculateDifference () {
      this.returnedAmountEur = this.convertToNumber(this.returnedAmountEur)
      if (this.changeEur <= this.returnedAmountEur) {
        this.differenceHrk = 0
      } else {
        this.differenceHrk = (this.changeEur - this.returnedAmountEur) * this.conversionRate
      }
      this.returnedAmountEur = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.returnedAmountEur)
      this.differenceHrk = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.differenceHrk)
    },
    clearAll () {
      this.rightCalc.invoiceAmtHrk = ''
      this.rightCalc.invoiceAmtEur = ''
      this.rightCalc.paidAmtHrk = ''
      this.rightCalc.paidAmtEur = ''
      this.rightCalc.changeHrk = ''
      this.rightCalc.changeEur = ''
      this.rightCalc.totalPaid = ''
      this.returnedAmountEur = ''
      this.differenceHrk = ''
      this.diff = 0

      const fieldRef = this.$refs.invoiceAmtEur
      fieldRef.focus()
    },
    filter: function (evt) {
      evt = (evt) || window.event
      const expect = evt.target.value.toString() + evt.key.toString()

      if (!/^[-+]?[0-9,]*?[0-9,]*$/.test(expect)) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  background-color: white;
}

.header-bar {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  max-width: calc(50% - 1.5rem);
  margin-left: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.calculator-row {
  padding: 0;
}

.cards {
  height: 2.5rem;
}

.inputs {
  border-radius: 0;
}

.for-register {
  margin-top: -1.5rem;
}

.back-white {
  height: 39px;
  background-color: rgb(255, 255, 255);
}

.back-red {
  height: 39px;
  background-color: rgb(255, 161, 161);
}

.back-green {
  height: 39px;
  background-color: rgb(179, 233, 179);
}
</style>
