
import { requestPrint } from '@/libs/bixolon/bxlcommon'
import { checkPrinterStatus, cutPaper, getPosData, setCharacterset, setPosId } from '@/libs/bixolon/bxlpos'
import { printLine, printTotals, printSeparator, printMultipleShort } from '@/libs/bixolon/printer'
import { formatCurrency, formatCurrencyCustom, getCummulatedDiscount } from '@/libs/bixolon/helpers'
import EventBus from '@/plugins/event-bus'
import state from '@/state'
import { df } from '@/plugins/firebase'
import { printerErrors } from '@/libs/jsPrintManager/utils'
import * as JSPM from 'jsprintmanager'

export const printerInit = (issueID) => {
  checkPrinterStatus()
  setCharacterset(1250)
  setPosId(issueID)
}

const viewResult = (result) => {
  if (result === 'Cannot connect to server') {
    alert('Pisač je nedostupan.')
  }
}

export const printVirman58 = async (printer, data, response) => {
  const printerType = printer?.library
  // Fix za staru konfiguraciju
  if (!['UNIVERSAL', 'NONE'].includes(printerType)) {
    if (!printer.font) printer.font = 0
    if (!printer.size) printer.size = 0
    if (!printer.width) printer.width = '80'
    if (!printer.line_length) printer.line_length = 48
    if (!printer.library) printer.library = 'BIXOLON'
  } let pName = printer.printer_name

  let fullVirman
  if (response && response.data && response.data.full_virman) {
    fullVirman = response.data.full_virman
  } else {
    console.error('fullVirman is missing from response.')
    return
  }

  let [escpos, doc, printerConfiguration] = [null, null, null]
  var currency = 'EUR'

  const company = state.getCurrentCompany()
  const pointOfSale = state.getPointOfSale()
  const currencySymbol = printer.euro_symbol_type ?? '€'

  const cashRegisterResponse = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
  const cashRegister = cashRegisterResponse.data()

  printerConfiguration = printer

  if (printerType === 'UNIVERSAL') {
    escpos = window.Neodynamic.JSESCPOSBuilder
    doc = new escpos.Document()
  }
  // const conversionRate = 7.53450
  // const conversionRateString = '7.53450'
  const paymentMethod = fullVirman.payment_method

  const companyAddress = `${fullVirman.company_details.address}, ${fullVirman.company_details.zip_code} ${fullVirman.company_details.city}`
  const pointOfSaleAddress = `${fullVirman.location_details.address}, ${fullVirman.location_details.zip_code} ${fullVirman.location_details.city}`
  const separator = company.receipt_numeration_separator // TODO: Vidjeti da li se ovdje radi o rcpt_separator, receipt_numeration_separator ne postoji u company_details

  var virmanSequence = 0
  if (cashRegister && cashRegister.virman_sequence) {
    virmanSequence = cashRegister.virman_sequence
  }

  const receiptId = `${virmanSequence + 1}${separator}${pointOfSale.location_id}${separator}${cashRegister.number}`

  const total = fullVirman.total
  const fullTotal = fullVirman.full_total
  const options = {
    horizontal: 0,
    vertical: 0,
    bold: false,
    invert: false,
    underline: false,
    alignment: 0,
    printerConfiguration: printerConfiguration,
    document: doc,
    escpos: escpos
  }

  if (state.getPrinterLib === 'UNIVERSAL') {
    options.font = printerConfiguration.font
  } else {
    if (printerConfiguration.font === 'A') options.font = 0
    else if (printerConfiguration.font === 'B') options.font = 1
    else if (printerConfiguration.font === 'C') options.font = 2
  }

  function getOptions (option) {
    const modifiedOptions = JSON.parse(JSON.stringify(options))
    Object.keys(option).forEach(key => {
      modifiedOptions[key] = option[key]
    })
    modifiedOptions.document = options.document
    modifiedOptions.escpos = options.escpos
    modifiedOptions.printerConfiguration = options.printerConfiguration

    return modifiedOptions
  }

  printLine('Specifikacija troškova', getOptions({ alignment: 1 }))

  var date = new Date(fullVirman.issue_date_time * 1000)
  printLine(fullVirman.company_details.name, getOptions({ alignment: 1 }))
  printLine(companyAddress + ', ' + fullVirman.company_details.country, getOptions({ alignment: 1 }))
  printLine(fullVirman.location_details.name, getOptions({ alignment: 1 }))
  printLine(pointOfSaleAddress, getOptions({ alignment: 1 }))
  printLine(`OIB: ${fullVirman.company_details.oib}`, getOptions({ alignment: 1 }))

  printSeparator(options)
  const operater = fullVirman.seller_id && fullVirman.seller_id !== '' ? fullVirman.seller_id : fullVirman.seller_name

  // console.log('operater', operater)
  printTotals('Virman br:', receiptId, options)
  printTotals('Način plaćanja:', paymentMethod, options)
  printTotals('Operater:', operater, options)
  printTotals('Blagajna: ', String(fullVirman.designation.register_number), options)
  printTotals('Datum: ', ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear(), options)
  printTotals('Vrijeme: ', ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2), options)

  printSeparator(options)

  const type = fullVirman.type
  if (type.payer_oib) {
    const customerAddress = `${type.payer_address}, ${type.payer_zip_code} ${type.payer_city}`

    printLine('Kupac', options)
    printLine(`Naziv: ${type.payer_name.toUpperCase()}`, options)
    printLine(`Adresa: ${customerAddress.toUpperCase()}`, options)
    printLine(`Oib: ${type.payer_oib.toUpperCase()}`, options)

    printSeparator(options)
  }

  const lineLength = options.printerConfiguration.line_length

  printLine('Artikl', options)
  printMultipleShort(['Cijena', 'Kol', 'Ukupno'], 2, options)

  printSeparator(options)

  fullVirman.items.forEach(item => {
    printLine(`${item.name.toUpperCase()}`.substring(0, lineLength), getOptions({ bold: true }))
    printMultipleShort([
      formatCurrency(currency, item.single_item_price / 100),
      formatCurrency(currency, item.amt / 1000),
      formatCurrency(currency, (item.amt / 1000) * (item.single_item_price / 100))
    ], 2, options)
    if (item.discount) {
      printMultipleShort([
        'Popust:',
        ' ',
        formatCurrency(currency, ((item.amt / 1000) * (item.single_item_price / 100)) - item.price / 100)
      ], 2, options)
    }
    // }
  })

  printSeparator(options)

  printMultipleShort([
    'Vrsta',
    'Stopa',
    'Osnovica',
    'Ukupno'
  ], 4, options)

  fullVirman.pdv.forEach((item) => {
    switch (item.rate) {
      case 0:
        printMultipleShort([
          'PDV 0%',
          '0%',
          formatCurrency(currency, item.base / 100),
          formatCurrency(currency, 0)
        ], 4, options)
        break

      default:
        printMultipleShort([
          'PDV ' + `${item.rate / 100}% `,
          `${item.rate / 100}% `,
          formatCurrency(currency, item.base / 100),
          formatCurrency(currency, item.total / 100)
        ], 4, options)
    }
  })

  if (fullVirman.pnp && fullVirman.pnp !== null) {
    var pnp = fullVirman.pnp
    printMultipleShort([
      'PNP ' + `${pnp.rate / 100} % `,
      `${pnp.rate / 100} % `,
      formatCurrency(currency, pnp.base / 100),
      formatCurrency(currency, pnp.total / 100)
    ], 4, options)
  }

  printSeparator(options)

  const cummulatedDiscount = getCummulatedDiscount(fullVirman)

  if (fullVirman.discount && fullVirman.discount.type && fullVirman.discount.name && fullVirman.discount.name === 'Reprezentacija') {
    printTotals('Popust - reprezentacija', formatCurrencyCustom(currencySymbol, fullVirman.discount.amount / 100), options)
  } else {
    if (cummulatedDiscount > 0) {
      printTotals('Popust na iznos', formatCurrencyCustom(currencySymbol, fullVirman.discount.amount / 100), options)
    }
  }
  if (cummulatedDiscount > 0) {
    printTotals('Cij. bez pop.', formatCurrencyCustom(currencySymbol, fullTotal / 100), options)
    printTotals('Ukupni popust', formatCurrencyCustom(currencySymbol, cummulatedDiscount), options)
  }

  if (company.print_eur === undefined || company.print_eur === null || company.print_eur === true) {
    printTotals('Ukupno', formatCurrencyCustom(currencySymbol, total / 100), getOptions({ bold: false, vertical: 0, horizontal: 1 }))
    // printTotals('', formatCurrencyFull('HRK', total / 100 * conversionRate), getOptions({ bold: false, vertical: 0, horizontal: 1 })) // printLine('\n'.repeat(1), options)
    // printTotals('Tečaj', `1 ${currencySymbol} = ${conversionRateString}`, options)
  }
  printSeparator(options)

  // Printing job
  if (printerType === 'UNIVERSAL') {
    doc.feed(2).cut()

    var escposCommands = options.document.generateUInt8Array()

    // create ClientPrintJob
    var cpj = new JSPM.ClientPrintJob()

    // Set Printer info
    // const myPrinter = new JSPM.NetworkPrinter(9100, '172.29.35.88')

    let selectedPrinter
    if (printerConfiguration.ip_address) {
      if (printerConfiguration.port) {
        selectedPrinter = new JSPM.NetworkPrinter(printerConfiguration.port, printerConfiguration.ip_address)
      } else {
        selectedPrinter = new JSPM.NetworkPrinter(9100, printerConfiguration.ip_address)
      }
    } else {
      selectedPrinter = new JSPM.InstalledPrinter(printerConfiguration.printer_name)
    }

    cpj.clientPrinter = selectedPrinter

    // Set the ESC/POS commands
    cpj.binaryPrinterCommands = escposCommands

    EventBus.$emit('clear-receipt-data')

    // Send print job to printer!
    try {
      await cpj.sendToClient()
    } catch (err) {
      printerErrors('JSPM_not_running')
    }

    return true
  } else if (printerType === 'BIXOLON') {
    cutPaper()
    const strSubmit = getPosData()

    let printerUrl
    if (printerConfiguration && printerConfiguration.ip_address && printerConfiguration.ip_address !== '') {
      printerUrl = printerConfiguration.ip_address
      pName = printerConfiguration.printer_name
    }

    try {
      requestPrint(pName, strSubmit, viewResult, printerUrl)
    } catch (err) {
      alert('Greška pri reprintu računa, molimo pokušajte ponovno!')
      EventBus.$emit('clear-receipt-data')

      console.error(err)
    } finally {
      EventBus.$emit('clear-receipt-data')
    }

    return true
  }
}
