const _ = require('lodash')

export default {
  methods: {
    checkCompanyIsSameAsInitial (initialCompany, company) {
      if (company.digital_signature !== initialCompany.digital_signature) {
        return false
      }

      if (company.print_eur !== initialCompany.print_eur) {
        return false
      }

      if (company.receipt_logo !== initialCompany.receipt_logo) {
        return false
      }

      if (company.warehouse !== initialCompany.warehouse) {
        return false
      }

      if (!_.isEqual(company.bank_accounts, initialCompany.bank_accounts)) {
        return false
      }

      if (company.address !== initialCompany.address) {
        return false
      }

      if (!_.isEqual(company.legal_notes, initialCompany.legal_notes)) {
        return false
      }

      if (company.print_template !== initialCompany.print_template) {
        return false
      }

      if (company.swift !== initialCompany.swift) {
        return false
      }

      if (!_.isEqual(company.associated_oibs, initialCompany.associated_oibs)) {
        return false
      }

      if (company.id !== initialCompany.id) {
        return false
      }

      if (company.status !== initialCompany.status) {
        return false
      }

      if (company.city !== initialCompany.city) {
        return false
      }

      if (company.receipt_type !== initialCompany.receipt_type) {
        return false
      }

      if (company.oib !== initialCompany.oib) {
        return false
      }

      if (company.receipt_numeration_separator !== initialCompany.receipt_numeration_separator) {
        return false
      }

      if (company.receipt_numeration_year !== initialCompany.receipt_numeration_year) {
        return false
      }

      if (company.type !== initialCompany.type) {
        return false
      }

      if (company.certificate !== initialCompany.certificate) {
        return false
      }

      if (!_.isEqual(company.legal_info, initialCompany.legal_info)) {
        return false
      }

      if (company.zip_code !== initialCompany.zip_code) {
        return false
      }

      if (company.is_taxed !== initialCompany.is_taxed) {
        return false
      }

      if (company.name !== initialCompany.name) {
        return false
      }

      if (!_.isEqual(company.memorandum, initialCompany.memorandum)) {
        return false
      }

      if (company.logo !== initialCompany.logo) {
        return false
      }

      if (company.domain !== initialCompany.domain) {
        return false
      }

      if (company.tax_type !== initialCompany.tax_type) {
        return false
      }

      if (company.note_a4 !== initialCompany.note_a4) {
        return false
      }

      if (company.resource_status !== initialCompany.resource_status) {
        return false
      }

      if (company.country !== initialCompany.country) {
        return false
      }

      return true
    }
  }
}
