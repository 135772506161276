<template>
  <modal
    height="auto"
    width="50%"
    scrollable
    draggable
    :resizable="true"
    :clickToClose="false"
    style="z-index: 200; border-radius: 25px"
    name="notifications"
    class="myTest"
  >
    <v-card style="border-radius: 25px">
      <v-card-title
        :class="[
          'd-flex',
          notification && notification.type === 'WARNING'
            ? 'notificationWarning'
            : 'notificationInfo',
        ]"
      >
        <span class="text-h5" style="font-weight: bold">{{
          notification ? notification.subject : ""
        }}</span>
      </v-card-title>

      <v-card-text class="pt-5">
        <div class="text">
          <div
            height="150"
            @scroll="onScroll"
            class="notifications"
            v-html="notification ? notification.text : ''"
          ></div>
        </div>

        <div
          class="pt-10"
          v-if="notification && notification.type === 'CONFIRMATION'"
        >
          <div
            style="text-align: center"
            :class="[scrolled === false ? 'redText' : 'whiteText']"
          >
            <b>Molimo pročitajte cijeli tekst!</b>
          </div>

          Potvrdite čitanje obavijesti upisivanje riječi:
          <span v-html="keyword"></span>
        </div>

        <v-form
          v-if="notification && notification.type === 'CONFIRMATION'"
          @submit.prevent="submit"
        >
          <v-text-field
            v-if="
              notification &&
              notification.keyword &&
              notification.keyword !== ''
            "
            autofocus
            outlined
            dense
            v-model="confirmationText"
          ></v-text-field>
          <v-row class="justify-end pr-5">
            <v-btn
              class="btn successButton"
              :disabled="!checkConfirmation() || !scrolled"
              @click="submit()"
              >Potvrdi</v-btn
            >
          </v-row></v-form
        >

        <v-form v-if="notification && notification.type !== 'CONFIRMATION'">
          <v-row class="justify-end pr-5 pt-5 pb-3">
            <v-btn class="btn successButton" @click="submit()">Potvrdi</v-btn>
          </v-row></v-form
        >
      </v-card-text>
    </v-card>
  </modal>
  <!-- </v-dialog> -->
</template>
<script>

import applicationSettings from '@/mixins/applicationSettings'
import state from '@/state'
import { auth, df } from '@/plugins/firebase'

export default {
  components: { },
  mixins: [applicationSettings],
  inject: ['showMask', 'hideMask'],
  data: function () {
    return {
      listeners: [],
      confirmationText: '',
      scrolled: false
    }
  },
  props: {
    showNotif: Boolean,
    notification: Object
  },
  computed: {
    totalScrolled () {
      return this.scroll
    },
    keyword () {
      if (this.notification && this.notification.type === 'CONFIRMATION') {
        if (this.confirmationText !== '') {
          var lowerKeyword = this.notification.keyword.toUpperCase()
          return lowerKeyword.replace(
            this.confirmationText.toUpperCase(),
            '<span style="color:green">' + this.confirmationText.toUpperCase() + '</span>')
        } else {
          return this.notification ? this.notification.keyword : ''
        }
      } else {
        return ''
      }
    },
    color () {
      return 'red'
    },
    avatar () {
      return state.getUser()?.name?.charAt(0)?.toUpperCase() || ''
    }
  },
  watch: {
    showNotif (newVal) {
      if (newVal) {
        if (this.notification && (!this.notification.user_id || this.notification.user_id === '')) {
          this.$modal.show('notifications')
        }

        if (this.notification && this.notification.user_id && (this.notification.user_id === auth.currentUser.uid || this.notification.user_id === state.getCashRegisterUser()?.id)) {
          this.$modal.show('notifications')
        }
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    onScroll (e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      if ((scrollTop + offsetHeight) >= scrollHeight) {
        this.scrolled = true
      }
    },
    checkConfirmation () {
      var check = false
      const options = { sensitivity: 'base' }
      if (this.notification && (this.notification.keyword.localeCompare(this.confirmationText, undefined, options) === 0)) {
        check = true
      }

      return check
    },
    submit () {
      if (this.notification?.type === 'CONFIRMATION' && this.checkConfirmation() === false) {
        return
      }

      this.confirmationText = ''

      this.$modal.hide('notifications')

      var userID = state.getCashRegisterUser() && state.getCashRegisterUser().id !== undefined ? state.getCashRegisterUser().id : state.getUser().id
      var userName = state.getCashRegisterUser() && state.getCashRegisterUser().name !== undefined ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname
      df.doc(`user_notifications/${auth.currentUser.uid}/notifications/${this.notification.id}`)
        .update({
          readed: true,
          reader_id: userID,
          reader_name: userName,
          readed_time: parseInt(Date.now() / 1000)
        })
    }

  }
}
</script>
<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px !important;
}

.notifications {
  overflow-y: scroll;
  color: black;
  font-size: 18px;
  max-height: 400px;
  overflow-y: scroll;
}
.redText {
  color: red;
}
.whiteText {
  color: white;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.myTest >>> .vm--modal {
  border-radius: 25px !important;
}

.notificationInfo {
  vertical-align: middle;
  background-color: #1577da;

  height: 58px;
  color: white;
}

.notificationWarning {
  vertical-align: middle;
  background-color: #f57c02;

  height: 58px;
  color: white;
}
</style>
