<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    width="55%"
    style="z-index: 6"
    content-class="my-custom-dialog"
  >
    <v-form ref="editReceiptSettingsForm" @submit.prevent="submit">
      <v-card
        style="border-radius: 12px !important"
        class="overflow-auto"
        :class="[
          { 'mobile-card': $vuetify.breakpoint.smAndDown },
          { 'card-company': !$vuetify.breakpoint.smAndDown },
        ]"
      >
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;
            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold">Postavke računa</span>
        </v-card-title>

        <v-card-text style="padding: 0; margin: 0; height: 100% !important">
          <v-container style="height: 100% !important; overflow-y: hidden">
            <v-tabs class="pb-5" v-model="tab" @change="changeTab(tab)">
              <v-tab
                style="font-size: 14px !important; margin-right: 0px !important"
                v-for="oneTab of tabs"
                :key="oneTab.id"
              >
                <v-icon class="pr-2">{{ oneTab.icon }}</v-icon>
                <span
                  ><b>{{ oneTab.name }}</b></span
                >
              </v-tab>
            </v-tabs>
            <v-tabs-items style="height: 100% !important" v-model="tab">
              <v-tab-item style="height: 100% !important">
                <v-row class="pt-5">
                  <v-col cols="6" class="pt-0 pb-3 mb-0 mt-0">
                    <v-select
                      style="border-radius: 12px; font-size: 12px !important"
                      outlined
                      dense
                      :items="numerationItems"
                      v-model="selectedNumeration"
                      return-object
                      item-text="text"
                      label="Numeracija računa"
                      autofocus
                      :rules="[rules.req]"
                      hide-details
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="pt-0 pb-3 mb-0 mt-0">
                    <v-select
                      style="border-radius: 12px"
                      outlined
                      dense
                      :items="receiptTypes"
                      v-model="selectedReceiptType"
                      item-text="text"
                      item-value="id"
                      label="Tip računa"
                      :rules="[rules.req]"
                      hide-details
                    >
                    </v-select>
                  </v-col>
                  <!-- <v-col cols="6" class="pt-0 pb-3 mb-0 mt-0"> </v-col> -->
                </v-row>

                <v-row class="pt-5">
                  <v-col cols="6" class="pt-0 pb-3 mb-0 mt-0">
                    <v-textarea
                      style="border-radius: 12px"
                      :label="$t('$vuetify.companies.notePOS')"
                      v-model="notePOS"
                      rows="3"
                      outlined
                      hide-details
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="6" class="pt-0 pb-3 mb-0 mt-0">
                    <v-textarea
                      hide-details
                      style="border-radius: 12px"
                      :label="$t('$vuetify.companies.noteA4')"
                      v-model="noteA4"
                      rows="3"
                      outlined
                    >
                    </v-textarea>
                  </v-col>
                  <div
                    class="pl-5"
                    style="
                      font-weight: bold;
                      margin-top: 10px !important;
                      text-align: center;
                    "
                  >
                    Napomene će se ispisivati na svakom računu.
                  </div>
                </v-row>
                <v-row class="pt-5">
                  <v-checkbox
                    dense
                    hide-details
                    class="pt-0 mt-0 pb-0 mb-0 pl-3"
                    label="Prikaži iznos u HRK uz konverziju 1 EUR = 7,53450 HRK"
                    v-model="printEur"
                  ></v-checkbox>
                </v-row>
              </v-tab-item>
              <v-tab-item style="height: 100% !important">
                <v-row class="pt-3 pb-10" style="text-align: center">
                  <v-col cols="12" class="pt-0 mt-0 pb-0 mb-0">
                    <v-row class="pt-3" style="align-items: center !important">
                      <div class="pl-5" style="font-size: 16px">
                        Predložak računa: <b>{{ showPrintTemplate() }}</b>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="openTemplates()"
                        class="btn infoButton mr-5"
                        >Promijeni</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around pt-5 mt-0">
                  <v-col cols="6" class="pt-0 mt-0">
                    <v-col class="d-flex justify-center">
                      <v-img
                        :src="receiptLogo"
                        v-model="receiptLogo"
                        SelectTemplate
                        contain
                        max-height="100"
                        max-width="250"
                        ref="receiptLogo"
                      ></v-img>
                    </v-col>
                    <v-col>
                      <v-file-input
                        :label="$t('$vuetify.companies.receiptLogo')"
                        style="overflow: hidden"
                        @change="onLogoFileInputChange"
                        clearable
                        v-model="logoFile"
                        accept=".png, .jpeg, .jpg"
                        @click:close="onLogoFileInputChange"
                      >
                      </v-file-input>
                    </v-col>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item style="height: 100% !important">
                <v-row class="pt-3" style="text-align: center">
                  <v-col cols="12">
                    <v-textarea
                      hide-details
                      style="border-radius: 12px"
                      label="Memorandum"
                      v-model="memorandum"
                      rows="3"
                      outlined
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      style="border-radius: 12px"
                      outlined
                      dense
                      :items="memorandumOverrides"
                      v-model="memorandumOverride"
                      item-value="id"
                      item-text="text"
                      label="Opcije"
                      hide-details
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item style="height: 100% !important">
                <v-row class="pt-3" style="text-align: center">
                  <div class="pl-4 pr-4">
                    <span
                      >AiKasa sustav automatski dodaje sve potrebne legalne
                      napomene.<br />
                      Ukoliko odaberete opciju “<b
                        >Zamijeni postojeće legalne napomene</b
                      >” ispisivati će se samo napomene koje ste sami
                      unijeli.</span
                    >
                  </div>
                  <v-col cols="12">
                    <v-textarea
                      hide-details
                      style="border-radius: 12px"
                      label="Legalne napomene"
                      v-model="legalNotes"
                      rows="3"
                      outlined
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      style="border-radius: 12px"
                      outlined
                      dense
                      :items="legalNotesOverrides"
                      v-model="legalNotesOverride"
                      item-value="id"
                      item-text="text"
                      label="Opcije"
                      hide-details
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>

        <v-card-actions class="pr-5 pl-10">
          <v-row dense style="margin-top: 1rem; margin-bottom: 1rem">
            <v-spacer></v-spacer>

            <v-btn
              class="btn dangerButton"
              style="margin-right: 1rem"
              @click="close"
            >
              Odustani
            </v-btn>
            <v-btn
              id="success"
              class="btn successButton"
              type="submit"
              :loading="submitting"
            >
              {{ $t("$vuetify.save") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <select-template
        ref="selectTemplate"
        @templateSelected="onTemplateSelected"
      ></select-template>
    </v-form>
  </v-dialog>
</template>
<script>
import { clone } from '@/plugins/utils'
import rules from '@/plugins/rules'
import { auth, df, functions } from '@/plugins/firebase'
// import { v4 as uuidv4 } from 'uuid'
import requestChecker from '@/mixins/requestChecker'
import SelectTemplate from '@/modules/companies/components/SelectTemplate'
const _ = require('lodash')

export default {
  mixins: [requestChecker],
  components: { SelectTemplate },
  inject: ['showMsgBox'],
  data: function () {
    return {
      visible: false,
      submitting: false,
      initialData: {},
      notePOS: '',
      noteA4: '',
      receiptLogo: '',
      logoFile: undefined,
      digitalSignature: '',
      digitalSignatureFile: undefined,
      defaultLogo: '',
      defaultSignature: '',
      printEur: undefined,
      numerationItems: [
        // TODO: dodati u prijevode
        { text: 'Br.Računa/prodajno/blagajna', separator: '/', year: false },
        { text: 'Br.Računa-prodajno-blagajna', separator: '-', year: false },
        {
          text: 'Br.Računa/prodajno/blagajna/godina',
          separator: '/',
          year: true
        },
        {
          text: 'Br.Računa-prodajno-blagajna-godina',
          separator: '-',
          year: true
        }
      ],
      receiptTypes: [
        { text: 'Račun', id: 'racun' },
        { text: 'Račun - Otpremnica', id: 'racunotpremnica' }
      ],
      selectedReceiptType: 'racun',
      selectedNumeration: undefined,
      rules: {
        req: rules.req()
      },
      receiptTemplates: [],
      listeners: [],
      tabs: [{ id: 0, name: 'Postavke', icon: 'mdi-file-cog-outline' },
        { id: 1, name: 'Predlozak i logo', icon: 'mdi-panorama-variant-outline' },
        { id: 2, name: 'Memorandum', icon: 'mdi-file-check-outline' },
        { id: 3, name: 'Legalne napomene', icon: 'mdi-note-multiple-outline' }],
      tab: null,
      memorandum: '',
      memorandumOverride: false,
      memorandumOverrides: [{ id: false, text: 'Dodaj na postojeći tekst' }, { id: true, text: 'Zamijeni postojeći tekst' }],
      legalNotes: '',
      legalNotesOverride: false,
      legalNotesOverrides: [{ id: false, text: 'Dodaj na postojeće legalne napomene' }, { id: true, text: 'Zamijeni postojeće legalne napomene' }]
    }
  },
  mounted () {
    this.getReceiptTemplates()
  },
  methods: {
    changeTab (tab) {
      if (tab === -1) {
        this.activeTab = 'Osnovni podaci'
      }

      this.activeTab = tab
    },
    onTemplateSelected (template) {
      this.company.print_template = template
    },
    openTemplates () {
      this.$refs.selectTemplate.open(this.company)
    },
    showPrintTemplate () {
      var templateName = 'racun02'
      if (this.company?.print_template && this.company.print_template !== null) {
        this.receiptTemplates.forEach(template => {
          if (template.id === this.company.print_template) {
            templateName = template.name
          }
        })
      }

      return templateName
    },

    getReceiptTemplates () {
      this.receiptTemplates = []
      const listener = df.doc('project_constants/receipt_templates').onSnapshot(doc => {
        this.receiptTemplates = doc.data().templates
      })
      this.listeners.push(listener)
    },
    open (company) {
      this.visible = true
      this.company = clone(company)
      this.defaultLogo =
        this.company.receipt_logo || require('@/assets/no-item.jpg')
      this.defaultSignature =
        this.company.digital_signature || require('@/assets/no-item.jpg')
      this.receiptLogo = clone(this.defaultLogo)
      this.digitalSignature = clone(this.defaultSignature)

      this.printEur = company.print_eur ?? false

      if (company.receipt_type) {
        this.selectedReceiptType = company.receipt_type
      }

      this.notePOS = this.company.note_pos
      this.noteA4 = this.company.note_a4
      this.memorandum = this.company.memorandum && this.company.memorandum.text ? this.company.memorandum.text : ''
      this.memorandumOverride = this.company.memorandum && this.company.memorandum.override ? this.company.memorandum.override : false

      this.legalNotes = this.company.legal_notes && this.company.legal_notes.text ? this.company.legal_notes.text : ''
      this.legalNotesOverride = this.company.legal_notes && this.company.legal_notes.override ? this.company.legal_notes.override : false
      this.selectedNumeration = this.numerationItems.find(
        (numeration) =>
          numeration.separator === this.company.receipt_numeration_separator &&
          numeration.year === this.company.receipt_numeration_year
      )
      const registerParams = {
        digital_signature: this.digitalSignature,
        receipt_logo: this.receiptLogo,
        note_pos: this.notePOS ? this.notePOS : '',
        note_a4: this.noteA4 ? this.noteA4 : '',
        receipt_numeration_separator: this.selectedNumeration.separator,
        receipt_numeration_year: this.selectedNumeration.year
      }

      this.registerRequestParams(registerParams)

      this.initialData.defaultLogo = this.defaultLogo
      this.initialData.defaultSignature = this.defaultSignature
      this.initialData.receiptLogo = this.receiptLogo
      this.initialData.digitalSignature = this.digitalSignature
      this.initialData.notePOS = this.notePOS
      this.initialData.noteA4 = this.noteA4
      this.initialData.selectedNumeration = this.selectedNumeration
      this.initialData.printEur = this.printEur
      this.initialData.printTemplate = this.company.print_template
      this.initialData.memorandum = this.memorandum
      this.initialData.memorandumOverride = this.memorandumOverride
      this.initialData.legalNotes = this.legalNotes
      this.initialData.legalNotesOverride = this.legalNotesOverride
      this.initialData.receiptType = this.selectedReceiptType
    },
    close () {
      this.logoFile = undefined
      this.digitalSignatureFile = undefined
      this.visible = false
    },

    createImage (
      file,
      image,
      imageTemplateRef,
      fileRef,
      defaultImage,
      maxWidth,
      maxHeight,
      type
    ) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (!e.target.result?.includes('data:image')) {
          this.showMsgBox({
            text: 'Nedopušteni tip datoteke. Dozvoljeni tipovi su .jpeg, .jpg i .png.',
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
          this[fileRef] = undefined
          return
        }
        if (e?.target?.result) {
          this[image] = e.target.result
          this.$forceUpdate()
          setTimeout(() => {
            const { naturalHeight, naturalWidth } =
              this.$refs[imageTemplateRef].image
            if ((naturalWidth > maxWidth || naturalHeight > maxHeight) && type !== 'logo') {
              this.showMsgBox({
                text: this.$t('$vuetify.companies.imageDimensionExceeding', {
                  width: naturalWidth,
                  height: naturalHeight,
                  maxWidth: maxWidth,
                  maxHeight: maxHeight
                }),
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this[fileRef] = undefined
              this[image] = defaultImage

              this.$forceUpdate()
            }
          }, 10)
        }
      }
      reader.readAsDataURL(file)
    },
    onLogoFileInputChange (file) {
      if (!file) {
        // set fake image
        this.receiptLogo = this.defaultLogo
        return
      }

      if (file.size > 410000) {
        this.showMsgBox({
          text: 'Nedopušteni veličina datoteke. Maksimalna dozvoljena veličina je 400 kB.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        return
      }

      this.createImage(
        file,
        'receiptLogo',
        'receiptLogo',
        'logoFile',
        this.defaultLogo,
        250,
        100,
        'logo'
      ) // receiptLogo is logo ref from the template
    },
    onDigitalSignatureFileInputChange (file) {
      if (!file) {
        // set fake image
        this.digitalSignature = this.defaultSignature
        return
      }
      this.createImage(
        file,
        'digitalSignature',
        'digitalSignature',
        'digitalSignatureFile',
        this.defaultSignature,
        200,
        200,
        'signature'
      )
    },
    submit () {
      if (
        _.isEqual(this.selectedNumeration, this.initialData.selectedNumeration) &&
        this.noteA4 === this.initialData.noteA4 &&
        this.notePOS === this.initialData.notePOS &&
        this.receiptLogo === this.initialData.receiptLogo &&
        this.digitalSignature === this.initialData.digitalSignature &&
        this.printEur === this.initialData.printEur &&
        this.company.print_template === this.initialData.printTemplate &&
        this.memorandum === this.initialData.memorandum &&
        this.memorandumOverride === this.initialData.memorandumOverride &&
        this.legalNotes === this.initialData.legalNotes &&
        this.legalNotesOverride === this.initialData.legalNotesOverride &&
        this.selectedReceiptType === this.initialData.receiptType
      ) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }
      if (!this.$refs.editReceiptSettingsForm.validate()) return
      if (this.receiptLogo !== this.defaultLogo) {
        this.receiptLogo = this.receiptLogo.split(',')[1]
      } else {
        if (this.defaultLogo === require('@/assets/no-item.jpg')) { this.receiptLogo = '' }
      }

      if (this.digitalSignature !== this.defaultSignature) {
        this.digitalSignature = this.digitalSignature.split(',')[1]
      } else {
        if (this.digitalSignature === require('@/assets/no-item.jpg')) { this.digitalSignature = '' }
      }

      this.submitting = true
      try {
        const payload = {
          action: {
            operation: 'update',
            entity: 'company',
            resource_id: this.company.id,
            params: {
              receipt_numeration_separator: this.selectedNumeration.separator,
              receipt_numeration_year: this.selectedNumeration.year,
              note_a4: this.noteA4 ? this.noteA4 : '',
              note_pos: this.notePOS ? this.notePOS : '',
              print_eur: this.printEur,
              receipt_type: this.selectedReceiptType
            }
          }
        }

        if (this.memorandum !== '') {
          payload.action.params.memorandum = {
            text: this.memorandum,
            override: this.memorandumOverride
          }
        }

        if (this.legalNotes !== '') {
          payload.action.params.legal_notes = {
            text: this.legalNotes,
            override: this.legalNotesOverride
          }
        }

        if (this.digitalSignature !== '') {
          payload.action.params.digital_signature = this.digitalSignature
        }
        if (this.receiptLogo !== '') {
          payload.action.params.receipt_logo = this.receiptLogo
        }
        if (this.notePOS !== undefined) {
          payload.action.params.note_pos = this.notePOS
        }
        if (this.noteA4 !== undefined) {
          payload.action.params.note_a4 = this.noteA4
        }

        const cleanParams = this.getCleanParams(payload.action.params)
        Object.keys(cleanParams).forEach((key) => {
          delete payload.action.params[key]
        })

        payload.action.params.user_id = auth.currentUser.uid

        if (this.company.print_template !== '') {
          payload.action.params.print_template = this.company.print_template
        }

        var companyApi = functions.httpsCallable('companyapi')
        this.$emit('editSuccess', this.company)

        companyApi({
          action: {
            operation: 'update',
            entity: 'company',
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.selectedOibs = []
              this.visible = false
              this.submitting = false
            } else {
              this.submitting = false
              this.selectedOibs = []
              this.showMsgBox({
                text: 'Dogodila se greška',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
            }
          })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }

      // df.doc(`request/${reqId}`)
      //   .set({
      //     user_id: `${auth.currentUser.uid}`,
      //     device_id: 'web',
      //     created: `${new Date().getTime()}`,
      //     type: 'company',
      //     payload: btoa(
      //       unescape(encodeURIComponent(JSON.stringify(payload)))
      //     )
      //   })
      //   .then(() => {
      //     this.$emit('editSuccess', this.company)
      //     this.close()
      //   })
      //   .catch(function (error) {
      //     // console.error('Error writing document: ', error)
      //     this.showMsgBox({
      //       text: error || 'An error has occurred',
      //       actions: ['cancel'],
      //       cancelBtnText: 'OK',
      //       color: 'error'
      //     })
      //   })
      //   .finally(() => {
      //     this.submitting = false
      //   })
    }
  }
}
</script>
<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px !important;
}
.card-company {
  border-radius: 25px !important;
  height: 500px !important;
  overflow-y: hidden;
}
</style>
