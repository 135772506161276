<template>
  <div style="width: 100%">
    <v-row class="text-h4 primary--text align-center">
      <v-col md="3" class="hidden-sm-and-down pb-4">
        {{ $t($route.meta.title) }}
      </v-col>
      <v-col md="9" class="d-flex align-center justify-end flex-grow-1">
        <v-btn
          plain
          outlined
          color="primary"
          class="hidden-sm-and-down"
          @click="fireAddEvent"
          v-if="!$route.meta.noAddButton"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t($route.meta.addItemText) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </div>
</template>
<script>
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'

export default {
  name: 'CompaniesTopBar',
  mixins: [applicationSettings, onScreenKeyboard],
  data: function () {
    return {
      topSearch: '',
      unfiskReceipts: [],
      listeners: []
    }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  computed: {
    title () {
      return state.getAppTitle()
    },
    listView () {
      return state.isListView()
    }
  },
  watch: {
    filter (val) {
      if (val !== this.topSearch) {
        this.topSearch = val
      }
    },
    search (val) {
      if (val !== this.topSearch) {
        this.topSearch = val
      }
    }
  },
  methods: {
    changeView () {
      state.setListView(!state.isListView())
    },
    fireAddEvent () {
      if (state.events && Object.prototype.hasOwnProperty.call(state.events, this.$route.meta.addItemAction)) {
        state.events[this.$route.meta.addItemAction] = true
      }
    },
    searchItems (reset = false) {
      if (reset) {
        this.search = ''
      } else {
        if (this.topSearch) {
          this.search = this.topSearch
        } else {
          this.search = ''
        }
      }
    },
    filterItems (reset = false) {
      if (reset) {
        this.filter = ''
      } else {
        if (this.topSearch) {
          this.filter = this.topSearch
        } else {
          this.filter = ''
          this.search = '' // reset search on input event if there is no text
        }
      }
    }
  }
}
</script>
